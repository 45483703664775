.dynasearchwrapper {
	width: 100%;
	min-height: 500px;
	@include flexbox(column, flex-start, center);

	div.search_page_container{
		width: 100%;

		.page_rayon{
			.imgWrapper{
				.video{
					margin-bottom: 10px;
					a{
				        overflow: hidden;
				        padding-top: calc((646 / 517) * 100%);
						height: 100%;
						width: 100%;

				        iframe{
				            position: absolute;
				            top: -6px;
				            left: 0;
				            border: 0;
				            width: 100%;
							height: 100%;  
							z-index: -1;
				        }
					}
				}
			}
		}
	}

	.main_ariane{
		width: 100%;
		transition: all .5s ease-out;
	}
	
	.wrap_rayon_info {
		position: relative;
		font-family: $primaryFont;
		display: flex;
		align-items: baseline;
		max-width: 50%;
		width: 100%;
		padding-bottom: 1rem;
		margin: 1rem auto 6.25rem;

		&::after {
			content: "";
			display: block;
			width: 100%;
			height: .08rem;
			background-color: $quaternaryColor;
			position: absolute;
			top: 100%;
			left: 0;
		}

		.uppercase_title {
			text-transform: capitalize;
			padding-right: .5rem;
		}

		.corresponding_product {
			text-transform: lowercase;
			color: $tertiaryColor;
			font-size: .875rem;
		}
	}

	.no_results_wrapper {
		margin: 0 auto;
		padding: 2rem;
		text-align: center;

		.search_help_title {
			padding-top: 7rem;
			padding-bottom: 1rem;
			margin-bottom: 1rem;
			@include wshopFont($primaryFont, .813rem, unset, .1rem, 700, $primaryColor);
			text-transform: uppercase;
		}

		.advise {
			font-weight: 700;
		}

		ul {
			width: 50%;
			margin: 2% auto;
			list-style: disc;

			li {
				width: fit-content;
				margin: 0 auto;
			}
		}

		.title_gonodle {
			@include wshopFont($primaryFont, 1.5rem, unset, .05rem, 700, $primaryColor);
			text-transform: uppercase;
			text-align: center;
		}

		/***** TEST SWIPER *****/

		.item {
			width: 500px;
		}

		.items_wrapper {
			display: flex;
			width: 100%;
		}

		.wrap_rolloverproduit {
			display: none !important;
		}

		.productSwiper {
			.swiper-wrapper {
				height: 600px;
				display: grid;
				grid-template-columns: 100%;
				grid-template-rows: 100%;

				.swiper-slide {
					display: none;
					grid-column: 1;
					grid-row: 1;

					&:first-child {
						display: block;
					}

					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}

    #search_page #list_item {
        .rollover_left.achat_express {
            .choices_list.noVerticalScroll {
                overflow: hidden;
            }
        }
    }
}

body.body_fixed {
	.main_ariane {
		margin-bottom: 1.5rem;
	}
}

.title_gonodle {
	margin-bottom: 1rem;
	text-align: center;
	@include wshopFont($primaryFont, 1.5rem, unset, .1rem, 700, $primaryColor);
	text-transform: uppercase;
}

/* NO RESULT */
#scroll_items {
	margin-top: 4rem;

	h3 {
		font-size: .875rem;
		font-weight: 200;
		text-align: center;
		margin-bottom: 1rem;
	}

    h2 {
        font-family: $primaryFont;
        font-size: 1rem;
        white-space: nowrap;
        font-weight: 400;
        text-transform: uppercase;
    }
}

#searchSwiper {
	width: 80vw;

	a {
		text-decoration: none;
	}

	.swiper-slide {
		padding: 0 1rem;

		h2 {
			text-align: left;
		}
	}

	.item-price {
		display: flex;
	}

	.wrapImages img {
		width: 100%;
		height: auto;
	}
}

