$col: 5;
$row: 5;

/*******************************************************************/
/* HEADER LOGOS */
/*******************************************************************/
.bgSprite {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 1px;

    &.quoteLeft {

        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
            /***@ IE10+ specific styles go here */ 
            display: block;
        }
    }

    &.quoteRight {
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
            /***@ IE10+ specific styles go here */ 
            display: block;
        }
    }

    &:before {
        content: '';
        width: 20px;
        height: 20px;
        @include bgIconPath('../svg/spritesheet.svg');
        margin-bottom: 1px;
    }

    @include spriter('../svg/spritesheet.svg', 5, 5, (2:'logoLight',5: 'facebookIcon', 4: 'instagramIcon', 20:'pinterestIcon', 25:'tiktokIcon', 6: 'arrowBottom', 7:'arrowTop', 8: 'cross', 16: 'logoBlue', 17: 'logoWhite',18:'search_txt', 21: 'wishlistIcon', 23: 'quoteLeft', 24: 'quoteRight',19:'wen'));

    .instaIcon {
        &::before {
            @include bgIconCoord(4, $col, $row);
        }
    }

    .fbIcon {
        &::before {
            @include bgIconCoord(5, $col, $row);
        }
    }

    .tiktokIcon {
        &::before {
            background-position: 0 100%;
        }
    }

    .pinterestIcon {
        &::before {
            @include bgIconCoord(20, $col, $row);
        }
    }

    .tiktokIcon {
        &::before {
            @include bgIconCoord(25, $col, $row);
        }
    }
}
