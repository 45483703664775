/* STYLESSHEET */

@import "var/fonts";
@import "var/colors";
@import "components/product_tooltip";

@import "plugin/lionbars";
@import "plugin/OverlayScrollbars";

@import "../../../_app/_src/scss/desktop/app";
@import "../../../_app/_src/scss/desktop/components/add_cart_product_tooltip";
@import "../../../_app/_src/scss/desktop/pages/bank/bank_thanks";


@import "var/prices";
@import "var/animations";
@import "components/reset";
@import "var/mixins";
@import "components/global";

@import "plugin/swiper_slider";
@import "plugin/simplebar";
@import "plugin/ill-js";
@import "components/header";
@import "components/menu";
@import "components/breadcrumbs";

@import "components/home";
@import "components/sprites";
@import "components/footer";
@import "components/forms";
@import "components/lightbox";
@import "components/w-forms";

/***** PAGES *****/
@import "pages/homepage";
@import "pages/boutique";
@import "pages/product";
@import "pages/category";
@import "pages/lookbook";
@import "pages/account";
@import "pages/cart";
@import "pages/checkout";
@import "pages/store_locator";
@import "pages/satellite";
@import "pages/satellite_plan_site";
@import "pages/text";
@import "pages/faq";
@import "pages/publication";
@import "pages/contactez_nous";
@import "pages/cms-page";
@import "pages/rayon";
@import "pages/tunnel";
@import "pages/search";
@import "pages/giftcard";


/***** COMPONENTS *****/
@import "components/bandeau";
@import "components/buttons";
@import "components/tete_gondole";
@import "components/satellite/lamarque.scss"; 
@import "components/wrapper_description";
