html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
input, select, button,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

a {
    color:#000000;
    text-decoration: none;
}

html {
    height: 100%;
    font-size: 16px;
    -webkit-font-smoothing: subpixel-antialiased!important;
}

@media screen and (max-width: 1366px){
    html {
        font-size: 1.18vmax;
    }
}

body {
    font-family: $secondaryFont;
    min-height: 100%;
}

ol, ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
