.productRightPart {
    @include flexbox(column, unset, center);
    justify-content: center;
    padding-right: 2rem;
    width: 30%;
    height: 80vh;

    .date,
    .taunt_text {
        display: none !important;
    }

    .productDescrWrapper {
        width: 100%;
    }

    .eclat_rond {
        max-height: 31px;
        img {
            width: 88%;
        }
    }

    /* PRODUCT NAME */
    .title {
        @include flexbox(row, flex-start, flex-start);

        /* ICON HEART WISHLIST */
        .bgSprite.wishlistIcon {
            margin-left: 0.5rem;

            &.existToWishlistButton::before {
                display: block;
                border-top: unset;
                border-right: unset;
                border-left: unset;
                background-image: url(../svg/spritesheet.svg);
                width: 20px;
                height: 20px;
                content: '';
                @include bgIconCoord(22, 5, 5);
                z-index: 1;
            }

            &::before {
                display: block;
                border-top: unset;
                border-right: unset;
                border-left: unset;
                background-image: url(../svg/spritesheet.svg);
                width: 20px;
                height: 20px;
                content: '';
                @include bgIconCoord(21, 5, 5);
                z-index: 1;
            }
        }
    }

    .item_price {
        margin: 1rem 0;
        @include flexbox(row, flex-start, flex-end);

        .pricetag,
        .pricetag span {
            @include wshopFont($primaryFont, 1.563rem, unset, unset, 400, $primaryColor);
        }

        .pricetag:first-child {
            margin-right: 1rem;
        }

        .pricetag:not(:first-child),
        .pricetag:not(:first-child) span {
            @include wshopFont($primaryFont, 1rem, unset, unset, 400, $tertiaryColor);

            &.currency {
                margin: 0;
            }

            &::after {
                background-color: $tertiaryColor;
            }
        }
    }

    .productColorFieldset .choices_list {
        margin: 1rem 0;
    }

    .productSizeFieldset .choices_list {
        margin: 1rem 0 0;
    }

    .titre_couleur,
    .titre_taille {
        @include wshopFont($primaryFont, 0.688rem, unset, unset, 700, $primaryColor);
        text-transform: uppercase;
    }

    .choices_list {
        @include flexbox(row, flex-start, center);
        flex-wrap: wrap;

        .form_itm {
            margin-bottom: 0.5rem;

            &:not(:last-child) {
                margin-right: 0.5rem;
            }

            /* COLORS LIST */
            &.color {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                @include flexbox(row, center, center);
                position: relative;

                &.nostock {
                    &::after {
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        transform: translate(6px, -5px);
                        rotate: 43deg;
                        background-color: $black;
                        width: 1px;
                        height: 100%;
                        content: "";
                    }
                }

                [type='radio'] {
                    position: absolute;
                    opacity: 0;
                    z-index: -1;
                    width: 100%;
                    height: 100%;

                    &:checked + label {
                        &::after {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            border: 1px solid $primaryColor;
                            border-radius: 50%;
                            width: 20px;
                            height: 20px;
                            content: '';
                        }
                    }
                }

                label {
                    position: relative;
                    cursor: pointer;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                }
            }

            /* SIZES LIST*/
            &.size {
                position: relative;

                [type='radio'] {
                    position: absolute;
                    opacity: 0;
                    z-index: -1;
                    width: 100%;
                    height: 100%;

                    &:checked + label {
                        border-color: $primaryColor;

                        span {
                            color: $primaryColor;
                        }

                        &.disabled {
                            border-color: $borderColor;
                               
                           span {
                               color: $borderColor;
                           }
                       }
                    }
                }

                label {
                    @include flexbox(row, center, center);
                    cursor: pointer;
                    border: 1px solid $borderColor;
                    cursor: pointer;
                    
                    &.disabled {
                         border-color: $borderColor;
                            
                        span {
                            color: $borderColor;
                        }
                    }

                    span {
                        @include wshopFont($primaryFont, 0.875rem, unset, 0.1rem, 400, $primaryColor);
                        padding: 1rem;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .disabledBtnTooltip {
        position: absolute;
        top: 79px;
        left: 0;
        opacity: 0;
        z-index: -1;
        transition: all 0.1s ease;
        border: unset;
        background-color: unset;
        @include wshopFont($primaryFont, 0.688rem, unset, unset, 400, $red2);
        padding: 0.5rem 0;
        text-align: left;

        &::before {
            display: none;
        }

        &.actif {
            opacity: 1;
            z-index: 1;
        }

        @media screen and (max-width: 1128px) {
            top: 70px;
        }

        @media screen and (max-width: 1100px) {
            top: 63px;
        }
    }

    .learn_more,
    .sizeguide,
    .product_alert_stock {
        @include wshopFont($primaryFont, 0.688rem, unset, unset, 200, $primaryColor);
    }

    .flex_container {
        margin: 1rem 0;
    }

    .product_alert_stock {
        margin-bottom: 0.5rem;
    }

    #product_addcart {
        #bloc_add_alert {
            position: relative;

            #keep_alive_stock {
                position: absolute;
                top: 0;
                box-sizing: border-box;
                background-color: $white;
                width: 245px;

                .w-input-error {
                    font-size: 0.7rem;
                    line-height: 1;
                }
            }

            .bloc_add_alert_confirmation {
                position: absolute;
                top: 0;
                box-sizing: border-box;
                background-color: $white;
                width: 245px;
            }
        }

        #bloc_availability {
            span {
                cursor: pointer;
                margin-top: 25px;
                color: $black;
                font-weight: 700;
                font-size: .688rem;
                font-family: $primaryFont;
                letter-spacing: .1rem;
                text-decoration: underline;
                text-underline-offset: 4px;
                text-transform: uppercase;
            }
        }

    }
}

/***** ALERT STOCK FORM *****/
.productRightPart .bloc_add_alert_form {
    @include flexbox(row, space-between, center);
    position: relative;
    margin: 0;
    border: 1px solid $primaryColor;
    width: 100%;
    max-width: 23rem;
    overflow: visible;

    & > .w-form-line {
        margin: unset;

        /* EMAIL INPUT */
        &:not(.w-submit) {
            width: 100%;

            .w-input-container {
                width: 100%;

                .w-input {
                    position: relative;

                    &:before {
                        position: absolute;
                        top: 0;
                        right: 55px;
                        background-color: $black;
                        width: 1px;
                        height: 2.8125rem;
                        content: '';
                    }

                    #mail_alerte_stock {
                        padding-right: 55px;
                        width: 100%;
                        height: 2.8125rem;
                    }

                    &.w-has-error {
                        #mail_alerte_stock {
                            border-right: none;
                        }

                        &:before {
                            background-color: $red2;
                        }

                        &.w-focused {
                            &:before {
                                background-color: $black;
                            }
                        }
                    }
                }
            }
        }

        /* EMAIL SUBMIT */
        &.w-submit {
            position: absolute;
            right: 0;
            width: 55px;
            /*border-left: 1px solid #242424;*/
            height: 2.8125rem;

            button {
                width: 100%;
                height: 2.8125rem;

                &.w-loader {
                    &.loading {
                        background: $dark url(../img/loader_bg2.gif) center
                            center no-repeat !important;
                        background-size: cover !important;
                    }
                }
            }
        }

        .w-input-element,
        .w-submit-button {
            border: unset;
            border: unset;
            background-color: unset;
        }

        .w-submit-button {
            @include wshopFont($primaryFont, 0.688rem, unset, 0.05rem, 700, $primaryColor);

            &.w-loader {
                width: 100%;
            }
        }
    }
}

.productRightPart .bloc_add_alert_confirmation p {
    @include wshopFont($primaryFont, 0.688rem, unset, unset, 200, $primaryColor);
}
