/* BANDEAU TOP PAGE */
.item.push {
    position: relative;
    height: 270px;

    .imgWrapper {
        .bandeau_link {
            img {
                display: block;
                margin: 0 auto;
            }
        }
    }
}

.accountTitleHolder {
    background-size: cover;
    background-repeat: no-repeat;
}

#push_rayon_4 {
    .imgWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.accountTitleWrapper {
    @include flexbox(column, center, center);
    color: $white;
    padding-bottom: 3.5rem;
    margin: 0 0 1rem;
    @include wshopFont($primaryFont, 2.188rem, 1.2, 0.1rem, 700, $white);
    text-transform: uppercase;

    &:before {
        margin-top: 1.5rem;
    }

    body.category & {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 85%;
        margin: 0 auto;
    }

    /* LOGO */
    &.bgSprite.bfr:before {
        width: 8.375rem;
        height: 8.375rem;

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            /* IE10+ */
            background-position: 29% 75%;
        }
    }

    h1 {
        @include wshopFont($primaryFont, 2.188rem, 1.2, 0.1rem, 700, $white);
        text-transform: uppercase;
    }

    p {
        @include wshopFont($secondaryFont, 1rem, 1.2, unset, 400, $white);
        text-align: center;
        width: 100%;
    }

    br {
        display: none;
    }
}

.histoire_maison {
    .accountTitleHolder {
        background-position: 0% 45%;
    }
}