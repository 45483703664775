.lightbox#exit_overlay_OVI {
    width: 568px;
    max-width: 100%;
    height: auto;
    left: 50%;
    top: 50%;
    right: auto;
    transform: translate(-50%, -50%);
    bottom: auto;

    .pop_contents:not(.blockpop) {
        width: 100%;
        height: 100%;
        padding: 1.2rem 2rem 1rem;
        @include flexbox(column, space-between, center);

        /***** IMAGE LOGO TOP *****/
        img.logo {
            width: 78%;
            padding-top: 0.5rem;
            max-width: 392.5px;
            height: auto;

        }

        /***** CONTENT CONTAINER *****/
        .content_newspopup {
            width: 100%;
        }

        /* STEP 1 : INSCRIPTION */
        .content_newspopup .pop_contents.blockpop {
            width: 100%;
            height: 100%;
            @include flexbox(column, space-between, center);

            /* TITLE */
            .title_popup_inscription_home {
                margin: 0 0 1rem;
                @include wshopFont($primaryFont, 18px, unset, .1rem, 700, $primaryColor);
                text-transform: uppercase;
                text-align: center;
            }

            /* TEXT */
            .pop_txt_listing {
                margin: 0 0 1rem;
                @include wshopFont($secondaryFont, 16px, unset, unset, 200, $primaryColor);
                text-align: center;
                width: 100%;
                line-height: 1.1;

            }

            /* FORM */
            .w-form.w-adwords-form .w-form-line-container {
                .w-form-line:nth-child(2) {
                    transform: translateY(-.8rem);
                }

                .w-input.w-email-input .w-input-element,
                .w-form-line.w-submit .w-submit-button {
                    height: 44px;
                }

                .w-input.w-email-input p.w-input-label {
                    font-size: 12px !important;
                }

                .w-form-line.w-submit .w-submit-button {
                    font-size: 11px !important;
                    background-color: $quaternaryColor !important;

                    &.w-loader {
                        width: 100% !important;
                    }
                }
            }

            /* TEXT INFOS DESABONNEMENT */
            .pop_cgv_listing {
                margin: 0 0 16px;
                @include wshopFont($primaryFont, 12px, unset, .05rem, 300, $tertiaryColor);
                text-align: center;

                a {
                    color: $tertiaryColor;
                }
            }

            /* FOOTER SOCIAL */
            .menuSocial {
                width: 100%;
                margin: 0 auto;
                @include flexbox(row, center, center);
                flex-wrap: wrap;

                p {
                    @include wshopFont($primaryFont, 10px, unset, .05rem, 200, $primaryColor);
                    text-transform: uppercase;
                    white-space: nowrap;
                    width: 100%;
                    text-align: center;
                }

                a {
                    text-decoration: none;

                    span {
                        @include wshopFont($primaryFont, 10px, unset, .05rem, 200, $primaryColor);
                        text-transform: uppercase;

                        &::before {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }
        }

        /* STEP 2 : CONFIRMATION */
        .content_newspopup .pop_contents.blockpop.confirmation {
            /* TEXT */
            .pop_txt_listing {
                width: 68%;

                .detail {
                    line-height: 1.5;
                }
            }

            /* CTA CLOSE */
            .form_submit {
                width: 245px;
                height: 44px;
                margin: 0.7rem 0 1.5rem 0;

                .button {
                    width: 100%;
                    height: 100%;
                    @include wshopFont($primaryFont, 11px, unset, .1rem, 700, $primaryColor);
                    text-transform: uppercase;
                    border: 1px solid $primaryColor;
                    transition: all .2s ease-out;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }

    .pop_cgv_listing.common_txt {
        display: none;
    }
}
