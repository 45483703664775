.product_page .home-module.slider-module,
.home.full_w_sldr_wrapper {
    max-width: 1620px;
    margin: 0 auto;
    padding: 0 2rem;
    position: relative;

    a {
        text-decoration: none;
    }

    .tete-gondole .imgWrapper {
        position: relative;
    
        .large+.large,
        .wrapImages+.wrapImages {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: all .2s ease-in-out;
            overflow: hidden;
    
            &:hover {
                opacity: 1;
            }
        }
    
        img {
            width: 100%;
            height: auto;
        }
    }
}

.eclat_rect{
    img{
        width: 190px !important;
    }
}

// Association on product page
body.product_page {
    #associations_slider_wrapper {
        #associations_slider {
            .item.assoc {
                .wrapper_description {
                    z-index: 1;
                    
                    .item_color {
                        margin-left: 4px;

                        form {
                            display: flex;
                            flex-wrap: wrap;

                            .form_itm.check.color {
                                [type=radio]:checked + label {
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        transform: translate(-50%, -50%);
                                        top: 50%;
                                        left: 50%;
                                        border-radius: 50%;
                                        width: 14px;
                                        height: 14px;
                                        background: 0 0;
                                        border: 1px solid $primaryColor;
                                        transition: opacity .3s ease-in-out;
                                    }
                                }
        
                                label {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }

            // For after color changed
            .item.assoc.color_has_changed {
                .imgNav.imgWrapper {
                    #nbr_visu_1 {
                        display: none;
                    }
                }

                .wrapper_description {
                    .product_name {
                        grid-column: 1;
                        grid-row: 1;

                        .item_name.subtitle {
                            display: none;
                        }
                    }

                    .wrap_prix_eclat {
                        grid-column: 1 / 3;
                        grid-row: 2;
                    }

                    .wrap_rolloverproduit {
                        position: unset;
                        grid-column: 1 / 3;
                        grid-row: 3;
                        width: 100%;
                        min-height: unset;
                        background: $white;
                        opacity: 1;
                        transition: none;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .add_wishlist.addToWishlistButton {
                            display: none;
                        }

                        .rollover_left.achat_express {
                            width: 100%;
                            margin-top: 5px;

                            .titre_choices_list {
                                display: none;
                            }

                            .simplebar-content {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: unset;
                                flex-wrap: wrap;

                                .form_itm.check.color {
                                    position: relative;
                                    width: auto;
                                    height: auto;
                                    margin: 4px 0 0.5rem 4px;

                                    &:not(:last-child) {
                                        margin-right: 1rem;
                                    }

                                    &:first-of-type label {
                                        &::after {
                                            content: '';
                                            position: absolute;
                                            transform: translate(-50%, -50%);
                                            top: 50%;
                                            left: 50%;
                                            border-radius: 50%;
                                            width: 14px;
                                            height: 14px;
                                            background: 0 0;
                                            border: 1px solid $primaryColor;
                                            transition: opacity .3s ease-in-out;
                                        }
                                    }

                                    [type=radio]:checked + label {
                                        &::after {
                                            content: '';
                                            position: absolute;
                                            transform: translate(-50%, -50%);
                                            top: 50%;
                                            left: 50%;
                                            border-radius: 50%;
                                            width: 14px;
                                            height: 14px;
                                            background: 0 0;
                                            border: 1px solid $primaryColor;
                                            transition: opacity .3s ease-in-out;
                                        }
                                    }

                                    label {
                                        width: 10px;
                                        height: 10px;
                                        display: block;
                                        border-radius: 50%;
                                        border: 1px solid rgba(0, 0, 0, .2);
                                        margin-right: 0;
                                    }
                                }
                            }
                        }

                        .form_submit.bloc_add_color {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}