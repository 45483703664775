@function imgCoord($l, $x) {
    $out: 0;

    @if $l >1 {
        $out: (
            (1 / ($l - 1)) * $x) * 100%;
    }

    @return $out;
}

@mixin bgIconCoord($item, $col, $row) {
    $len: (
        $col * $row
    );
$y: 0;
background-size: ($col * 100%) auto;

@for $i from 1 through $len {
    $x: (
        $i - 1) % $col;

    @if ($i - 1)>0 and $x ==0 {
        $y: $y + 1;
    }

    @if ($item ==$i) {
        background-position: imgCoord($col, $x) imgCoord($row, $y
        );
}
}
}

body {

    /* Empeche le resize des elements sous 1024px */
    @media screen and (max-width: 1023px) {
        min-width: 1008px;
    }

    #site_global_wrap {
        padding-top: 156px;
    }

    &.lightbox_freeze {
        height: 100%;
        overflow: hidden;
    }

    &.promo_on {
        #site_global_wrap {
            padding-top: 196px;

            @media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 1) {
                padding-top: 160px;
            }

            .lightbox#popup_savoir_plus {
                height: auto;
            }
        }

        #topLogin {
            top: calc(84px + 40px);

            @include extraLargeScreen {
                top: calc(84px + 35px);
            }
        }
    }

    &.homepage {
        #site_global_wrap {
            padding-top: 0;
        }
    }

    &.body_login {
        .accountTitleWrapper {
            margin-top: 5rem;
            padding-top: 3rem;
        }
    }

    &.body_login {
        #site_global_wrap {
            padding-top: 100px;
        }

        &.promo_on {
            #site_global_wrap {
                padding-top: 140px;
            }
        }
    }

    &.checkout,
    &.cart.step_2.in_tunnel {
        #site_global_wrap {
            padding-top: 2rem;
        }
    }

    &.menu_left_open {
        overflow: hidden;
    }

    @media screen and (max-width: 1620px) {
        .page_wrapper {
            width: 100%;
            box-sizing: border-box;
        }

        .metaCat {
            padding: 2rem 2% !important;
        }
    }
}

.cache {
    display: none;
}

.w-checkbox-input .w-input-element:checked+.w-input-label::before,
.w-radio-input .w-input-element:checked+.w-input-label::before {
    background-color: $secondaryColor;
}

.w-input-element,
.w-submit-button,
button,
input {
    outline: 0;
}

.ill_img>img {
    position: relative;
    width: 100% !important;
    height: auto;

    /* @  if Internet Explorer 11 */
    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        width: auto !important;
    }
}

/* Ajout d'un loader personalisé le temps du lazy load */
.ill_img {
    &:before {
        content: '';
        background-size: 100%;
        opacity: 0.5;
        position: absolute;
        width: 10%;
        height: 10%;
        top: 50%;
        left: 45%;
        z-index: -1;
    }
}

/* Masquage du loader pour les pages spécifiques */
.cart_product_line,
.cover-module,
.image-image-text,
.insideImg:nth-of-type(2) {

    .imgLoaded,
    .watched {
        &:before {
            background: none !important;
        }
    }
}

/* GLOBAL SHADES */
#shad,
#shad_zoom {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.3);
    overflow-y: auto;
}

.shad {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.3);
    overflow-y: auto;

    &.actif {
        display: block;
    }

    &#shad_menu_responsive {
        &.actif {
            z-index: -1;
            opacity: 0;

            @media only screen and (max-width: 1440px) {
                z-index: 4;
                opacity: 0.4;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}

#shad_link {
    display: block !important;
    width: 100%;
    opacity: 0;
    z-index: -10;
    transition: opacity 0.2s linear, z-index 0s linear 0.2s;

    &.actif {
        opacity: 0.3;
        z-index: 8;
        transition: opacity 0.2s linear 0.35s, z-index 0.001s linear;
    }

    @media screen and (max-width: 1280px) {
        width: 55%;
    }
}

#shad_menu {
    display: block !important;
    background-color: rgba($black, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -10;
    margin-top: 0;
    transition: opacity 0.3s linear, z-index 0s linear 0.3s;

    &.sticky {
        margin-top: 0;
    }

    &.actif {
        opacity: 1;
        z-index: 5;
        transition: opacity 0.3s linear 0.2s, z-index 0s linear;
    }
}

.wrapper_newspopup_home {
    .pop_contents {
        .logo {
            width: 126px;
            height: 130px;
            margin-bottom: 54px;
        }
    }

    #socialRow {
        display: flex;
        align-items: center;
        font-family: $primaryFont;
        color: $primaryColor;
        font-weight: 500;
        font-size: 10px;
        text-transform: uppercase;

        a {
            text-decoration: none;
        }

        span {
            margin-left: 0.5rem;
        }
    }
}

#contentImgWrap {
    position: relative;
}

.btn_container {
    a {
        text-decoration: none;
    }
}

.eclat_rond {
    max-height: 16px;
}

.item {
    a {
        text-decoration: none;
    }

    .imgWrapper {
        position: relative;
        overflow: hidden;

        &:hover {
            .wrap_rolloverproduit {
                bottom: 0;

                .addToWishlistButton {
                    top: -26%;
                }
            }
        }
    }

    .wrap_rolloverproduit {
        position: absolute;
        bottom: -100%;
        z-index: 4;
        width: 100%;
        min-height: 70px;
        background: $white;
        opacity: 0.8;
        transition: all 0.2s ease-out;
        padding: 1.5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .achat_express {

            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
                width: 100%;
            }
        }

        .addToWishlistButton {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 5%;
            background-color: $white;
            border-radius: 50%;
            width: 46px;
            height: 46px;
            display: flex;
            justify-content: center;

            &:before {
                width: 30px;
                height: 30px;
            }
        }

        .productColorFieldset {
            .choices_list {
                display: flex;
                flex-wrap: wrap;
            }
        }

        form {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }

        .titre_choices_list {
            text-transform: uppercase;
            font-family: $primaryFont;
            font-weight: 500;
            font-size: 11px;
            margin-bottom: 10px;
        }

        .form_itm {
            margin-bottom: 0.5rem;

            &[data-color='blanc'],
            &[data-color='Blanc'],
            &[data-color='white'],
            &[data-color='White'],
            &[data-color='vanille'],
            &[data-color='Vanille'],
            &[data-color='champagne'],
            &[data-color='Champagne'] {
                label {
                    border: 1px solid rgba($color: $black, $alpha: 0.2);
                }
            }

            label {
                cursor: pointer;
            }
        }

        .productSizeFieldset {
            display: none;

            .choices_list {
                display: flex;
            }

            .form_itm {
                font-family: $primaryFont;
                font-size: 13px;
                font-weight: 500;

                &.size span {
                    cursor: pointer;
                }

                input.prodSize {
                    display: none;
                }
            }
        }

        .bloc_add_color {
            display: block;
            min-width: 8rem;

            button {
                outline: 0;
                border: none;
                background-color: transparent;
                width: auto;

                span {
                    display: flex;

                    &:after {
                        content: '';
                        background-image: url('../img/card.png');
                        width: 17px;
                        display: block;
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-left: 0.5rem;

                        @media screen and (-ms-high-contrast: active),
                        (-ms-high-contrast: none) {
                            background-image: url('../img/cart_small.png');
                            width: 17px;
                            height: 14px;
                            background-size: contain;
                            background-position: center center;
                        }

                        @supports (-ms-ime-align: auto) {
                            background-image: url('../img/cart_small.png');
                            width: 17px;
                            height: 14px;
                            background-size: contain;
                            background-position: center center;
                        }
                    }
                }

                &:hover {
                    background-color: transparent;
                    color: $primaryColor;
                }
            }

            .loader {
                display: none;
            }
        }

        [type='radio']:checked,
        [type='radio']:not(:checked) {
            position: absolute;
        }

        .form_itm {
            display: flex;

            &.color {

                [type='radio']:checked+label,
                [type='radio']:not(:checked)+label {
                    position: relative;
                    width: 16px;
                    height: 16px;
                    margin: 0 12px 0 0;
                    border-radius: 50%;
                }

                [type='radio']:checked+label:after,
                [type='radio']:not(:checked)+label:after {
                    content: '';
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    left: 50%;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    background: transparent;
                    opacity: 0;
                    border: 1px solid $primaryColor;
                    transition: opacity 0.3s ease-in-out;
                }

                [type='radio']:checked+label:after {
                    opacity: 1;
                }

                [type='radio']:checked+label:before,
                [type='radio']:not(:checked)+label:before {
                    display: none;
                }

                .input_check {
                    opacity: 0;
                }
            }

            &.size {
                span {
                    font-size: 0.75rem;
                }

                label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    font-size: 10px;
                    color: $primaryColor;
                    text-transform: uppercase;
                    letter-spacing: 1.5px;
                    width: auto;
                    height: 21px;
                    margin: 0 12px 0 0;
                    transition: all 0.3s ease-in-out;
                }

                [type='radio'] {
                    +label {
                        &:before {
                            content: none !important;
                        }

                        &:after {
                            content: '';
                            width: 0.7rem;
                            height: 1px;
                            top: auto;
                            bottom: 1px;
                            left: auto;
                            opacity: 0;
                            transition: opacity 0.3s ease-in-out;
                        }
                    }

                    &:checked+label {
                        &:after {
                            opacity: 1;
                        }

                        span {
                            color: $secondaryColor;
                            font-weight: 600;
                        }
                    }

                    &.disabled {
                        &+label {
                            span {
                                color: rgba($primaryColor, 0.5);
                            }
                        }
                    }
                }
            }
        }
    }
}

.form_itm {
    margin-bottom: 0.5rem;

    &[data-color='blanc'],
    &[data-color='Blanc'],
    &[data-color='white'],
    &[data-color='White'],
    &[data-color='vanille'],
    &[data-color='Vanille'],
    &[data-color='champagne'],
    &[data-color='Champagne'] {
        label {
            border: 1px solid rgba($color: $black, $alpha: 0.2);
        }
    }
}

.faq .faq_content .theme_global {
    display: none;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;

    &.actif {
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

.faq .faq_content .theme_page {
    display: -webkit-box;
    display: flex;
    height: 0;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in;
    transition: opacity 0.5s ease-in;
}

.w-input-element:not([type='radio']):not([type='checkbox']),
input[type='email'].w-input-element,
input[type='password'].w-input-element,
input[type='text'].w-input-element,
textarea.w-input-element {
    appearance: none;
}

/* ADD ALERT */
.btnAddBasketWrapper {
    max-width: 100%;
    width: 100%;
    font-family: $primaryFont;

    &.form_submit {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .alert_stock {
        font-size: 0.75rem;
        line-height: unset;
        letter-spacing: unset;
        font-weight: 200;
        color: $black;
        margin-bottom: 0.5rem;
    }

    .bloc_add_alert_form {
        width: 100%;
        overflow: hidden;
        @include flexbox(row, space-between, center);
        border: 1px solid $primaryColor;

        .w-form-line {
            margin: unset;
            justify-content: space-between;
            width: 100%;

            .w-input-container {
                width: 70%;

                .w-input-element.inputErr {
                    border: 2px solid $red2;
                    color: $red2;
                }

                @media screen and (max-width: 1500px) {
                    width: 60%;
                }

                &+.form_submit {
                    width: 35%;

                    @media screen and (max-width: 1500px) {
                        width: 40%;
                    }

                    button {
                        background-color: unset;
                        width: 100%;
                        text-align: center;
                        cursor: pointer;
                        height: 2.8125rem;
                    }

                    span {
                        font-size: 0.628rem;

                        @media screen and (max-width: 1199px) {
                            font-size: 0.64rem;
                            line-height: 1.8;
                        }

                        line-height: unset;
                        letter-spacing: 0.05rem;
                        font-weight: 700;
                        color: $black;
                        text-transform: uppercase;
                        background-color: transparent;

                        @media screen and (max-width: 1199px) {
                            font-size: 0.64rem;
                            line-height: 1.8;
                        }
                    }
                }
            }

            .w-input-element {
                text-overflow: ellipsis;
            }

            .w-input-element,
            .w-submit-button {
                background-color: unset;
                border: unset;
                border: unset;
            }

            .w-submit-button {
                &.w-loader {
                    width: 100%;
                }
            }
        }
    }

    .bloc_add_alert_confirmation {
        font-size: 0.65rem;

        p {
            span {
                display: inline-block;
                margin-top: 0.5rem;
            }
        }
    }

    .alert_return {
        cursor: pointer;
        position: absolute;
        top: 0.7rem;
        right: 0.5rem;
    }

    .roll_preco {
        position: absolute;
        top: 115%;
        left: 0;
        font-family: $secondaryFont;
        font-size: 16px;
        line-height: 18px;
    }
}

.lookbook .btnAddBasketWrapper {
    .bloc_add_alert_form {
        .w-form-line {
            .w-input-container {
                width: 75%;

                &+.form_submit {
                    width: 25%;
                }
            }
        }
    }

    .alert_return {
        top: 0.5rem;
        right: 0.5rem;
    }
}

/* PRODUCT w/ achat express */
.productBigPictures {
    div {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: calc(648 / 518 * 100%);
        margin-bottom: 10px;

        span:nth-of-type(2) {
            opacity: 0;
        }

        &:first-child {
            z-index: 2;
            transition: all 0.2s ease-out;
        }

        &:nth-child(2) {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 3;
            transition: opacity 0.2s ease-out;

            &:hover {
                opacity: 1;

                span {
                    opacity: 1;
                }
            }
        }
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
    }

    .block_vid {
        display: block;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            border: 0;
            width: 100%;
            height: 100%;
        }
    }
}

body.tablet {
    .item .wrap_rolloverproduit {
        display: none !important;
    }
}

/*Fullpage search loader */
.fullpage-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55vh;

    span {
        font-family: $primaryFont;
        font-weight: 900;
        font-size: 12 / 16 * 1rem;
        color: $black;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    &:before {
        display: block;
        width: 2.5em;
        height: 2.5em;
        background: transparent url(../svg/loader_custom.svg) center no-repeat;
        background-size: cover;
        margin-right: 0.75em;
        animation: revolution 1.6s steps(16, start) infinite;
        content: '';
    }
}

/* SIMPLE BAR FLEX */
.imgWrapper {
    .wrap_rolloverproduit {
        form {
            .rollover_left {
                min-width: 55%;

                .ligne_form {
                    .choices_list {
                        max-height: 72px;
                        padding-top: 3px;
                        padding-left: 3px;
                        overflow-x: visible;
                        overflow: auto;

                        .simplebar-wrapper {
                            max-width: 100% !important;
                        }

                        .simplebar-content {
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }
                }
            }
        }
    }
}

.form_itm.color label {
    border: 1px solid rgba(0, 0, 0, 0.2);
}

/* masquer le template de recherche au chargement des pages */
[v-cloak] {
    display: none;
}