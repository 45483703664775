.metaFilter {
    border-bottom: 1px solid $borderColor;

    .metaFilter_list {
        gap: clamp(1rem, fs-vw(28, 1920), 2rem);
        width: calc(100% - 40px);
        padding: 0 0 1.5rem;
        margin: 0 auto;
        @include flexbox(row, center, center);
        flex-wrap: wrap;
    
        .metaFilter_list_item {
            @include wshopFont($primaryFont, 9px, normal, 1.2px, 700, $primaryColor);
            text-transform: uppercase;

            // resize font size for screen larger than 13 inches
            @media screen and (min-width: 1248px) {
                @include wshopFont($primaryFont, 11px, normal, 1.2px, 700, $primaryColor);
            }

            // resize font size for screen below 13 inches
            @media screen and (max-width: 1248px) {
                @include wshopFont($primaryFont, 9px, normal, 1.2px, 700, $primaryColor);
            }
    
            a {
                white-space: nowrap;
                text-decoration: none;
            }
        }
    }
}