// *****************************************************************************
//  TypeFace
//
//  A font-face declaration generator mixin
//  TypeFace aims at normalizing font-face declarations in the WSHOP
//
//  @author Tim BEARD <tb@wshop-cloudcommerce.com>
//  @since  2019-03-07
//
//  @param  String  $family     Font family name
//  @param  Array   $variants   A map of font weights arrays
//  @param  String  $root       Relative path to fonts folder root
// *****************************************************************************

// Helper function thatReturns numeric font weight from given font weight string
// May also be used for any font-weight rule
@function fontWeight($weight) {

    $fontWeights: (
        thin: 100,
        hairline: 100,
        extralight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        book: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        heavy: 900,
        black: 900,
        std:850
    );

    @return map-get($fontWeights, $weight);
}

// Helper function that builds path to a font file
// Shouldn't be used outside the main mixin
@function buildPath($root, $family, $style, $weight) {

    // Convert font family name to lowercase and build filename
    $lowerFam: to-lower-case($family);
    $file: '#{$lowerFam}-#{$style}-#{$weight}';

    // Return full relative path to file
    @return '#{$root}/#{$lowerFam}/#{$style}/#{$weight}/#{$file}';
}

// Main mixin where the magic happens
@mixin typeFace($family, $variants, $root: '../fonts') {

    @each $style, $fontWeights in $variants {

        @each $weight in $fontWeights {

            $path: buildPath($root, $family, $style, $weight);

            @font-face {
                font-family: $family;
                font-style: $style;
                font-display: swap;
                font-weight: fontWeight($weight);
                src: url('#{$path}.woff2') format('woff2'),
                     url('#{$path}.woff') format('woff');
            }
        }
    }
}


/**
 * EXEMPLE

    $playfair: (
        normal: (regular),
        italic: (regular)
    );

    $oswald: (normal: (medium));

    @include typeFace('Playfair', $playfair);
    @include typeFace('Oswald', $oswald);

    $primaryFont: 'Playfair', serif;
    $secondaryFont: 'Oswald', sans-serif;

*/

