.submenu_wrapper .submenu .ss_menu_categ_wrapper .insideCat {
    .wrap_ul {
        span {
            cursor: pointer !important;
        }

        &.actif {
            ul.objet {
                display: block;
            }
        }
    }
}

/* Menu tablette */
#left_menu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: auto;
    margin-left: 50px;
    transform: translateY(-50%);
    z-index: 5;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 1) {
        display: block;
        background: $white;
        width: 390px;
        height: calc(100vh - 120px);
        padding: 25px 35px;
        left: 0;
        top: 120px;
        margin-left: 0;
        opacity: 0;
        transform: translateX(-100%);
        transition: all 0.35s ease-in-out;

        .menu_tablet_wrapper {
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 100%;
            display: block;
            -ms-overflow-style: none;
             scrollbar-width: none;

            &::-webkit-scrollbar {
              display: none;
            }

            .main_menu_itm {
                text-align: center;
                font-size: 17px;

                .submenu_wrapper {
                    .submenu {
                        .ss_cat {
                            .ss_cat_name {
                                font-size: 16px;
                            }

                            .wrap_ul {
                                li {
                                    a {
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .menu_visuel_wrapper {
            display: none !important;
        }

        &.open {
            opacity: 1;
            transform: translateX(0%);
            z-index: 30;
        }
    }

    .main_menu_itm {
        font-family: $primaryFont;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 1rem;

        .submenu_wrapper {
            display: none;

            .submenu {
                padding-left: 0;

                .ss_cat {
                    margin: 0 0 15px 0;

                    .ss_cat_name {
                        font-weight: 600;
                        margin-bottom: 0.75rem;
                        font-size: 13px;
                        text-transform: none;
                        display: inline-block;
                    }

                    .wrap_ul {
                        .objet {
                            min-width: 100% !important;
                            max-height: 10rem;
                            overflow-y: scroll;
                            overflow-x: hidden;
                            display: block;

                            .lb-v-scrollbar {
                                background-color: $borderColor;

                                .lb-v-scrollbar-slider {
                                    background: $primaryColor;
                                }
                            }
                           }

                        li {
                            display: block;
                            margin-bottom: 0.5rem;
                            a {
                                font-size: 13px;
                                font-size: 400;
                                text-transform: none;
                            }
                        }
                    }
                }
            }

            &.opened {
                opacity: 1;
                z-index: 2;
            }
        }

        &.important {
            .entry {
                color: $secondaryColor;
                margin-bottom: 8px;

                a {
                    &:before {
                        background-color: $secondaryColor;
                    }
                }
            }
            .submenu_wrapper {
                max-height: 100vh;
                opacity: 1;
                z-index: 2;
                padding-top: 10px;
                padding-left: 10px;
            }
        }
    }
}

body.promo_on {
    #left_menu {
        @media (hover: none) {
            height: calc(100vh - 160px);
            top: 160px;
        }
    }
}