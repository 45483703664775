.btnAddBasketWrapper .btnAddBasket,
.loader {
    opacity: 1;
    width: 245px;
    height: 45px;
    text-transform: uppercase;
    @include flexbox(row, center, center);
    transition: all .2s ease-out;
    cursor: pointer;
    pointer-events: unset;
}

.btnAddBasketWrapper .btnAddBasket {
    @include wshopFont($primaryFont, 0.688rem, unset, .1rem, 700, $primaryColor);
    background-color: $white;
    border: 1px solid $primaryColor;

    &:hover {
        @include wshopFont($primaryFont, 0.688rem, unset, .1rem, 700, $white);
        background-color: $primaryColor;
        border: 1px solid $white;
    }

    &#btn_add_cart {
        span {
            width: 100%;
            height: 45px;
            line-height: 45px;
        }

        & + .loader {
           height: 100%; 
        }
    }
}

.loader {
    @include wshopFont($primaryFont, 0.688rem, unset, .1rem, 700, $white);
    background-color: $primaryColor;
    border: 1px solid $white;

    &:hover {
        background-color: $black;
    }
}

.form_submit {
    position: relative;

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background: #3C3C3C url(../svg/three_dots.svg) center no-repeat;
        background-size: auto 25%;
        width: 245px;
        height: 100%;
    }

    &.loading {
        .loader {
            z-index: 2;
        }
    }
}