.productRightPart .bloc_description {
    width: 100%;
    height: 125px;
    padding: 2.8125rem 5% 0 0;
    outline: 0;
    position: relative;
    left: -20px;

    &.withPreorder {
        padding: 3.5rem 5% 0 0;
    }

    .featWrapper {
        @include flexbox(row, flex-start, flex-start);

        & > div {
            width: 100%;
            position: relative;
        }

        .dropdown {
            display: flex;


            .intitule_container {
                @include wshopFont($primaryFont, 0.688rem, unset, .05rem, 700, $primaryColor);
                text-transform: uppercase;
                cursor: pointer;
                transition: all .5s ease-in-out;
                white-space: nowrap;
            }

            &:not(:last-child) {
                margin-bottom: .5rem;
            }

            &.bgSprite.bfr {
                @include flexbox(row, flex-start, center);
                padding-left: 1.25rem;

                &:before {
                    opacity: 0;
                    transition: all .5s ease-in-out;
                    position: relative;
                    left: 0;
                    top: -1px;
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin: 0;
                }
            }
           
            .os-scrollbar-handle {
                opacity: 0.1;
            }

            &:hover, &.active_description {
                .intitule_container {
                    color: $secondaryColor;
                }

                .dropdown_content {
                    opacity: 1;
                    max-height: 360px;
                    transition: max-height 0.5s ease-in;
                }

                &.bgSprite.bfr::before {
                    opacity: 1;
                }
            }
        }
        
        .dropdown.active_description {
            .dropdown_content {
                max-height: 450px;
            }
        } 

        .dropdown_content {
            max-height: 0;
            transition: max-height 0.5s ease-out;
            overflow: hidden;
            padding-left: 3rem;
            @include wshopFont($secondaryFont, 1rem, unset, unset, 400, $primaryColor);
            opacity: 0;
            overflow: hidden;
            position: absolute;
            left: 100px;
            top: 0;
            width: calc(100% - 100px);
            padding-bottom: 20px;

            .os-padding {
                .os-viewport {
                    padding-right: 20px;
                    padding-bottom: 20px;
                }
            }
        }
    }
}

body.en {
    .productRightPart .bloc_description {
        .featWrapper {
            .dropdown_content {
                left: 150px;
                width: calc(100% - 150px);
                .os-content {
                    padding: 0 !important;
                }
            }
        }
    }
}