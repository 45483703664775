.metaCat {
    width: 100%;
    border: 1px solid $borderColor;
    margin-top: 4rem;
    padding: 2rem 0;
 

    .metaCat_container {
        max-width: 1200px;
        margin: 0 auto;
        @include flexbox(row, space-between, center); 
    }

    .metaCat_title {
        @include wshopFont($primaryFont, .75rem, unset, unset, 700, $tertiaryColor);
        text-transform: uppercase;
    }

    .metaCat_list {
        width: 80%;
        @include flexbox(row, space-between, center);

        .metaCat_list_item {
            @include wshopFont($primaryFont, .75rem, unset, unset, 700, $primaryColor);
            text-transform: uppercase;

            a {
                text-decoration: none;
            }
        }
    }
}