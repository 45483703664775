.faq_content {
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;

    .nav_moncompte{
        width: 100%;
        text-align: center;
        padding: 2rem 0;
        margin: 0 0 1.5rem;
        .faq_title{
            font-family: $primaryFont;
            font-size: 1.35rem;
        }   
    }

    .site_search {
        width: 60%;
        margin: 2rem auto;
        position: relative;
    
        .w-input-container {
            &:after {
                content: '';
                display: block;
                width: 1.7rem;
                height: 1.7rem;
                background: url('../svg/spritesheet.svg');
                background-repeat: no-repeat;
                @include bgIconCoord(18, 5, 5);
                position: absolute;
                right: 0.5rem;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    
        .search_results {
            display: none;
            border: 1px solid #000;
            border-top: none;
            background: #fff;
            position: absolute;
            top: 95%;
            width: 100%;
            z-index: 2;
            padding: 0 1em;
            font-size: .7rem;
            letter-spacing: .07rem;
            transition: all 0.2s ease-out;
            max-height: 30rem;
            overflow-y: scroll;

            @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
                /***@ IE10+ specific styles go here */  
                overflow-y: auto;
             }
            
            /***@  width */
			&::-webkit-scrollbar {
				width: 10px;
			}

			/***@ Track */
			&::-webkit-scrollbar-track {
			    background: #ffffff; 
			}

			/***@ Handle */
			&::-webkit-scrollbar-thumb {
                background: #f4ebe6;
                border-radius: 10px;
			}

			/***@ Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
			    background: #f4ebe6; 
			}
    
            li {
                min-height: 1.75rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
                font-family: $primaryFont;
                color: #949494;
                font-size: 11px;
    
                &:first-of-type {
                    border: none;
                }
            }
        }
    }

    #theme_global {
        display: flex;
        justify-content: space-between;
        margin: 2rem 0;

        .theme {
            border: 1px solid rgba($color: #000000, $alpha: .3);
            background-color: $quaternaryColor;
            width: 100%;
            max-width: 241px;
            padding: 22px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $primaryFont;
            text-transform: uppercase;
            font-size: 12px;
            cursor: pointer;
            letter-spacing: .07rem;
            font-weight: bold;
            position: relative;

            @media only screen and (max-width: 1260px) {
                max-width: 200px;
                padding: 20px;
                font-size: 10px;
            }


            &.actif {
                .intitule {
                    color: $secondaryColor;
                    &:before {
                        content: '';
                        width: 20px;
                        height: 20px;
                        background-image: url(../svg/spritesheet.svg);
                        position: absolute;
                        top: 80%;
                        left: 50%;
                        transform: translate(-50% ,-50%);
                        @include bgIconCoord(16, 5, 5);
                    }
                }
                
            }

            .intitule h2 {
                font-size: 12px;
                font-family: $primaryFont;
                text-transform: uppercase;

                @media only screen and (max-width: 1260px) {
                    font-size: 10px;
                }
            }

            &.actif .intitule h2 {
                color: $secondaryColor;
            }
                
        }
    }

    .theme_detail {
        width: 100%;
    }

    .faq_question {
        font-family: $primaryFont;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: .05rem;
        font-weight: 500;
        height: 52px;
        display: flex;
        align-items: center;
        border-top: 1px solid $quaternaryColor;
        width: 100%;
        justify-content: space-between;
        box-sizing: border-box;

        &::after {
            content: " + ";
            width: 16px;
            height: 16px;
            display: flex;
            margin-right: 1rem;
        }

        &.active {
            color: $secondaryColor;
            font-weight: bold;
            &::after {
                content: " - ";
            }
            
        }

        &:first-child {
           border: none; 
        }

        &:last-child {
           border-bottom: 1px solid $quaternaryColor; 
        }
    }

    .faq_reponse {
        font-size: 16px;
        text-align: justify;
        padding: 26px 1px;
    }


    #theme_page {
        min-height: 200px;
        transition: all .2s ease-in-out;
        &.actif {
            height: auto;

            opacity: 1;
        }
    }
}