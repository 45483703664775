@import '../components/product/product_right';
@import '../components/product/product_left';
@import '../components/product/product_bloc_desc';
@import '../components/product/product_metaCat';

.product_main_wrapper {
    margin-bottom: 6rem;

    .home-module.slider-module .home-column span {
        margin-bottom: 1rem;
        text-align: center;
        @include wshopFont(
            $primaryFont,
            1.5rem,
            unset,
            0.1rem,
            700,
            $primaryColor
        );
        text-transform: uppercase;
    }
}

@media screen and (max-width: 1440px) {
    .product_page {
        .product_main_wrapper {
            margin-bottom: 10rem;
        }
    }
}

.prod_top_wrapper {
    @include flexbox(row, flex-start, flex-start);
}

.product_page {
    .breadcrumbs_wback {
        display: flex;
        align-items: center;
        max-width: 1620px;
        margin: 1rem auto;

        #btn_retour_product span {
            font-family: montserrat, sans-serif;
            font-size: 0.563rem;
            line-height: unset;
            letter-spacing: unset;
            font-weight: 500;
            color: $primaryColor;
            text-decoration: underline;
            text-transform: uppercase;
            margin-right: 1rem;

            &::before {
                content: '';
                border: solid $primaryColor;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 2px;
                transform: rotate(135deg);
                margin-right: 3px;
            }
        }

        @media screen and (max-width: 1680px) {
            #btn_retour_product {
                margin-left: 3rem;
            }
        }

        #breadcrumbs {
            max-width: unset;
            margin: unset;

            @media screen and (max-width: 1680px) {
                .breadcrumbs {
                    margin-left: 0;
                }
            }
        }
    }

    #product_addcart {
        margin-bottom: 2rem;

        #bloc_add_alert {
            position: relative;

            #keep_alive_stock {
                position: inherit;
                top: 0;
                box-sizing: border-box;
                width: 245px;
                background-color: $white;
            }

            .bloc_add_alert_confirmation {
                position: inherit;
                top: 0;
                box-sizing: border-box;
                width: 245px;
                background-color: $white;
            }
        }

        #bloc_add_basket {
            &.inactiv {
                .btnAddBasket {
                    cursor: default;

                    &:hover {
                        background-color: $white;
                        border: 1px solid $primaryColor;
                        color: $primaryColor;
                    }
                }
            }
        }

        .roll_preco {
            font-size: 1rem;
        }
    }

    #wrap_info_np {
        width: 100%;

        .item_name {
            display: flex;
            width: 100%;

            .title {
                flex-direction: column;
                width: 100%;

                #itm_name {
                    width: 100%;

                    span {
                        display: block;
                    }

                    .itm_name {
                        text-transform: uppercase;
                        font-family: $primaryFont;
                        font-size: 0.813rem;
                        font-weight: 500;
                        color: $black;
                        margin-bottom: 0.75rem;
                    }

                    #item_des {
                        /*font-family: $primaryFont;
                        font-size: 0.813rem;
                        font-weight: 400;
                        color: $black;*/
                        font-family: $primaryFont;
                        font-size: 1rem;
                        line-height: unset;
                        letter-spacing: 0.05rem;
                        font-weight: 400;
                        color: $primaryColor;
                        text-transform: unset;
                        width: 100%;
                    }
                }
            }

            #addToWishlistButton {
                margin-left: 0.89rem;
            }
        }

        a.learn_more {
            position: relative;
            top: 7px;
        }

        .item_price_wrapper {
            position: relative;
            top: 7px;
            margin-bottom: 1.5rem;
        }

        .eclat_rect {
            margin-bottom: 0.3rem;
        }
    }
}

// Handle eclat style in assoc product
.item.assoc {
    .eclat_rond {
        height: 100%;

        img {
            height: 100%;
            width: 100%;
        }
    }
}

.bgSprite.existToWishlistButton.aft:after,
.bgSprite.existToWishlistButton.bfr:before {
    background-position: -20px -80px !important;
}
