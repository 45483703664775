.category_nav_wrapper .wrap_flitre_pager {
	position: relative;
	box-sizing: border-box;
	margin: 0 auto;
	padding: 1rem 0;
	width: 100%;
	max-width: 1620px;
	@include flexbox(row, center, center);

	@include medium {
		padding: 1rem 2rem;
	}

	.main_filter_wrapper {

		@media screen and (max-width: 1340px) {
			margin-left: 0;
		}

		.block_filters {
			z-index: 3;
		}

		.blockwrap_filters {
			@include flexbox(row, center, flex-start);
			width: 100%;

			@media screen and (max-width: 1160px) {
				@include flexbox(row, flex-start, flex-start);
			}
		}

		.blockwrap_filters .filter_wrapper {
			position: relative;
			cursor: pointer;
			margin: 0 1rem;
			max-width: 8rem;

			@media screen and (max-width: 1570px) {
				&:nth-child(n+7) {
					.selected_options {
						right: 0;
					}
				}
			}

			@media screen and (max-width: 1160px) {
				margin-right: 0.5rem;
				margin-left: 0;
			}

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			// Filter name
			.filter_name {
				@include flexbox(row, space-between, center);
				@include wshopFont($primaryFont, 7px, normal, 0.96px, 600, $primaryColor); 
				text-transform: uppercase;

				// resize font size for screen larger than 13 inches
				@media screen and (min-width: 1248px) {
					@include wshopFont($primaryFont, 9px, normal, 0.96px, 600, $primaryColor); 
				}

				// resize font size for screen below 13 inches
				@media screen and (max-width: 1248px) {
					@include wshopFont($primaryFont, 7px, normal, 0.96px, 600, $primaryColor); 
				}

				&::before {
					order: 2;
					transition: all .2s ease-out;
					width: 14px;
					height: 14px;
				}
    
				span {
					text-decoration: none;
					white-space: nowrap;
				}

				@media screen and (max-width: 1160px) {
					letter-spacing: 0;
				}

				@media screen and (max-width: 1024px) {
					font-size: 0.6rem;
				}
			}

			// Filter options
			.selected_options {
				@include flexbox(row, space-between, flex-start);
				position: absolute;
				top: 1.15rem;
				flex-wrap: wrap;
				opacity: 0;
				z-index: -1;
				transition: all .2s ease-out;
				box-shadow: 0px 10px 10px rgba(95, 95, 95, 0.411);
				background: $white;
				padding: 1rem 2rem;
				width: 370px;

				@media screen and (max-width: 1340px) {
					padding: 1rem 1.5rem;
					width: 345px;
				}

				@media screen and (max-width: 1160px) {
					width: 300px;
				}

				.form_itm {
					width: 48%;

					&:not(:last-child) {
						margin-bottom: 1rem;
					}

					&:not(.color) {
						@include flexbox(row, unset, unset);

						[type=checkbox]::before {
							background-color: $secondaryColor;
						}
					}

					&.color {
						position: relative;
						margin-right: .9rem;
						margin-bottom: 1rem;
						width: unset;

						&:nth-child(5n) {
							margin-right: 0;
						}

						[type=checkbox] {
							margin: 0;
							border-radius: 50%;

							&:checked {
								border-color: $secondaryColor;
							}
						}

						label {
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							border-radius: 50%;
							width: 1rem;
							height: 1rem;
						}

						.color_picto {
							display: block;
							border-radius: 50%;
							width: 100%;
							height: 100%;
						}
					}

					&.size {
						width: 28%;
					}

					[type=checkbox] {
						margin-right: 1rem;
						width: 0.9rem;
						height: 0.9rem;
						@include flexbox(column, center, center);
						-webkit-appearance: none;
						cursor: pointer;
						outline: none;
						border: 1px solid $borderColor;

						&::before {
							display: block;
							opacity: 0;
							transition: opacity .2s ease-out;
							width: .8rem;
							height: .8rem;
							content: "";
						}

						&:checked::before {
							opacity: 1;
						}
					}
				}

				.tri_price {
					text-decoration: none;

					&:not(:last-child) {
						margin-bottom: 1rem;
					}

					&.actif {
						color: $secondaryColor;
						font-weight: 700;
					}
				}

				label span,
				.form_itm label,
				.tri_price {
					@include wshopFont($primaryFont, .688rem, 1.5, .1rem, 400, $primaryColor);
					cursor: pointer;
					text-transform: uppercase;
				}

				.form_itm label {
					font-size: 7px;

					// resize font size for screen larger than 13 inches
					@media screen and (min-width: 1248px) {
						font-size: 9px;
					}

					// resize font size for screen below 13 inches
					@media screen and (max-width: 1248px) {
						font-size: 7px;
					}
				}

				[for="two"] [type="radio"],
				[for="one"] [type="radio"] {
					position: absolute;
					left: -9999999px;
					opacity: 0;
				}
				input[type="radio"]:checked + span {
					color: $secondaryColor;
					font-weight: 700;
				}
			}

			&#filter_color .selected_options {
				width: 222px;
				@include flexbox(row, flex-start, center);
				flex-wrap: wrap;
			}

			&#filter_prices .selected_options {
				@include flexbox(column, space-between, flex-start);
				width: 226px;
			}

			&:hover {
				.filter_name {
					color: $secondaryColor;

					&::before {
						transform: rotate(-180deg);
					}
				}

				.selected_options {
					opacity: 1;
					z-index: 10;
				}
			}
		}
	}
}

#block_selected_filter {
	margin: 0rem auto;
	max-width: 1620px;
	@include flexbox(row, center, center);
	flex-wrap: wrap;

	div {
		@include flexbox(row, unset, unset);
	}

	.selected_filter,
	.remove_filter {
		@include wshopFont($primaryFont, .625rem, unset, .1rem, 400, $tertiaryColor);
	}

	.remove_filter {
		cursor: pointer;
		text-decoration: underline;

		& + .remove_filter {
			display: none;
		}
	}

	.selected_filter {
		margin: .5rem;
		border-radius: 20px;
		padding: 0.475rem 1.2rem;
		@include flexbox(row, space-between, center);
		border: 1px solid $borderColor;
		text-transform: uppercase;

		&.bgSprite.bfr:before {
			order: 2;
			opacity: .5;
			cursor: pointer;
			margin-left: .5rem;
			width: .8rem;
			height: .8rem;
		}
	}
}