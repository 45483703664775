/***** FOOTER SATELLITE *****/

.wrapper_content_sat {
	margin: 6rem auto 3rem;

	.content_sat {
		max-width: 1000px;
		margin: 0 auto;

		.row {
			margin-bottom: 2.5rem;

			/* FIRST TITLE */
			&:first-child h2 {
				font-size: 1.25rem;
			}

			/* TITLES */
			h2 {
				@include wshopFont($primaryFont, .75rem, unset, unset, 700, $primaryColor);
				margin-bottom: .5rem;
			}

			/* SUBTITLES */
			strong,
			em {
				@include wshopFont($primaryFont, .75rem, unset, unset, 400, $primaryColor);
			}

			div {
				@include wshopFont($secondaryFont, 1rem, unset, unset, 400, $primaryColor);
			}
		}
	}
}

.materials {
	.bain {
		max-width: 1500px;
		margin: auto;
		padding: 0 3rem;
	}

	.history_container {
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		&:first-of-type {
			margin: 2rem 0 5rem 0;
		}

		.img_mogolie_bloc {
			display: flex;
			width: 70%;
			justify-content: space-around;
			align-items: center;

			img {
				width: 35%;
			}

			.two_img_mogolie iframe,
			.two_img_mogolie img {
				width: 100%;
			}

			img:nth-of-type(2) {
				margin-right: 2rem;
			}

			&:nth-of-type(2) {
				display: flex;
				align-items: center;

				.img_mongolie_bottom {
					padding: 0 2rem;
				}
			}
		}

		.text_mongolie_bloc {
			width: 30%;

			p{
				text-align: justify;
			}
		}

		h2 {
			text-transform: uppercase;
			font-family: $primaryFont;
			color: $primaryColor;
			font-size: 1.3rem;
			margin-bottom: 2rem;
		}
	}
}

.engagement {
	.engagementWrapper {
		max-width: 1500px;
		margin: auto;
	}
	.engagement_container {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}
	.engagement_content_bloc {
		width: 50%;
		padding: 1rem;
		img {
			width: 100%;
		}
		& div:nth-child(1) {
			margin-bottom: 1.5rem;
		}
	}
	.engagement_image_wrapper {
		display: flex;
		flex-direction: column;
		&.two_img {
			flex-direction: row;
			width: 100%;
			justify-content: space-between;
			img {
				width: 48%;
			}
		}
	}
	.engagement_content_inner p {
		text-align: justify;
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}
	.engagement_content_inner h2 {
		font-size: 1.3rem;
		font-family: $primaryFont;
		color: $primaryColor;
		margin-bottom: 1.2rem;
	}
	li[data-tab="sustainable"] {
		position: relative;
			&:before {
				background-image: url(../img/satellite/sfa_1.png);
				background-size: 100% auto;
				width: 27px;
				height: 31px;
			}
	}
	.satellite_nav_bar li[data-tab="sustainable"].tabsatellite.active.bgSprite.bfr::before {
		margin-top: .5rem;
	}
}

.satellite_conseil {
	.entretien {
		max-width: 1500px;
		margin: auto;

		.satellite_intro,
		.satellite_intro_faq {
			text-align: center;
			width: 50%;
		}

		.satellite_intro {
			margin: 3rem auto;
		}

		.satellite_intro_faq {
			margin: 0 auto !important;
		}

		p.notice {
			margin: 2rem 0;
			@include wshopFont($secondaryFont, 1.2rem, unset, unset, 400, $primaryColor);
		}

		p {
			&:last-of-type {
				margin-top: 2rem;
			}
		}

		@media screen and (max-width: 1024px) {
			img {
				width: 50%;
			}
		}

		#machine {
			h4:first-of-type {
				padding-top: 2rem;
			}
		}

		#etiquette {
			.satellite_block_container {
				&:first-of-type {
					margin-top: -5rem;

					.satellite_block_txt {
						padding-top: 20rem;

						p {
							margin-bottom: 2rem;
						}

						img {
							width: 100%;
						}
					}
				}

				&:nth-of-type(2) {
					.satellite_block_txt {
						margin-top: -16rem;

						div {
							p {
								display: flex;
								align-items: center;

								&:before {
									background-image: url(../svg/spritesheet_2.svg);
									content: "";
									width: 2rem;
									height: 2rem;
									display: block;
									margin-right: 1rem;
								}
							}

							&:first-of-type {
								p:before {
									@include bgIconCoord(11, 5, 5);
									width: 3rem;
								}
							}

							&:nth-of-type(2) {
								p:before {
									@include bgIconCoord(12, 5, 5);
								}
							}

							&:nth-of-type(3) {
								p:before {
									@include bgIconCoord(13, 5, 5);
									width: 2.5rem;
								}
							}

							&:nth-of-type(4) {
								p:before {
									@include bgIconCoord(14, 5, 5);
									width: 3rem;
								}
							}

							&:nth-of-type(5) {
								p:before {
									@include bgIconCoord(15, 5, 5);
								}
							}
						}
					}
				}
			}
		}

		.satellite_block_container {
			display: flex;
			justify-content: space-around;
			align-items: center;
			margin-bottom: 3rem;

			/***@  IE10+ specific styles go here */  
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  

				img {
					padding-right: 10rem;
				}
				.satellite_block_txt {
					margin-right: 4rem;
				}
			 }

			&:nth-of-type(2) {
				img {
					margin: 0;
				}			
			}

			.satellite_block_txt {
				max-width: 40%;

				h2 {
					text-transform: uppercase;
					font-family: $primaryFont;
					color: $primaryColor;
					font-size: 1.2rem;
					margin-bottom: 2rem;
				}

				p.subTitle {
					text-transform: uppercase;
					font-family: $primaryFont;
					margin-bottom: 2rem;
					font-size: .85rem;
					font-weight: 500;
					color: #000;
					line-height: 1.5;

					&:nth-of-type(2) {
						margin-top: 2rem;
					}
				}

				ul > div {
					display: flex;
					&:before {
						content: "\002022";
						margin-right: .5rem;
					}
				}
			}
		}
	}

	/**@ version EN css */
	&.en {
		.entretien {
			#etiquette {
				.satellite_block_container {
					&:nth-of-type(2) {
						.satellite_block_txt {
							div {
								&:first-of-type {
									p:before {
										width: 6%;
									}
								}

								&:nth-of-type(3) {
									p:before {
										width: 6%;
									}
								}

								&:nth-of-type(4) {
									p:before {
										width: 6%;
									}
								}
							}
						}
					}
				}
			}			
		}
	}
}

.satellite_tab {
	opacity: 0;
	transition: opacity 0.5s ease-in;
	height: 0;
	padding: 0 3rem;
	overflow: hidden;

	&.actif {
		opacity: 1;
		height: auto;
	}
}

.satellite_nav_bar {
	display: flex;
	justify-content: center;

	li,
	a {
		cursor: pointer;

		&.bgSprite.bfr:before {
			display: none;
		}

		&.tabsatellite.active,
		&.tabsatellite.actif {
			color: #1a34a1;
			display: flex;
			flex-direction: column-reverse;

			&.bgSprite.bfr:before {
				margin: auto;
				display: block;
			}
		}
	}

	li,
	a {
		text-transform: uppercase;
		font-family: $primaryFont;
		color: $primaryColor;
		font-weight: 600;
		margin: 0 3rem 2rem;
		font-size: .8rem;
		letter-spacing: .3px;
		text-align: center;
		text-decoration: none;
	}
}
.page_satellite.entretien {
	article.satellite_tab {
		p,
		li,
		p.notice {
			@include wshopFont($secondaryFont, 1rem, unset, unset, 400, $primaryColor);
		}
	}
}
@import '../components/satellite/savoirfaire.scss';


/* Page Plan du site */

body.footer_sitemap{
	.satellite_title .uppercase_title{
	    font-size: 1.5rem;
		font-weight: bold;
		font-family: $primaryFont;
		text-align: center;
	    display: block;
    	margin-bottom: 1.5rem;
	}
	.look_list{
		width: 76%;
	    margin: 0 auto;
		h2{
			font-family: $primaryFont;
			text-align: center;
			span{
				width: 100%;
			}
		}
	}
}

/* GDT */

.satellite_guide_taille {
	.guide_tailles {
		.title_gdt {

			margin-bottom: 3.5rem;

			h1 {
				text-align: center;
				display: flex;
	            align-items: center;
	            justify-content: center;
	            font-family: $primaryFont;
	            width: 100%;
	            height: 60px;
	            font-weight: bold;
	            font-size: 1.2rem;
	            color: $primaryColor;
	            text-transform: uppercase;
	            letter-spacing: 0.08rem;
			}
		}

		.tabcontent {

			.sg-container {
				width: 100%;
				max-width: 960px;
				margin: auto;
				display: flex;
				justify-content: center;

				.sg-right,
				.sg-left {
					text-transform: uppercase;
				    text-align: center;
				    padding-right: 5rem;
				    margin: 1rem 0 2rem 1.5rem;
				    font-weight: 700;
				    line-height: 3;
				    font-size: 13.34px;
				    font-family: $primaryFont;

				    .sg-inner-container {

				    	line-height: 3;
					    padding-right: 0;
					    text-transform: none;
					    display: flex;

				    	.sg-details {
				    		margin: 1rem 1.5rem 0;
						    text-align: center;
						    padding-right: 0;
						    text-transform: none;

						    p {
						    	font-size: .8rem;
							    font-weight: 500;
							    padding-right: 0;
							    text-transform: none;
						    }

						    div {
						    	font-size: .8rem;
							    color: #6f7377;
							    font-weight: 400;
						    }

				    	}
				    }
				}

				.sg-right {

				}

				.sg-left {

				}

			}
		}
	}
}

.misc_cookiesinfo {
    #cookiesLightbox {
        &.displayCookies {
            display: block!important;
        }

        &.hideCookies {
            display: none!important;
        }

        .w-form-line.w-submit button.w-loader.hideLoader {
            z-index: -1;
            opacity: 0;
        }
    }
}

.cms_page_content:has(.gjs-landing-page) {
	opacity: 0;
	animation-name: showcontent;
	animation-duration: 2s;
	animation-delay: 1s;
	animation-fill-mode: forwards;
}

@keyframes showcontent {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }