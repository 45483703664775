.promo_bar {
    position: relative;
    height: 40px;
    transition: height 0.2s ease-in-out;

    .promo_swiper {
        height: 100%;

        .promoElemts {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    div.swiper-slide-duplicate strong,
    div.swiper-slide-prev strong,
    div.swiper-slide-next strong,
    div.swiper-slide-duplicate-prev strong,
    div.swiper-slide-duplicate-next strong,
    div.swiper-slide-active span strong {
        visibility: visible !important;
    }

    .close_promo {
        position: absolute;
        right: 2%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        cursor: pointer;
        color: $white;
    }
}

.headerTop,
.header_menu_top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    height: 120px;
    align-items: center;
    position: relative;
    max-width: 1620px;
    margin: 0 auto;
    font-family: $primaryFont;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    .ico {
        background-image: url(../svg/spritesheet_new.svg);
        background-repeat: no-repeat;
        background-size: 500% 500%;
        width: 22px;
        height: 22px;
        display: block;
    }

    &.center {
        justify-content: center;
        max-width: 100%;
    }

    a {
        text-decoration: none;
        height: 100%;
        display: flex;
        align-items: center;
        color: var(--categ-color, $primaryColor);
    }

    .mainMenu {
        display: flex;
        height: 100%;

        .main_menu_itm {
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0 1.3rem;

            @media only screen and (max-width: 1180px) {
                margin: 0 1rem;
            }

            &>span {
                padding-bottom: 15px;
            }

            &.logoBlue {
                &::before {
                    background-position: 0 75%;
                    /* Picto hover menu */
                    display: none;
                }
            }

            span {
                cursor: pointer;
            }

            &:first-child {
                margin-left: 0;
            }

            .block_lnk {
                height: 100%;
                display: flex;
                align-items: center;

                span {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &:hover {
                        cursor: pointer;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

            &.menu_static {
                .ss_menu_categ_wrapper li:not(:first-child) {
                    margin-top: 20px;
                }

                .submenu_wrapper {
                    .menu_categ_wrapper {
                        justify-content: space-between;

                        .submenu {
                            margin-right: 90px;
                            display: flex;
                            flex-direction: column;

                            @media only screen and (max-width: 1380px) {
                                margin-right: 30px;
                            }

                            .ss_menu_categ_wrapper {
                                display: block;

                                li {
                                    a {
                                        span {
                                            color: $primaryColor !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:hover {
                .has_submenu {
                    a {
                        color: var(--categ-hover-color, $primaryColor);
                    }
                }
            }

            >span {
                position: relative;
                width: fit-content;

                >a::after {
                    z-index: 90;
                    transition: all .4s ease-in;
                    content: "";
                    position: absolute;
                    bottom: 10px;
                    height: 1px;
                    right: 0;
                    width: 0;
                    background-color: var(--categ-hover-color, $primaryColor);

                }
            }

            &:hover {

                >span>a {
                    font-weight: 600 !important;

                    &::after {
                        width: 100%;
                        left: 0;
                        right: auto;
                    }
                }
            }
        }

        .ss_cat li a.ss_cat_name span {
            display: block;
            position: relative;
        }

        .ss_cat li a.ss_cat_name span::after {
            z-index: 90;
            transition: all .4s ease-in;
            content: "";
            position: absolute;
            bottom: -5px;
            height: 1px;
            left: 0;
            width: 0;
            background-color: var(--hover-color, $primaryColor);
        }

        .ss_cat li a.ss_cat_name span:hover {
            color: var(--hover-color, $primaryColor) !important;

            &::after {
                width: 100%;
                right: auto;
            }
        }

        .objet li {
            width: fit-content;
            position: relative;
            cursor: pointer;

            a {
                color: var(--spanss-color, $primaryColor) !important;
            }
        }

        .objet li a::after {
            z-index: 90;
            transition: all .4s ease-in;
            content: "";
            position: absolute;
            bottom: -5px;
            height: 1px;
            right: 0;
            width: 0;
            background-color: var(--hoverss-color, $primaryColor);
        }

        .objet li:hover {
            a {
                color: var(--hoverss-color, $primaryColor) !important;
                font-weight: 500 !important;

                &::after {
                    width: 100%;
                    left: 0;
                    right: auto;
                }
            }
        }

        #submenu_maison {

            .submenu a.cat_name {
                position: relative;
            }

            .submenu a.cat_name::after {
                z-index: 90;
                transition: all .4s ease-in;
                content: "";
                position: absolute;
                bottom: -5px;
                height: 1px;
                left: 0;
                width: 0;
                background-color: #0a259a;
            }

            .submenu a.cat_name:hover {

                &::after {
                    width: 100%;
                    right: auto;
                }
            }

            .ss_menu_categ_wrapper li a.ss_cat_name span {
                display: block;
                position: relative;
            }

            .ss_menu_categ_wrapper li a.ss_cat_name span::after {
                z-index: 90;
                transition: all .4s ease-in;
                content: "";
                position: absolute;
                bottom: -5px;
                height: 1px;
                left: 0;
                width: 0;
                background-color: $primaryColor;
            }

            .ss_menu_categ_wrapper li a.ss_cat_name span:hover {
                color: $primaryColor;
                font-weight: 600;

                &::after {
                    width: 100%;
                    right: auto;
                }
            }
        }

        &.actif {
            .block_lnk:hover {
                color: $secondaryColor;
            }

            .hovering .block_lnk {
                color: $secondaryColor;
            }
        }

        .bgSprite:before {
            position: absolute;
            top: 54%;

            @media screen and (-ms-high-contrast: active),
            screen and (-ms-high-contrast: none) {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .block_top_item,
    .main_menu_itm {
        height: 100%;
        display: flex;
        align-items: center;

        &.my_account {
            a {
                margin: 0 1rem 0 0;

                .ico {
                    background-position: 0% 50%;
                }

                .rolloverTxt {
                    font-size: 0;
                    display: none;
                }
            }
        }

        &.search {
            position: relative;

            .bgSprite.logoBlue::before {
                position: absolute;
                bottom: 15px;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
                transition: opacity 0.1s ease-out;
                background-position: 0 75%;
            }

            &:hover .bgSprite.logoBlue::before {
                opacity: 0;
            }
        }

        &.hovering .submenu_wrapper {
            visibility: visible;
            opacity: 1;
        }

        &.storelocator {
            height: 22px;
            margin-right: 1rem;

            .ico {
                background-position: 50% 0%;
            }

            .txt {
                font-size: 0;
                display: none;
            }
        }
    }

    .logoBox {
        position: relative;
        height: 100%;
        width: 100%;
        mask-image: url(../svg/n_logo.svg);
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center center;
        background-color: var(--logo-color-page, #000);
        top: 0%;
    }

    @media screen and (min-width: 1033px) and (max-width: 1660px) {
        .logoBox {
            width: 20rem;
        }

        #site_head_wrap.sticky .headerTop .logoBox {
            left: 12%;
        }
    }

    .block_logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 200px;
        height: 100%;

        .logo {
            justify-content: center;
        }
    }

    .block_top_links {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.header_menu_top {
    height: 35px;
    padding: 0 20px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 1) {
        display: none;
    }
}

.search #form_recherche {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    .ico {
        background-position: 75% 0%;
    }

    label {
        cursor: pointer;
        height: 22px;
        display: flex;
        align-items: center;
        font-size: 0;
    }

    .loader {
        justify-content: center;
        display: flex;
        align-items: center;
        width: 100%;
        background: transparent;
        color: $primaryColor;
    }
}

.search_txt {
    cursor: pointer;
    font-size: 12px;

    &:after {
        margin-left: 0.5rem;
    }

    &:before {
        content: none;
    }
}

.search {
    .wrap_search_field {
        background: rgba(255, 255, 255, 1);
        width: 100vw;
        min-height: 20vh;
        position: fixed;
        left: 0;
        right: 0;
        top: calc(120px - 40px);
        z-index: 30;

        .search_elm_wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            padding: 3.5rem 0 0;
            margin: auto;

            input {
                width: 75%;
                max-width: 845px;
                font-size: 23px;
                border-bottom: solid 1px rgba(127, 127, 127, 0.32);
                outline: 0;
                background: transparent;
                margin: 0 15px;

                &::-ms-clear {
                    display: none;
                }

                &::-webkit-input-placeholder {
                    font-size: 23px;
                    color: rgba(127, 127, 127, 0.32);
                    letter-spacing: 0.1rem;
                    padding-left: 10px;
                }

                &::-moz-placeholder {
                    font-size: 23px;
                    color: rgba(127, 127, 127, 0.32);
                    letter-spacing: 0.1rem;
                    padding-left: 10px;
                }

                &:-ms-input-placeholder {
                    font-size: 23px;
                    color: rgba(127, 127, 127, 0.32);
                    letter-spacing: 0.1rem;
                    padding-left: 10px;
                }

                &:-moz-placeholder {
                    font-size: 23px;
                    color: rgba(127, 127, 127, 0.32);
                    letter-spacing: 0.1rem;
                    padding-left: 10px;
                }
            }

            .search_txt {
                height: 27px;

                &:after {
                    width: 27px;
                    height: 27px;
                }
            }

            .close_it {
                cursor: pointer;
                opacity: 0.3;
                height: 20px;
            }
        }

        .results_list {
            width: 100%;
            max-width: 80%;
            padding-bottom: 30px;
            margin: 28px auto 0;

            >li {
                width: 75%;
                max-width: 845px;
                margin: 0 auto 1rem;
                text-transform: capitalize;
                color: $tertiaryColor;
                font-weight: 400;

                strong {
                    font-weight: 500;
                }
            }

            li span:first-of-type {
                cursor: pointer;
            }
        }
    }
}

#site_head_wrap {
    background-color: $white;
    z-index: 10;
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $subMenu;

    #show_top_cart {
        top: 74px;
    }

    &.sticky {
        #show_top_cart {
            top: calc(74px + 40px);

            @include extraLargeScreen {
                top: calc(74px + 35px);
            }
        }

        .headerTop {
            .logoBox {
                mask-image: url(../svg/logo-kujten-simple.svg);
                mask-repeat: no-repeat;
                mask-size: 80% 50%;
                display: block;
                top: 10%;
                background-color: var(--logo-color-page, #000);
            }
        }

        .submenu_wrapper {
            top: 25px;
        }
    }
}

/* SHIPPING COUNTRY / CURRENCY SWITCHER LIGHTBOX */
.globale_overlay {
    opacity: 0 !important;
}

.globale_popup_wrapper {
    --gle_font_family--h: $primaryFont !important;
    --gle_font_family--p: $primaryFont !important;
    --gle_font_family--i: $primaryFont !important;


    #globale_csc_popup {
        margin: 0 !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
        font-family: $primaryFont;
        outline: none !important;
        min-width: unset !important;
        width: 100% !important;
        max-width: 692px;
    }

    .glPopupContent {
        min-width: 692px;
        min-height: 486px;
        width: fit-content;
        height: fit-content;

        padding-top: 68px !important;
        padding-bottom: 30px !important;
    }


    .ql-editor[data-key="FormattedHeader"] {
        font-family: var(--gle_font_family--h);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1.92px;
        text-transform: uppercase;
        margin-bottom: 50px;

        .ql-align-center {
            font-size: 16px;
        }
    }
    

    .glMainContent,
    .glControls {
        margin: 0 auto !important;
        max-width: 376px;
        width: 100%;
    }

    .CustomSwitcherPopup .glPopupContent .dropDownRow .DropdownLabel {
        text-align: left;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 2.34px;
    }

    .CustomSwitcherPopup .glPopupContent .dropDownRow .dropdown select {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        border-radius: 0;
        border: 1px solid $primaryColor;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        font-weight: 600;
        
    }

    .CustomSwitcherPopup .glPopupContent .glCancelBtn {
        width: 100%;
        max-width: none;
        padding: 1.5em 1em;
        border-radius: 0;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        margin: 0 auto;
        margin-bottom: 38px;
    }

    input.Backtoshop {
        font-weight: 700;
        background: none;
        text-decoration: underline;
        text-transform: uppercase;
        cursor: pointer;
    }

}

/* NAV */
.ge-switcher-flag { 
    cursor: pointer;
}

.block_top_item.switchLang {
    position: relative;
    font-family: $primaryFont;
    height: fit-content !important;
    align-self: center;
    font-weight: 500;
    font-size: 11px !important;
    letter-spacing: .1rem !important;
    text-transform: uppercase;

    .ge-switcher-flag {
        font-size: inherit !important;
        font-weight: inherit !important;
        letter-spacing: inherit !important;
        text-transform: inherit !important;
        margin: 0 !important;
        margin-right: 1.375rem !important;

        #ge_flagPrefix {
            display: none;
        }
    }

    .switchLangBtn {
        appearance: none;
        display: flex;
        align-items: center;
        margin: 0 1.375rem 0 0;
        padding: 0;
        border: none;
        background: none;
        color: currentColor;
        font-family: inherit;
        font-weight: 700;
        font-size: 11px;
        cursor: pointer;
    
        &::after {
            content: '';
            display: block;
            width: 1em;
            height: 1em;
            
            mask-image: url(../svg/nxt_m.svg);
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
            
            background: currentColor;
            transform: rotate(90deg);
            margin-left: 5px;
        }
    }

    .switchLangWrapper {
        position: absolute;
        top: 100%;
        right: 36px;

        z-index: 10;

        font-weight: 400;
        font-family: $primaryFont;
        font-size: 11px;
        color: currentColor;

        text-transform: uppercase;
        letter-spacing: 0.1rem;
        cursor: pointer;

        transition: all 0.3s ease-in-out;
    
    }
}

#cart_top {
    cursor: pointer;

    &:hover {
        cursor: pointer;
    }

    .cart {
        height: 100%;
        margin: 0;
        display: flex;
        position: relative;

        &.has_item {
            &::after {
                position: absolute;
                right: 0;
                content: attr(data-cart);
                -webkit-transform: translate(50%, -50%);
                transform: translate(50%, -50%);
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: $primaryColor;
                color: $white;
                font-size: 8px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }

        .ico {
            background-position: 100% 0%;
        }

        .rolloverTxt {
            height: 100%;
            align-items: center;
            display: flex;
            font-size: 0;
            display: none;

            .monpanier {
                position: relative;

                span {
                    color: $white;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: $secondaryColor;
                    font-size: 8px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    vertical-align: top;
                    margin-left: 8px;
                    padding-top: 1px;
                    padding-left: 1px;
                    font-style: initial;
                }

                &.empty {
                    display: none;
                }
            }

            .my_cart,
            .monpanier {
                height: 100%;
                align-items: center;
                display: flex;
            }

            .my_cart {
                position: relative;

                &:before {
                    top: 70%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    background-position: 0 75%;
                }

                &.bgSprite {
                    color: $secondaryColor;
                }
            }
        }
    }
}

.header_menu_top {
    .submenu_wrapper {
        position: absolute;
        visibility: hidden;
        transform: translateX(0%);
        width: 100%;
        background: rgba(255, 255, 255, 0.9);
        left: 0;
        margin-top: 10px;
        top: 25px;
        z-index: -1;
        padding: 20px;
        opacity: 0;
        transition: all 0.5s ease-in-out;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        height: auto;
        min-height: 18.75rem;

        .close_menu {
            position: absolute;
            top: 20px;
            right: 15px;
            width: 2rem;
            height: 12px;
            cursor: pointer;
            z-index: 10;
            display: none;

            &:before {
                content: '';
                position: absolute;
                right: 0;
                width: 20px;
                height: 2px;
                background-color: $primaryColor;
                transform: rotate(45deg);
                top: 5px;
                transition: all 0.3s ease-in-out;
            }

            &:after {
                content: '';
                position: absolute;
                right: 0;
                width: 20px;
                height: 2px;
                background-color: $primaryColor;
                transform: rotate(-45deg);
                bottom: 5px;
                transition: all 0.3s ease-in-out;
            }
        }

        .menu_categ_wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: auto;
            max-width: 1315px;

            @media only screen and (max-width: 1380px) {
                margin: 0 auto;
                gap: 30px;

                .ss_menu_categ_wrapper {
                    &.submenu {
                        width: 68.82%;

                        .ss_cat {
                            gap: 30px;

                            @media only screen and (max-width: 1100px) {
                                gap: 40px;
                            }

                            &>.ss_cat_item {
                                margin-right: 0;

                                &:last-child {
                                    margin-right: 0;
                                }
                            }

                            .wrap_ul {
                                gap: 30px;

                                .objet {
                                    height: 200px;
                                    column-gap: 30px;

                                    li {
                                        margin-right: 0;

                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .menu_visuel_wrapper {
                        width: 26.46%;
                    }
                }
            }
        }

        #newEvent {
            margin-bottom: 3.375rem;
        }

        .submenu {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            text-transform: uppercase;
            letter-spacing: 0.1rem;
            width: auto;
            text-transform: none;

            &.ss_menu_categ_wrapper {
                &.cache {
                    display: none;
                }

                .ss_cat {
                    display: inline-flex;
                    width: auto;

                    @media screen and (min-width: 1224px) {
                        gap: 30px;
                    }

                    .ss_cat_item {
                        padding-top: 0;
                        width: auto;
                        display: inline-block;

                        &:first-child {
                            padding-top: 0;
                        }
                    }

                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                }

                .ss_cat_name {
                    position: relative;
                    width: fit-content;
                    font-weight: 600;
                    font-size: 14px;
                    letter-spacing: 0;
                    white-space: nowrap;
                    height: auto;

                    span {
                        color: var(--span-color, $primaryColor) !important;
                    }
                }

                .wrap_ul {
                    display: flex;

                    span {
                        color: $primaryColor;
                        font-weight: 700;
                        cursor: unset !important;
                    }

                    .objet {
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: 0;
                        text-transform: initial;
                        display: inline-flex;
                        flex-direction: column;
                        height: 13rem;
                        width: auto;
                        flex-wrap: wrap;

                        @media only screen and (max-width: 1360px) {
                            height: 205px;
                        }

                        li {
                            margin-right: 90px;

                            &:first-child {
                                margin-top: 20px;
                            }

                            &:last-child {
                                margin-bottom: 20px;
                            }

                            margin-top: 20px;

                            @media only screen and (max-width: 1380px) {
                                margin-right: 50px;
                            }

                            a {
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }

            &+.submenu {
                margin-top: 0;
                display: flex;
                flex-direction: column;
            }

            /*Catégorie ventes privées, affichage spécial*/
            &.vp_cat {
                text-align: center;

                .ss_menu_categ_wrapper .ss_cat {
                    display: flex;
                    justify-content: space-between;

                    .insideCat {
                        width: 100%;

                        .ss_cat_name {
                            span {
                                width: 100%;
                                font-weight: 700 !important;
                            }
                        }

                        .objet li {
                            display: flex;
                            justify-content: center;
                            width: fit-content;
                        }
                    }
                }
            }

            .cat_name {
                cursor: pointer;
                font-size: 13px;
                color: $secondaryColor !important;
                letter-spacing: 0;
                text-transform: initial;
                font-weight: 600;
                display: block;
                margin-top: 0;
                margin-bottom: 25px;

                &.actif {
                    color: $secondaryColor !important;
                }
            }
        }

        #categ_7 .cat_name {
            color: $red2;
            font-size: 13px;
            text-transform: uppercase;
        }

        .menu_visuel_wrapper {
            .menu_visuel {
                display: flex;
                justify-content: flex-start;

                a.visu_menu {
                    margin-left: 25px;
                    display: flex;
                    flex-direction: column;

                    @media screen and (max-width: 1380px) {
                        margin-left: 7px;
                    }

                    img {
                        &[src^='undefined'] {
                            display: none;
                        }

                        @media screen and (max-width: 1380px) {
                            width: 100%;
                        }
                    }

                    .text_push_menu {
                        font-size: 14px;
                        text-transform: uppercase;
                        text-align: center;
                        margin-top: 1rem;
                    }
                }
            }
        }

        .menuSocial {
            display: flex;
            position: absolute;
            top: 76%;
            width: 40%;
            left: 50%;
            transform: translateX(-50%);
            justify-content: flex-start;

            a {
                display: flex;
                justify-content: space-between;
                width: 48%;

                &+a {
                    margin-left: 2rem;
                }
            }

            p {
                display: none;
            }

            .bgSprite {
                position: relative;
            }

            .bgSprite:before {
                position: absolute;
                top: 50%;
                left: -28px;
                width: 30px;
                height: 30px;
                transform: translateY(-50%);
            }

            .fbIcon:before {
                left: 100%;
            }
        }

        @media screen and (max-height: 675px) {
            #newEvent {
                margin-bottom: 2rem;
            }

            .submenu {
                .ss_menu_categ_wrapper {
                    top: 0;
                    text-transform: none;
                }
            }
        }
    }

    #submenu_maison {

        /* Menu "La marque" exception, column reverse */
        .menu_visuel_wrapper {
            display: flex;
            flex-direction: row;
        }

        .menu_visuel_wrapper .menu_visuel a.visu_menu {
            flex-direction: column-reverse;
        }

        .submenu {
            position: relative;
            margin-right: 32px;

            &:nth-child(1) {
                max-width: 160px;
            }

            &:nth-child(4) {
                margin-right: 0;
            }

            .cat_name {
                height: auto;
                font-size: 14px;

                span.cat_name {
                    margin-bottom: 0;
                }
            }

            .ss_menu_categ_wrapper {
                li {
                    font-size: 12px;
                    max-width: 145px;

                    .ss_cat_name {
                        letter-spacing: 0;
                    }
                }
            }
        }
    }
}

.en {
    .header_menu_top #submenu_maison {
        .submenu {
            &:nth-child(1) {
                margin-right: 52px;
            }
        }
    }
}

.promo_on {
    .submenu_wrapper {
        top: 25px;
    }

    .search .wrap_search_field {
        top: 120px;
    }

    #site_head_wrap {
        #show_top_cart {
            top: calc(74px + 40px);

            @include extraLargeScreen {
                top: calc(74px + 35px);
            }
        }
    }

    #site_head_wrap.sticky {
        .submenu_wrapper {
            top: 25px;
        }
    }
}

#show_top_cart {
    position: fixed;
    display: block;
    width: 420px;
    background: $white;
    right: 0;
    margin-top: 10px;
    top: 114px;
    z-index: 8;
    transform: translateX(105%);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    height: auto;

    @include extraLargeScreen {
        width: 700px;
    }

    &.appear {
        transform: translateX(0%);
    }

    &.cart_box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 1.5rem 1.8rem;
        cursor: default;

        .title {
            font-size: 1.2rem;
            color: $primaryColor;
            text-transform: uppercase;
            letter-spacing: 0.15rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            span {
                font-weight: normal;
                text-transform: initial;
                font-size: 0.9rem;
                letter-spacing: 0;
            }

            .highlight {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: $primaryColor;
                color: $white;
                font-size: 8px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                vertical-align: top;
                margin-left: 8px;
                padding-top: 1px;
            }
        }

        .customize_products .title {
            text-transform: initial;
            font-size: .8rem;
            font-weight: 500;
            letter-spacing: initial;
            margin-top: 0.2rem;
        }

        @media screen and (max-height: 578px) {
            .title {
                margin-bottom: 0.5rem;
            }
        }

        #cart_list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            max-height: 21rem;
            overflow: hidden;
            padding-top: 10px;
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            cursor: default;

            .product-link {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                margin-bottom: 1.25rem;
                position: relative;

                .cart_link {
                    cursor: pointer;

                    .cart_thumb_holder {
                        height: 135px;

                        img {
                            height: 100%;
                            width: auto;
                        }
                    }
                }

                .cart_detail_box {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-evenly;
                    height: 8.125rem;
                    margin-left: 1.875rem;

                    .cart_product {
                        width: 200px;

                        .wrap_top_info {
                            display: flex;
                            justify-content: space-between;
                            flex-direction: column;

                            .sub_title {
                                font-weight: 500;
                                font-size: 0.8rem;
                                color: $primaryColor;
                                margin-bottom: 0.4375rem;
                                text-transform: initial;
                                letter-spacing: 0.9px;
                            }

                            .wrapper_price {
                                display: flex;
                                justify-content: flex-start;
                                margin-bottom: 0.4375rem;

                                .pricetag {
                                    font-size: 0.7rem;
                                    text-transform: uppercase;
                                    font-family: $primaryFont;
                                    font-weight: 500;
                                    letter-spacing: 0.05rem;

                                    &:first-child {
                                        margin-right: 0.5rem;
                                    }
                                }
                            }
                        }
                    }

                    .sub_couleur,
                    .sub_taille,
                    .sub_quantity {
                        font-weight: 500;
                        font-size: 0.7rem;
                        letter-spacing: 0.05rem;
                        color: $grey;
                        text-transform: initial;

                        .typeOf {
                            font-size: 0.7rem;
                            color: $primaryColor;
                            font-weight: 500;
                        }
                    }

                    .remove_prod {
                        font-weight: 300;
                        font-size: 11px;
                        text-decoration: underline;
                        letter-spacing: 0.05rem;
                        color: $grey;
                        position: relative;
                        cursor: pointer;
                        text-transform: initial;
                    }
                }
            }
        }

        .cart_bot_wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;

            .cart_product_total {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 1.5rem;
                padding-top: 1.2rem;
                border-top: solid 1px $lightgrey3;

                label {
                    font-family: $primaryFont;
                    font-weight: 500;
                    font-size: 0.8rem;
                    color: $primaryColor;
                }

                .pricetag {
                    font-family: $primaryFont;
                    color: $primaryColor;
                    font-weight: 500;
                    font-size: 0.8rem;
                    letter-spacing: 0px;
                }
            }

            @media screen and (max-height: 578px) {
                .cart_product_total {
                    margin-bottom: 1rem;
                    padding-top: 1rem;
                }
            }

            .cart_info_delivery {
                margin: 0 auto 1rem;

                p {
                    font-size: 0.75rem;
                    color: $primaryColor;
                    text-align: center;
                    font-weight: 500;
                    letter-spacing: 0.03rem;

                    strong {
                        color: $secondaryColor;
                        text-transform: uppercase;
                        font-weight: 700;
                        letter-spacing: 0.5px;
                    }
                }
            }

            .form_submit.cart_btn2 {
                margin: 0 auto;
                max-width: unset;

                .button {
                    @extend .btnAddBasketWrapper;
                    height: 2.8125rem;
                    width: 245px;
                    justify-content: center;
                }

                .loader {
                    font-size: 0;
                }

            }

            .alma_upselling_panier {
                display: flex;
                align-items: center;
                color: $primaryColor;
                margin-top: 16px;

                &::before {
                    content: "";
                    display: inline-block;
                    width: calc(50px * 0.75);
                    height: calc(25px * 0.75);
                    background-image: url(../img/alma.png);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-right: 8px;
                }
            }
        }
    }

    @media screen and (max-height: 578px) {
        &.cart_box {
            padding: 0.5rem 1.8rem 1.5rem;
        }
    }

    &.noprod {
        flex-direction: row;
        justify-content: center;

        .no_product {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 10px;
            line-height: 10px;
            letter-spacing: 1.5px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .cart_bottom_info {
        padding: 1.2rem 0;
        background-color: $tertiaryColor;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;

        p {
            font-size: 0.75rem;
            text-transform: uppercase;
            text-align: center;
            font-weight: 600;
            color: $primaryColor;
            letter-spacing: 0.1rem;

            span {
                color: $secondaryColor;
                font-weight: 700;
            }
        }
    }
}

/* MON COMPTE */
.my_account {
    position: relative;

    a {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        span {
            display: flex;
            position: relative;

            &:before {
                top: 70%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
            }

            &.bgSprite {
                color: $secondaryColor;
            }
        }
    }

    #topLogin {
        position: fixed;
        display: block;
        width: 410px;
        background: $white;
        right: 0;
        top: 84px;
        min-height: 507px;
        z-index: 91;
        transform: translateX(105%);
        transition: all 0.3s ease-in-out;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

        @include extraLargeScreen {
            width: 700px;
        }

        &.appear {
            transform: translateX(0);
        }

        .wrapper_menu_compte_container {
            padding: 40px;

            .title {
                font-size: 1rem;
                color: $primaryColor;
                text-transform: uppercase;
                letter-spacing: 0.15rem;
                font-weight: bold;
            }

            &:not(:has(.subTitle)) {
                .title {
                    margin-bottom: 1rem;
                }
            }

            .subTitle {
                font-size: 12px;
                color: $tertiaryColor;
                margin: 0.8rem 0;
                font-weight: 500;
            }

            .wrapper_menu_compte {
                padding: 25px 0;
                border-top: 1px solid rgba(0, 0, 0, 0.1);

                .welcome_client,
                .div_loyalty_points {
                    display: none;
                }

                .item {
                    a {
                        letter-spacing: 0.05rem;
                        font-size: 0.85rem;
                        color: $primaryColor;
                        position: relative;
                        display: block;
                        margin: 0;
                        text-transform: initial;
                    }

                    &+.item {
                        margin-top: 1.4rem;
                    }

                    &.actif a {
                        color: $secondaryColor;
                    }

                    &.fid {
                        .loyalty_points {
                            padding: 3px 6px;
                            border-radius: 10px;
                            background-color: $secondaryColor;
                            color: $white;
                            letter-spacing: 0.05rem;
                            font-size: 8px;
                            margin-left: 8px;
                            vertical-align: top;
                            padding-top: 1px;
                        }
                    }

                    &.messages {
                        .highlight {
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            background-color: $primaryColor;
                            color: $white;
                            font-size: 8px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            vertical-align: top;
                            margin-left: 8px;
                            padding-top: 1px;
                        }
                    }

                    &.wishlist {
                        #number_wishlist_top {
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            background-color: $primaryColor;
                            color: $white;
                            font-size: 8px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            vertical-align: top;
                            margin-left: 8px;
                            padding-top: 1px;
                        }
                    }
                }
            }

            .btn_container {
                position: relative;
                max-width: 350px;
                cursor: pointer;

                #btn-disconnect {
                    width: 100%;
                    cursor: pointer;
                    height: 3.2rem;
                }

                .loader {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: -1;
                    cursor: pointer;
                }

                &.loading {
                    .loader {
                        z-index: 1;
                    }
                }
            }
        }
    }
}

.wrapper_menu_compte {
    .messages {
        .highlight {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: $primaryColor;
            color: $white;
            font-size: 8px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            vertical-align: top;
            margin-left: 8px;
            padding-top: 1px;
        }
    }
}

.search_elm_wrap {
    flex-direction: column;

    .rowTitle {
        width: 100%;
        display: flex;
        justify-content: center;

        .title_search {
            color: $primaryColor;
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }

    .rowSearch {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

/* RESPONSIVE */
@media screen and (max-width: 1620px) {
    .headerTop {
        padding: 0 1%;
        box-sizing: border-box;
    }
}

/* TABLETTES */
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 1) {

    .headerTop .mainMenu .main_menu_itm,
    .headerTop .block_top_item.my_account a,
    .search #form_recherche,
    .switchLangBtn,
    #cart_top .cart {
        margin: 0 0.75rem;
    }

    .headerTop {
        .block_logo {
            max-width: unset;
            width: auto;
        }

        .logoBox {
            width: 9rem;
            top: 7%;
        }

        .ge-switcher-flag,
        .switchLang, 
        .switchLang a {
            color: $white;
        }

        .main_menu_contents_tablette {
            display: flex !important;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            z-index: 6;
            padding-left: 1rem;

            /* Menu responsive */
            .wrap_trigger_menu {
                margin: auto 48px auto 0;
                cursor: pointer;

                #trigger_menu_responsive {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    width: 32px;
                    height: 29px;
                    transform: rotate(0deg);
                    transition: 0.5s ease-in-out;

                    span {
                        display: flex;
                        background-color: $black;
                        width: 100%;
                        height: 2px;
                        position: absolute;
                        left: 0;
                        transform: rotate(0deg);
                        opacity: 1;
                        transition: 0.25s ease-in-out;

                        &:nth-child(1) {
                            top: 0;
                        }

                        &:nth-child(2) {
                            top: 9px;
                        }

                        &:nth-child(3) {
                            top: 18px;
                        }
                    }

                    &.open {
                        span {
                            &:nth-child(1) {
                                top: 9px;
                                transform: rotate(135deg);
                            }

                            &:nth-child(2) {
                                opacity: 0;
                                left: -60px;
                            }

                            &:nth-child(3) {
                                top: 9px;
                                transform: rotate(-135deg);
                            }
                        }
                    }
                }
            }
        }
    }

    #site_head_wrap.sticky .headerTop .logoBox {
        mask-image: url(../svg/logo-kujten-simple.svg);
        width: 6rem;
        top: 15%;
        mask-position: 23% 20%;
    }
}

.mainMenu {
    .main_menu_itm {
        span {
            a {
                font-size: 11px;

                @media screen and (max-width: 1480px) {
                    font-size: 9px;
                }
            }
        }

        .ss_cat {
            #menu_1_kujten-x-harpo {
                order: -1;
            }
        }
    }
}

.homepage {

    .headerTop,
    .header_menu_top {

        .ico {
            width: 22px;
            height: 22px;
            display: block;
            background-image: none;
            mask-repeat: no-repeat;
            mask-size: contain;
            mask-image: url(../svg/spritesheet_new.svg);
            mask-repeat: no-repeat;
            mask-size: 500% 500%;
            background-color: $white;
        }

        a {
            height: 100%;
            display: flex;
            align-items: center;
            color: var(--categ-color, $primaryColor);
            text-decoration: none;
        }

        
        .ge-switcher-flag {
            color: $white;
        }

        .switchLang,
        .switchLang a {
            color: $white;
        }

        .block_top_item,
        .main_menu_itm {
            height: 100%;
            display: flex;
            align-items: center;

            &.my_account {
                a {
                    margin: 0 1rem 0 0;

                    .ico {
                        mask-position: 0% 50%;
                        -webkit-mask-position: 0% 50%;
                    }

                    .rolloverTxt {
                        font-size: 0;
                        display: none;
                    }
                }
            }

            &.storelocator {
                height: 22px;
                margin-right: 1rem;

                .ico {
                    mask-position: 50% 0%;
                    -webkit-mask-position: 50% 0%;
                }

                .txt {
                    font-size: 0;
                    display: none;
                }
            }

            &:hover {
                a:not(.cat_name) {
                    color: var(--categ-hover-color, $primaryColor) !important;
                    font-weight: 500;
                }
            }

        }

        .logoBox {
            position: relative;
            height: 100%;
            width: 100%;
            mask-image: url(../svg/kujten_logo_white.svg);
            mask-repeat: no-repeat;
            mask-size: contain;
            mask-position: center center;
            background-color: var(--logo-color-home, #000);
            top: 0;

            &:hover {
                background-color: var(--logo-color-home-scroll, #000);
            }
        }
    }

    .header_menu_top {
        height: 35px;
        padding: 0 20px;
    }

    .search #form_recherche {

        .ico {
            mask-position: 75% 0%;
            -webkit-mask-position: 75% 0%;
        }
    }

    #site_head_wrap {
        background: transparent;
        border-bottom: none;

        .mainMenu .main_menu_itm span a:not(.main_categ_maison) {
            color: var(--categ-sticky-color, $primaryColor);
        }

        #menu_maison .main_categ_maison {
            color: $white;
        }

        &.sticky {
            background-color: $white;

            #show_top_cart {
                top: calc(74px + 40px);

                @include extraLargeScreen {
                    top: calc(74px + 35px);
                }
            }

            .headerTop {
                .logoBox {
                    mask-image: url(../svg/logo-kujten-simple.svg);
                    mask-repeat: no-repeat;
                    mask-size: 80% 50%;
                    display: block;
                    top: 10%;
                    background-color: var(--logo-color-home-scroll, #000);
                }
            }

            .submenu_wrapper {
                top: 25px;
            }

            .ge-switcher-flag,
            .switchLang {
                color: $black;
            }

            .ico {
                background-color: $black;
            }

            .mainMenu .main_menu_itm span a {
                color: var(--categ-color, $primaryColor);
            }

            #menu_maison .main_categ_maison {
                color: $black;
            }

            .promo_bar {
                display: none;
            }

            &:hover {
                .headerTop .logoBox {
                    mask-image: url(../svg/logo-kujten-simple.svg);
                }
            }
        }

        &:hover {
            background-color: $white;

            .headerTop .logoBox {
                mask-image: url(../svg/n_logo.svg);
                background-color: var(--logo-color-home-scroll, #000);
            }

            .ge-switcher-flag,
            .switchLang,
            .switchLang a {
                color: $black !important;
            }

            .ico {
                background-color: $black;
            }

            .mainMenu .main_menu_itm span a:not(.maison) {
                font-size: 11px;

                @media screen and (max-width: 1480px) {
                    font-size: 9px;
                }
            }

            .header_menu_top .mainMenu a:not(.cat_name) {
                color: var(--categ-color, $primaryColor) !important;

                &:hover {
                    color: var(--categ-hover-color, $primaryColor) !important;
                }
            }
        }
    }

    #cart_top {
        .cart {
            .ico {
                mask-position: 100% 0%;
            }
        }
    }
}
