/*************************/
/*     WSHOP FRONT      */
/*************************/

/* EXEMPLE
@include wshopFont($primaryFont, 16px, 24px, $letterSpacing, $bold, $black) */

@mixin wshopFont($family, $size, $lineHeight, $letterSpacing, $weight, $color){
	font-family: $family;
	font-size: $size;
	@if $lineHeight == false{
		$lineHeight: ($size*1.3);
	}
	line-height: $lineHeight;
	letter-spacing: $letterSpacing;
	font-weight: $weight;
	color: $color;
}