@import '../../_app/_src/scss/desktop/mixins/typeFace';

$montserrat: (
    normal: (regular,medium,bold)
);

$calson: (
    normal: (regular,medium,bold)
);

$gotham: (
    normal: (regular,medium,bold)
);

$freescript: (
    normal: (regular)
);

$alamano: (
    normal: (regular,bold)
);

$adelon: (
    normal: (regular)
);

$kunstler: (
    normal: (regular)
);

@include typeFace('caslon', $calson);
@include typeFace('gotham', $gotham);
@include typeFace('freescript', $freescript);
@include typeFace('montserrat', $montserrat);
@include typeFace('alamano', $alamano);
@include typeFace('adelon', $adelon);
@include typeFace('kunstler', $kunstler);


// *********************************************************
//  EXEMPLE :
//  font-family: 'Metropolis', sans-serif;
//  font-weight: 400;
// *********************************************************

$primaryFont: 'montserrat', sans-serif;
$secondaryFont: 'caslon', sans-serif;
$thirdFont: 'freescript', sans-serif;
$fourthFont: 'gotham', sans-serif;
$primaryFontCustom: 'alamano', sans-serif;
$secondaryFontCustom: 'adelon', sans-serif;
$thirdFontCustom: 'kunstler', sans-serif;
$fontGiftCard: Adobe Caslon Pro;
$fontPlaceholder: Arial;