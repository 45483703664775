html[lang="en"] {
    .checkout {
        .wrapper_panier {
            .bank_left_side {
                .wrapper_bloc_banque {
                    &.paypal {
                        .bank_title {
                            span::after {
                                left: 19%;
                                top: 14%;
                            }
                        }
                    }

                    &.alma {
                        .bank_title {
                            span::after {
                                top: 58%;
                                width: 100%;
                                height: 58%;
                                right: -76%;
                                position: absolute;
                            }
                        }

                        span {
                            line-height: 1.6;
                            bottom: 0.2rem;
                        }
                    }
                }
            }
        }
    }
}

/* Sticky bloc de droite (devrait être simplement ".sticky") */
.tunnel_sticky {
    position: relative;
}

.cart {
    .go_back {
        font-size: 0.5rem;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        font-weight: 700;
        font-family: $primaryFont;
        position: absolute;
        left: 0;
        top: 52%;
        transform: translateY(-50%);
        cursor: pointer;

        &:before {
            transform: rotate(90deg);
        }
    }

    #breadcrumbs {
        display: none;
    }

}

#alma-widget {
    .alma-payment-plans-container {
        width: 270px;
    }

    .alma-payment-plans-eligibility-line {
        &::before {
            content: "";
            display: inline-block;
            width: 50px;
            height: 25px;
            background-image: url(../img/alma.png);
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 16px;
        }

        svg {
            display: none;
        }
    }
}

.cart #alma-widget .alma-payment-plans-container {
    width: 100%;
}

.alma-eligibility-modal-left-side .alma-eligibility-modal-title,
.alma-eligibility-modal-right-side .alma-eligibility-modal-eligibility-options button,
.alma-eligibility-modal-schedule-total {
    font-family: $primaryFont;
}

.in_tunnel {
    .go_back {
        display: none;
    }

    &:not(.step_1) {
        #site_head_wrap {
            display: none;
        }

        .disconnect_logo {
            display: flex;
            justify-content: center;
        }
    }

    .accountTitleHolder {
        display: none;
    }

    .w-form-line.w-submit {
        margin-top: 3rem;
    }
}

.cart.step_1 {
    #bandeauMain {
        display: block;

        .accountTitleWrapper {
            padding-top: 3rem;
        }
    }

    .cart_product_subtitle_giftcard {
        display: none;
    }

    .cart_product_title_giftcard {
        text-transform: uppercase;
    }

}

/* Etapier */
.tunnel_track_pusher {
    padding: 35px 0 25px;
    display: flex;
    align-items: center;
    max-width: 1620px;
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
    position: relative;

    .tunnel_track_wrapper {
        width: 60%;
        max-width: 620px;
        margin: auto;

        .tunnel_track {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .tunnel_step {
                display: flex;
                color: $grey;
                position: relative;
                font-family: $primaryFont;
                font-size: 0.8rem;
                letter-spacing: 0.1rem;
                text-transform: uppercase;
                font-weight: 700;

                span {
                    line-height: 35px;
                    cursor: pointer;
                }

                &.on {
                    color: $secondaryColor;

                    &:before {
                        position: absolute;
                        top: 70%;
                    }

                    span {
                        color: $secondaryColor;
                    }
                }

                &.passed {
                    text-decoration: none;
                }
            }
        }
    }
}

.thanksWrapper {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;

    .bankThanksTop {
        text-align: center;
        font-family: $primaryFont;
        margin: 2rem 0 1.5rem;

        h1 {
            justify-content: center;
            flex-wrap: wrap;

            span {
                display: block;
                font-family: $secondaryFont;
                font-size: 1rem;
                width: 100%;
                margin-top: 1rem;
            }
        }
    }

    .seeOrderEvo {
        font-family: $secondaryFont;
    }

    .thanksTrust {
        font-family: $thirdFont;
        font-size: 1.6rem;

        strong {
            font-weight: 400;
        }
    }

    .bankThanksBtn {
        display: flex;
        height: 2.8125rem;
        justify-content: center;
        align-items: center;
        background-color: $primaryColor;
        color: $white;
    }
}

.thanksWrapper .thanksRecap {
    .thanksLeftWrapper {
        width: 90%;
    }
}

.thanksWrapper .thanksRecap .thanksRight {
    width: 40%;
    text-align: center;
    background-color: $quaternaryColor;

    .thanksRightWrapper {
        padding: 1rem 0.5rem;

        p {
            label {
                font-size: 0.9rem;
            }

            span {
                font-family: $primaryFont;
                font-weight: 700;
                font-size: 0.8rem;
            }

            &.txt_thanks_transaction {
                font-size: 0.8rem;
                text-transform: initial;
            }

            &.main_ref_transaction>label:after {
                content: " :";
            }
        }
    }
}

.dropdown_cart_link {
    text-decoration: none;
}

.deconnection_tunnel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;

    #btn-disconnect.button {
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 0;
        border: none;

        &:hover {
            background: transparent;
            color: #242424;
        }

        span {
            font-family: $primaryFont;
            font-size: .8rem;
            letter-spacing: .08rem;
            text-transform: uppercase;
            text-decoration: underline;
            font-weight: 600;
            margin: 1rem 0;
        }
    }
}

#cart_total {
    .wrapper_payment {
        .payment_addr_wrapper {
            .title {
                font-family: $primaryFont;
            }

            .address {
                font-family: $secondaryFont;
            }

            .address_mod {
                font-size: 0.75rem;
                font-family: $primaryFont;
            }

            &.payment_addr_wrapper_giftcard_only {
                display: none;
            }
        }
    }
}

.cart,
.customer,
.checkout {
    .wrapper_panier {
        padding: 0 2rem;

        .bloc_pack_illimitee {
            display: none;
        }
    }

    .choix_type_adresse {
        @include flexbox(row, flex-start, center);
        margin: 0 0 1rem;

        .type_adresse {
            cursor: pointer;

            &:not(:first-child) {
                margin-left: 1rem;
            }

            &.actif p {
                font-weight: 700;
                color: $secondaryColor;
            }
        }

        .type_adresse {
            @include flexbox(row, unset, center);
            text-transform: uppercase;
            cursor: pointer;

            &::before {
                content: none;
                display: block;
                width: 14px;
                height: 14px;
                background-image: url(../svg/spritesheet_2.svg);
                margin-right: 0.5rem;
            }

            &.maison::before {
                @include bgIconCoord(1, 5, 5);
            }

            &.bureau::before {
                @include bgIconCoord(2, 5, 5);
            }

            &.autre::before {
                @include bgIconCoord(3, 5, 5);
            }

            &.actif {
                color: $secondaryColor;
                font-weight: bold;

                &.maison::before {
                    @include bgIconCoord(6, 5, 5);
                }

                &.bureau::before {
                    @include bgIconCoord(7, 5, 5);
                }

                &.autre::before {
                    @include bgIconCoord(8, 5, 5);
                }
            }

            &+.type_adresse {
                margin-left: 36px;
            }
        }
    }

    a {
        text-decoration: none;
    }

    .wrapper_panier {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        max-width: 1230px;
        margin: 40px auto 40px;
        font-family: $primaryFont;

        .line_ssTotal {
            display: none;
        }

        .cart_product_line {
            display: flex;
            margin: 1.8rem 0;
            position: relative;

            &:first-child {
                margin-top: 1rem;
            }

            .cart_product_pic {
                width: 190px;

                .cart_product_img {
                    width: 100%;
                    height: auto;
                    vertical-align: bottom;
                }

                &.card {
                    width: 190px;

                    img {
                        width: 100%;
                        height: auto;
                        vertical-align: bottom;
                    }
                }
            }

            .cart_product_desc {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-left: 2.5rem;
                margin-right: 1rem;
                position: relative;
                flex-grow: 1;

                .wrapper_cart_product_desc {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-wrap: wrap;

                    .toggle_modal_actions {
                        border: solid $primaryColor;
                        border-width: 0 1px 1px 0;
                        display: inline-block;
                        width: 5px;
                        height: 5px;
                        transform: rotate(45deg);
                        margin-top: 2px;
                        cursor: pointer;
                    }

                    .cart_item_actions {
                        width: 100%;
                        margin-bottom: .5rem;

                        .cart_item_actions_content {
                            padding: 20px 0;
                            position: relative;
                            background-color: $lightgrey2;

                            &:hover {
                                .cart_item_actions_close.close {
                                    opacity: 1;
                                }
                            }

                            .cart_item_actions_close.close {
                                position: absolute;
                                z-index: 10;
                                top: 20px;
                                right: 16px;
                                width: 16px;
                                height: 16px;
                                cursor: pointer;
                                opacity: 0;
                                transition: opacity .35s ease-in-out;
                            }

                            .cart_item_actions_form {
                                width: 100%;

                                .cart_item_actions_bloc.productSizeFieldset {
                                    .choices_list.size_list {
                                        width: 100%;
                                        display: flex;
                                        justify-content: center;
                                        flex-wrap: wrap;

                                        .form_itm.size {
                                            margin-right: 15px;
                                            margin-bottom: 0;
                                            position: relative;
                                            cursor: pointer;

                                            &:last-child {
                                                margin-right: 0;
                                            }

                                            &:hover {
                                                text-decoration: underline;
                                                text-underline-position: under;
                                                font-weight: 600;
                                            }

                                            input.input_check.radio {
                                                position: absolute;
                                                width: 100%;
                                                left: 0;
                                                right: 0;
                                                z-index: 1;
                                                opacity: 0;
                                                cursor: pointer;
                                            }

                                            [type=radio]:checked+label {
                                                font-weight: 600;
                                                text-decoration: underline;
                                                text-underline-position: under;
                                            }
                                        }

                                        .form_itm.size input.input_check.radio.disabled ~ label span {
                                            color: $grey;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .configurator {
                        font-size: 0.7rem;
                        margin-bottom: 0.5rem;
                    }

                    .wrap_titre_prix {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        flex-direction: column;
                        width: 100%;

                        .cart_product_brand {
                            display: none;
                        }

                        .cart_product_title {
                            font-size: 12px;
                            font-weight: 600;
                            color: $primaryColor;
                            font-family: $primaryFont;
                            text-transform: uppercase;
                        }

                        .cart_product_subtitle {
                            font-size: 12px;
                            font-weight: 500;
                            color: $primaryColor;
                        }

                        .wrapper_cart_product_price {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            margin: 0.5rem 0 0.7rem;

                            .pricetag {
                                font-weight: 400 !important;
                                font-size: 0.8rem;
                                font-family: $primaryFont;
                                color: $primaryColor;

                                &+.pricetag {
                                    margin-left: 1rem;
                                    font-size: 0.7rem !important;
                                    color: $tertiaryColor;
                                }
                            }
                        }
                    }

                    .product_nostock_label {
                        font-size: 0.7rem;
                        color: $red;
                    }
                }

                .cart_product_sizecol {
                    margin-bottom: 0.5rem;
                    font-size: 0.7rem !important;
                    width: 100%;

                    label {
                        text-transform: uppercase;
                        font-weight: 700;
                        letter-spacing: 0.1rem;
                    }

                    &.size {
                        width: 23%;
                    }

                    &.quantity {
                        display: block;
                    }
                }

                .cart_product_modify {
                    width: 100%;
                    margin-top: 0.5rem;
                    position: relative;
                    @include flexbox(row, space-between, unset);

                    .product_quantity_change {
                        position: absolute;
                        top: -2.1rem;
                        left: 85px;

                        .qteWrapper {
                            @include flexbox(row, space-between, center);

                            button {
                                cursor: pointer;
                                width: 1.5rem;
                                height: 1.5rem;
                                @include wshopFont($primaryFont, 1.2rem, unset, unset, 200, $primaryColor);
                                background: none;
                                border: 1px solid $borderColor;
                            }

                            .selected_qte {
                                width: 2rem;
                                @include wshopFont($primaryFont, 0.75rem, unset, unset, 200, $primaryColor);
                                text-align: center;
                            }
                        }
                    }

                    .product_del,
                    .remove_wish {
                        span {
                            display: block;
                        }
                    }

                    .product_del {
                        display: flex;
                        order: 2;
                    }

                    .product_towish {
                        display: flex;
                        order: 3;
                    }

                    a {
                        @include wshopFont($primaryFont, 0.75rem, unset, unset, 200, $grey);
                        text-decoration: underline;
                        border: none;
                        background-color: none;
                        width: unset;
                        text-transform: none;

                        &:hover {
                            background: none;
                        }
                    }
                }

                .line_product_desc_preorder {
                    display: none !important;
                }
            }
        }

        .left_side,
        .bank_left_side {
            width: 53%;
            font-size: 0.95rem;
            display: flex;
            flex-direction: column;

            .wrapper_bloc_banque.gift_card.payment_gift_card {
                order: 2;
            }

            .wrapper_bloc_banque.banque.hosted_hipay.credit_card.flexco {
                order: 1;
            }

            #paiement_paypal {
                order: 3;
            }

            .wrapper_bloc_banque.alma_inpage2.alma,
            .wrapper_bloc_banque.alma_inpage3.alma {
                order: 4;
            }

            .cart_main_title.wishlist {
                border-top: 1px solid $grey6;
                padding-top: 1.5rem;
            }

            .cart_main_title,
            .title_basket_tunnel {
                font-size: 1rem;
                font-family: $primaryFont;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 2px;
                margin-bottom: 0.3rem;
            }

            .empty_cart {
                margin: 1.8rem 0;
            }

            span.nb_products {
                color: $grey7;
                text-transform: lowercase;
                font-family: $secondaryFont;
                letter-spacing: 0;
                font-weight: 400;
                font-size: 1rem;
            }

            .precoBasket {
                display: none;
            }

            .dropdown_cart {
                font-size: 0.9rem;

                h3 {
                    display: none;
                }

                .precoSubtitle {
                    font-size: 16px;
                    margin-bottom: 5px;
                }

                .precoDeliveryDate {
                    color: $primaryColor;
                    line-height: 1.6;
                    font-family: $secondaryFont;
                    font-size: 15px;
                }
            }

            .itm_back_btn {
                display: inline-block;
                padding-left: 17px;
                color: $balticSea;
                font-family: $secondaryFont;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                font-size: 9px;
                font-weight: bold;
                position: relative;
                height: 37px;

                &:before {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 0;
                    height: 7px;
                    width: 10px;
                    background: url(../img/arrow_r.png) center center no-repeat;
                    background-size: contain;
                    transform: rotate(180deg);
                }
            }

            .bloc_livraison {

                .title_type_livraison,
                .transporteur_info {
                    pointer-events: none;
                }
            }

            .bloc_livraison,
            .wrapper_bloc_banque,
            .bloc_paiement {
                width: 100%;
                border: 1px solid $greyblue;
                position: relative;
                color: $primaryColor;
                margin-bottom: 3.7rem;
                padding: 0;
                transition: all 0.2s ease-out;

                .checkbox,
                .radio {
                    cursor: pointer;
                    border: 1px solid $grey;
                    border-radius: 50%;
                    background-color: $white;
                    position: absolute;
                    left: -7px;
                    top: 2.5rem;
                    width: 14px;
                    height: 14px;
                    transform: translate(0, -50%);
                    transition: all 0.2s ease-out;

                    &:after {
                        content: '';
                        display: block;
                        border-radius: 50%;
                        width: 10px;
                        height: 10px;
                        margin: 1px;
                        background-color: transparent;
                        transition: all 0.2s ease-out;
                    }
                }

                &.active,
                &.actif,
                &:hover {
                    border-color: $black;

                    .checkbox,
                    .radio {
                        border-color: $black;

                        &:after {
                            background-color: $secondaryColor;
                        }
                    }
                }

                .adresse_change,
                .wrapper_locate_relais {
                    padding: 0 2rem;
                }

                .wrapper_select_adr .w-dropdown .w-input-element {
                    cursor: pointer;
                }

                .wrapper_locate_relais {
                    .w-input-container {
                        &:after {
                            content: '';
                            background-image: url(../svg/vector_spritesheet.svg);
                            @include bgIconCoord(18, 5, 5);
                            width: 1.8rem;
                            height: 1.8rem;
                            position: absolute;
                            left: 92%;
                            bottom: 0;
                            top: 0;
                            margin: auto;
                            border: none;
                        }
                    }

                    .displayed_result {
                        padding: 1.5rem 0;
                        display: flex;
                        justify-content: space-between;
                        font-family: $secondaryFont;

                        .list-right {
                            padding-right: 15px;
                        }

                        .distance {
                            display: none;
                        }

                        .kp {
                            margin-top: 1rem;
                            border-bottom: 1px solid $tertiaryColor;
                        }

                        .kpname {
                            display: block;
                            text-transform: uppercase;
                            font-weight: 600;
                            margin-bottom: 7px;
                            font-size: 0.73rem;
                            letter-spacing: 0.9px;
                        }

                        .address {
                            letter-spacing: 0.03rem;
                            font-size: 0.9rem;
                            text-transform: lowercase;
                            font-family: $primaryFont;

                            .city {
                                text-transform: capitalize;
                            }
                        }

                        .btn_container {
                            margin: 16px 0;
                            height: auto;
                        }

                        .relayLink {
                            color: $greyblue;
                            font-size: 0.85rem;
                            letter-spacing: 0.3px;
                            text-decoration: underline;
                            font-family: $primaryFont;
                        }

                        .map_canvas {
                            width: 62%;
                            height: auto;
                        }

                        .sidebar-right {
                            width: 35%;
                            max-height: 450px;
                        }

                        .lb-v-scrollbar {
                            width: 1px;
                        }

                        .lb-v-scrollbar-slider {
                            width: 1px;
                            background: $grey;
                        }
                    }
                }

                .title_type_livraison,
                .bank_title {
                    letter-spacing: 0.1rem;
                    margin-bottom: 0.5rem;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin: -0.5rem auto 0;
                    text-align: center;
                    background-color: $white;
                    width: 55%;
                    font-size: 0.9rem;
                    font-family: $primaryFont;
                }

                .transporteur_info {
                    text-align: center;
                    font-size: 0.7rem;
                    line-height: 1.8;
                }

                .choix_delivery {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: 3.3rem 1.5rem 1rem 3rem;
                    margin-top: -3.3rem;
                    min-height: 162px;

                    .button.dark {
                        line-height: 3;
                        color: $greyblue;
                        font-size: 0.75rem;
                        letter-spacing: 0.3px;
                        border: none;
                        font-weight: 500;
                        text-transform: initial;

                        &:hover {
                            background-color: transparent;
                        }
                    }

                    .transporteur_delay {
                        font-weight: 600;
                        font-size: 0.9rem;
                        color: $darkblue;
                        margin-top: 0.5rem;
                        font-family: $secondaryFont;
                    }

                    a.show_modif_info {
                        position: relative;
                        cursor: pointer;
                        font-size: 0.85rem;
                        text-decoration: underline;
                    }

                    .left_delivery {
                        width: 60%;
                        position: relative;

                        p {
                            font-size: 0.75rem;
                            line-height: 1.5;
                            color: $primaryColor;

                            &.shop_name {
                                text-transform: uppercase;

                                i {
                                    display: none;
                                }
                            }

                            &:not(.title_type_livraison) {
                                letter-spacing: 0.03rem;
                            }
                        }

                        input[type='radio'] {
                            display: none;
                        }
                    }

                    .right_delivery {
                        .price_tag {
                            font-family: $primaryFont;
                            font-weight: 600;
                            font-size: 1rem;

                            .currency {
                                margin-left: 3px;
                            }
                        }
                    }
                }

                .transporteur_info,
                .civilite,
                .shop_name,
                .delivery_form_title {
                    text-transform: uppercase;
                    font-family: $primaryFont;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                }

                .civilite,
                .shop_name,
                .adresse {
                    font-size: 0.7rem !important;
                }

                .delivery_form_title {
                    text-align: center;
                    font-size: 0.73rem;
                    margin: 0.5rem 0;
                    letter-spacing: 0.9px;
                }
            }

            /* Bank */
            .wrapper_bloc_banque {

                .title_wrapper_fold,
                .cards-wrapper,
                #flex {
                    padding: 0 2rem;
                }

                #flex {
                    min-height: 355px;
                }

                .cards-wrapper {
                    display: flex;
                    align-items: center;
                    margin: 1rem 0;

                    img {
                        vertical-align: top;
                        width: auto;
                        height: 1.5rem;

                        +img {
                            margin-left: 0.3rem;
                        }
                    }
                }

                .title_wrapper_fold {
                    span.mode_title {
                        margin-bottom: 5px;
                        display: inline-block;
                    }

                    .w-form-line {
                        margin-bottom: 1.2rem;
                    }

                    .mode_email, .mode_sms {
                        display: flex;
                        align-items: center;
                        margin-top: 1rem;

                        .info_email.w-form-line {
                            margin-right: 1rem;
                        }
                    }

                    .mode_email {
                        border-bottom: 1px solid #d9d9d9;
                        margin-bottom: 1.5rem;
                    }

                    .mode_sms {
                        .info_sms.w-form-line {
                            margin-right: 1rem;
                        }

                        .intl-tel-input.allow-dropdown {
                            border: 1px solid #d9d9d9;
                            height: 3rem;
                            vertical-align: middle;
                            display: flex;
                            align-items: center;

                            .flag-container {
                                border-right: 1px solid #d9d9d9;
                                margin-top: 5px;
                                margin-bottom: 5px;
                            }

                            input#client_tel {
                                padding-left: 55px;
                                background: transparent;
                                width: 100%;
                            }
                        }
                    }

                    .loader_container {
                        position: relative;
                        margin-top: 4px;

                        .confirmPaiement.w-submit-button  {
                            background-color: $primaryColor;
                            color: $white;
                            transition: background-color 0.2s ease-out;
                            height: 3rem;

                            &:hover {
                                background-color: $black;
                            }
                        }

                        .loader {
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: -1;
                            background: #3c3c3c url(../svg/three_dots.svg) center no-repeat;
                            background-size: auto 25%;
                            width: 245px;
                            height: 100%;

                            span {
                                display: none;
                            }
                        }
                    }

                    .mode_qr_code {
                        text-align: center;
                    }
                }

                .bank_subtitle,
                .total_transaction,
                .alias_list {
                    font-size: 0.8rem;

                    .pricetag {
                        color: $primaryColor;
                    }
                }

                .bank_subtitle {
                    margin-top: 1rem;
                    margin-bottom: 0.5rem;

                    strong {
                        font-weight: normal;
                    }
                }

                &.paypal {
                    border: 1px solid $borderColor;

                    .bank_title span {
                        position: relative;

                        &::after {
                            content: '';
                            background-image: url(../img/paypal-logo.png);
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-color: $white;
                            width: 100%;
                            height: 125%;
                            position: absolute;
                            right: -34%;
                            bottom: 0;
                            top: 0;
                            margin: auto;
                        }
                    }

                    .bloc_bank_contents {
                        display: block;
                        text-align: center;
                    }

                    .bank_subtitle {
                        cursor: pointer;
                        color: $greyblue;
                        font-size: 0.95rem;
                        letter-spacing: 0.3px;
                        text-decoration: underline;
                    }

                    #inputPAYPAL {
                        visibility: hidden;
                        opacity: 0;
                        height: 0;
                    }
                }

                &.alma {
                    border: 1px solid $borderColor;

                    #alma-payment-2,
                    #alma-payment-3 {
                        width: 99.9%;
                    }
            
                    .bank_title span {
                        position: relative;

                        &::after {
                            content: '';
                            background-image: url(../img/alma.png);
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-color: $white;
                            width: 100%;
                            height: 125%;
                            position: absolute;
                            right: -104%;
                            bottom: 0;
                            top: 0;
                            margin: auto;
                        }
                    }

                    .bloc_bank_contents {
                        display: block;
                        text-align: center;
                        margin-bottom: 2rem;
                    }

                    .bank_subtitle {
                        cursor: pointer;
                        color: $greyblue;
                        font-size: 0.95rem;
                        letter-spacing: 0.3px;
                        text-decoration: underline;
                    }

                    #inputPAYPAL {
                        visibility: hidden;
                        opacity: 0;
                        height: 0;
                    }
                }

                &.bloc_paiement {
                    .title_wrapper {
                        .bank_subtitle_unfold {
                            font-family: $fourthFont;
                            font-style: normal;
                            font-weight: 325;
                            font-size: 14px;
                            line-height: 20px;
                            padding: 20px;
                            text-align: left;
                            color: $iridium;
                        }
                    }
                }

                .content_bloc_paiement {
                    .paiement_amount {
                        font-family: $fourthFont;
                        font-style: normal;
                        font-weight: 325;
                        font-size: 14px;
                        line-height: 20px;
                        padding: 0 20px;
                        text-align: left;
                        color: $iridium;
                    }

                    div {
                        padding: 25px;

                        .button {
                            width: 230px;
                            font-family: $fourthFont;
                            font-weight: bold;
                            font-size: 11px;
                            color: $balticSea;

                            &:not(.w-loader):hover {
                                color: $white;
                            }
                        }

                        .valid {
                            background-color: $balticSea;
                            color: $white;

                            &:not(.w-loader):hover {
                                color: $balticSea;
                                background-color: $white;
                            }
                        }
                    }
                }
            }

            .title_wrapper_fold.loader_container {
                position: relative;

                .cancelPayment.w-submit-button {
                    background-color: $primaryColor;
                    color: $white;
                    transition: background-color 0.2s ease-out;
                    height: 3rem;
                    width: 100%;

                    &:hover {
                        background-color: $black;
                    }
                }

                .loader {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    background: #3c3c3c url(../svg/three_dots.svg) center no-repeat;
                    background-size: auto 25%;
                    width: 245px;
                    height: 100%;

                    span {
                        display: none;
                    }
                }
            }

            .payment_gift_card {
                border: 1px solid #efefef;
                position: relative;

                &.actif {
                    border-color: #000;
                }

                .radio {
                    // !important car style inline
                    display: block !important;
                    cursor: pointer;
                    border: 1px solid #8b8b8b;
                    border-radius: 50%;
                    background-color: #fff;
                    position: absolute;
                    left: -7px;
                    top: 1.5rem;
                    width: 14px;
                    height: 14px;
                    transform: translate(0, -50%);
                    transition: all .2s ease-out;
                    z-index: 3;
                }

                .gift_card_title {
                    position: absolute;
                    top: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .w-submit-button {
                    cursor: pointer;
                    color: #9ca4ab;
                    font-size: .95rem;
                    letter-spacing: .3px;
                    text-decoration: underline;
                    border: none;
                    font-weight: 400;
                    text-transform: initial;
                    width: 100%;
                    margin-top: 1rem;

                    &:hover {
                        background-color: #fff;
                        color: #3c3c3c;
                    }
                }

                .giftcard_desc,
                .container__input-code,
                .giftcard_total_amount,
                .giftcard_remain_amount,
                .w-input-error {
                    width: 80%;
                    margin-left: 2rem;
                }

                .w-input-container {
                    .w-input-error {
                        width: 80%;
                        margin-left: 0rem;
                    }
                }

                .giftcard-buttons-container {
                    margin: 1rem 0 2rem 2rem;

                    .w-form-line.w-submit {
                        margin-top: 0;

                        .w-submit-button.cancel {
                            border: 1px solid $primaryColor;
                            background-color: $white;
                            color: #242424;
                            text-transform: uppercase;
                            text-decoration: none;

                            &:hover {
                                border: 1px solid $primaryColor;
                                background-color: $white;
                                color: #242424;
                            }
                        }

                        .w-submit-button {
                            border: 1px solid $primaryColor;
                            background-color: $primaryColor;
                            color: $white;
                            text-transform: uppercase;
                            text-decoration: none;

                            &:hover {
                                border: 1px solid $primaryColor;
                                background-color: $primaryColor;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        .right_side,
        .bank_right_side {
            width: 39%;

            .main_table {
                background: $lightgrey2;
                padding-top: 28px;
                padding-bottom: 1rem;
                margin-bottom: 25px;
                display: flex;
                flex-direction: column;

                .cart_product_line {
                    margin: 1.7rem 0;

                    &:first-child {
                        margin-top: 0;
                    }

                    .cart_product_pic {
                        width: 105px;
                    }

                    .cart_product_desc {
                        margin-left: 1.3rem;

                        .wrap_titre_prix {
                            .cart_product_title {
                                font-size: 0.8rem;
                            }

                            .wrapper_cart_product_price {
                                margin: 0.3rem 0 0.5rem;

                                .pricetag {
                                    font-weight: 600;
                                    font-size: 0.8rem;
                                }
                            }
                        }

                        .cart_product_sizecol {
                            font-size: 0.9rem;
                            margin-bottom: 0.25rem;
                        }

                        .cart_product_modify {
                            margin: 0.4rem 0 0;

                            .product_quantity_change {
                                top: -1.8rem;
                                display: none;
                            }

                            .product_mod {
                                margin-right: 0.5rem;
                            }
                        }
                    }
                }

                .bill_line,
                .order_gift_form {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 70%;
                    margin: auto;
                    font-size: 0.95rem;
                    padding: 5px 0;

                    &:last-of-type {
                        padding: 5px 0 0;
                        margin-bottom: 1.5rem;
                    }

                    &.empty p {
                        width: 100%;
                        text-align: center;
                    }

                    &.total_produit {
                        padding: 0 0 20px;

                        span.nb_products {
                            color: $grey7;
                            display: inline-flex;
                            align-items: center;
                            font-family: $secondaryFont;

                            &:after {
                                content: '';
                                display: inline-block;
                                height: 7px;
                                width: 7px;
                                border-style: solid;
                                border-color: $primaryColor;
                                border-width: 0px 1px 1px 0px;
                                margin-left: 10px;
                                transform: rotate(45deg);
                                transition: all 0.3s ease-in-out;
                            }
                        }

                        &.toggle,
                        &.toggle label {
                            cursor: pointer;
                            font-size: 0.9rem;
                        }

                        &.open {
                            span.nb_products {
                                &:after {
                                    transform: rotate(-135deg);
                                }
                            }
                        }
                    }

                    &.total_produit_dropdown {
                        display: none;
                        width: 83%;
                        margin: auto;
                        padding: 0;
                    }

                    &.discount {
                        padding: 15px 0 5px;
                        border-top: 1px solid $lightgrey;

                        .pricetag {
                            text-transform: uppercase;
                            letter-spacing: 0.05rem;
                        }

                        #bloc_info_offert {
                            position: inherit !important;
                            width: auto !important;

                            img {
                                display: none !important;
                            }
                        }
                    }

                    &.hide_shippingfees {
                        display: none !important;
                    }

                    &.used_cp {
                        font-size: 0.85rem;

                        label {
                            width: 44%;
                        }

                        .code_promo {
                            a {
                                text-decoration: none;

                                span {
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }

                    &.coupon {
                        padding: 10px 0 0;

                        #formPromo {
                            background-color: $white;

                            &.w-coupon-form .w-form-line.w-submit {
                                border-left: 0;

                                .w-submit-button {
                                    outline: none;
                                    margin: 0.45rem;

                                    span {
                                        color: $white;
                                        background-color: $grey2;
                                        padding: 0.5rem;
                                    }
                                }
                            }

                            .w-form-line {
                                .w-input-container {
                                    .w-input-note {
                                        &.w-input-error {
                                            font-size: 0.7rem;
                                            margin-top: 0.25em;
                                            line-height: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .pricetag {
                        font-weight: 500;
                        font-family: $primaryFont;
                    }

                    &.total {
                        font-weight: 600;
                        color: $primaryColor;
                        text-transform: uppercase;

                        .pricetag {
                            font-weight: 700;
                        }
                    }

                    .error_text {
                        display: none;
                    }
                }

                .upselling.price {
                    order: 6;
                    margin-bottom: 2rem;

                    &:before {
                        content: '';
                        display: block;
                        height: 1.5rem;
                        width: 100%;
                        background-color: $white;
                        position: absolute;
                        top: -1.5rem;
                        left: 0;
                    }

                    p {
                        @include wshopFont($secondaryFont, 0.875rem, unset, unset, 400, $primaryColor);
                        text-align: center;
                    }
                }

                .formPanier_container {
                    margin-top: 1.5rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 1rem;
                    cursor: pointer;

                    #formPanier,
                    .form_submit.full {
                        .loader {
                            span {
                                display: none;
                            }
                        }

                        #btn_cmd_valid {
                            background-color: $primaryColor;
                            color: $white;
                            transition: background-color 0.2s ease-out;

                            &:hover {
                                background-color: $black;
                            }
                        }
                    }

                    p.shipment_cgv_link {
                        display: block !important;
                        text-align: center;
                        font-family: $primaryFont;
                        font-size: 0.75rem;
                        margin: 1.5rem 0;
                        padding: 0 4rem;

                        a {
                            text-decoration: underline;
                        }
                    }
                }
            }

            /* adresses rappel */
            .wrapper_payment {
                width: 78%;
                margin: auto;

                .payment_addr_wrapper {
                    margin: 1.7rem 0;
                    padding: 0 6.5%;
                    position: relative;

                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-of-type {
                        margin-bottom: 3.4rem;

                        &:after {
                            content: '';
                            display: block;
                            width: 100%;
                            height: 1px;
                            background: $lightgrey;
                            position: relative;
                            top: 1.7rem;
                        }
                    }

                    &:before {
                        content: '';
                        display: block;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        background: $lightgrey;
                        position: absolute;
                        right: 96%;
                        top: 2px;
                    }

                    .title {
                        text-transform: uppercase;
                        font-family: $secondaryFont;
                        font-weight: 600;
                        margin-bottom: 7px;
                        font-size: 0.7rem;
                        letter-spacing: 0.09rem;
                    }

                    .address {
                        font-size: 0.9rem;
                        line-height: 1.5;
                    }

                    .address_mod {
                        color: $grey3;
                        text-decoration: underline;
                        font-size: 0.95rem;
                        letter-spacing: 0.3px;
                        margin: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
            }

            /* recap commande */
            .recap_cart_title {
                text-align: center;
                text-transform: uppercase;
                font-size: 1rem;
                font-family: $primaryFont;
                color: $primaryColor;
                letter-spacing: 0.15rem;
                font-weight: 600;
                margin-bottom: 25px;

                span {
                    font-size: 0.7rem;
                    display: block;
                    letter-spacing: 0.09rem;
                    margin-top: 2px;
                }
            }

            /* bloc_question */
            .bloc_question {
                .retour_rallonge {
                    display: none;
                }

                .title_question,
                #title_other_question {
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 0.8rem;
                    font-family: $secondaryFont;
                    margin: 1rem 0;
                    font-weight: 600;
                    letter-spacing: 0.08rem;
                }

                #title_other_question {
                    margin: 2rem 0 1rem;
                }

                .faq {
                    margin-bottom: 10px;

                    .intitule_question {
                        line-height: 1.075;
                        cursor: pointer;
                        font-size: 0.75rem;
                        font-weight: 300;
                        text-align: center;
                    }

                    .texte_question {
                        margin: 1rem 0;
                        font-size: 0.8rem;
                        line-height: 1.5;

                        h4 {
                            text-align: center;
                        }

                        h4,
                        p {
                            font-size: 0.8rem;
                        }
                    }
                }

                .questions_frequentes {
                    text-align: center;

                    div {
                        font-size: 0.8rem;
                        line-height: 1.5;
                    }

                    #other_question_3 {
                        a {
                            text-decoration: underline;

                            span {
                                text-transform: lowercase;
                            }
                        }
                    }
                }
            }

            /* bloc_secur */
            .top {
                background: $lightgrey2;
                padding: 28px 0;

                .right_bloc_btm_paiement {
                    .title {
                        display: flex;
                        align-items: center;
                        padding: 20px 0;
                        margin-bottom: 2px;
                        line-height: 1.1;
                        font-size: 1rem;
                        color: $black;
                        font-weight: 700;
                        justify-content: center;
                        text-transform: uppercase;
                        text-align: center;
                        letter-spacing: 0.2rem;

                        span.split {
                            display: block;
                            font-size: 0.7rem;
                        }
                    }

                    .tunel_paiement_right_description {
                        width: 70%;
                        margin: auto;
                        text-align: center;
                        font-size: 0.95rem;
                        line-height: 1.5;
                        font-family: $secondaryFont;

                        img {
                            max-height: 12px;
                        }
                    }
                }
            }
        }

        .wrapper_mes_adresses {
            width: 100%;

            .button {
                display: block;
                height: auto;
                padding: 1rem;
                text-align: center;
                text-decoration: none;
            }

            .conteneur_adresse {
                padding: 3rem 0 2rem;
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                flex-wrap: wrap;

                .adresse {
                    background-color: $quaternaryColor;
                    display: flex;
                    justify-content: center;
                    padding: 34px;
                    text-align: center;
                    width: 32%;
                    margin-bottom: 1rem;

                    .wrap_adresse {
                        .addr_label {
                            font-family: $primaryFont;
                            font-weight: bold;
                            text-transform: uppercase;
                            font-size: 14px;
                            display: flex;
                            flex-direction: column;

                            span+span {
                                margin-top: 0.2rem;
                                font-weight: 400;
                                letter-spacing: 0.1rem;
                                font-size: 11px;
                            }
                        }

                        .infos {
                            margin: 0.5rem 0;
                            font-family: $secondaryFont;
                            font-size: 1rem;
                            line-height: 1.5;
                        }
                    }

                    .modlinks_wrapper {
                        font-family: $primaryFont;
                        color: $tertiaryColor;
                        letter-spacing: 0;
                        font-size: 12px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 1rem;

                        .modify {
                            margin-left: 0.5rem;
                        }
                    }
                }

                &::after {
                    content: '';
                    display: block;
                    width: 32%;
                }
            }

            .adresse {
                .modlink.delete {
                    margin-left: 0.5rem;
                }
            }

            .addr_titre,
            .addr_type {
                text-transform: uppercase;
                font-family: $primaryFont;
                padding-bottom: 0.5rem;
                letter-spacing: 0.1rem;
            }

            .addr_type {
                font-size: 0.8rem;
            }

            .addr_titre {
                font-weight: 700;
            }
        }

        .edit_adresse {
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            .elem_tunnel {
                &.left_side {
                    .itm_back_btn {
                        display: none;
                    }

                    .choix_type_adresse {
                        display: flex;
                        justify-content: space-between;
                        width: 50%;
                        margin-bottom: 1rem;

                        .type_adresse {
                            text-transform: uppercase;
                            font-family: $primaryFont;
                            font-size: 0.7rem;
                            color: $grey7;
                            font-weight: 600;
                            letter-spacing: 2px;
                            margin-right: 2rem;
                            cursor: pointer;

                            p {
                                display: flex;

                                span {
                                    mask-image: url(../svg/spritesheet_2.svg);
                                    background-color: $greyblue;
                                    display: block;
                                    width: 1rem;
                                    height: 1rem;
                                    margin-right: 0.5rem;
                                }
                            }

                            &.maison {
                                p span {
                                    @include maskIconCoord(1, 5, 5);
                                }
                            }

                            &.bureau {
                                p span {
                                    @include maskIconCoord(2, 5, 5);
                                }
                            }

                            &.autre {
                                p span {
                                    @include maskIconCoord(3, 5, 5);
                                }
                            }

                            &.actif {
                                color: $darkgrey2;

                                span {
                                    background-color: $darkgrey2;
                                }
                            }
                        }
                    }

                    .w-group-label {
                        font-weight: 600;
                        color: $iridium;
                    }

                    button span,
                    .w-group-label {
                        font-family: $primaryFont;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

.order_gift_form {
    margin: 13px auto 0 !important;

    p {
        span {
            font-size: 0.8rem;
        }
    }
}

.checkout {
    #bank_div {
        .displayNone {
            display: none;
        }

        .errorBank {
            display: block;
            margin-bottom: 2rem;
        }

        #hipay_applepay_bloc {
            padding: 0.75rem;

            .title_wrapper_fold {
                margin-top: -1.2rem;

                .total_transaction {
                    display: none;
                }
            }

            #hipay-apple-pay-button {
                position: relative;
                height: 2.1rem;
                padding: 0;
                overflow: hidden;
                max-width: 20rem;
                margin: 1rem auto;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 5.2rem;
                    height: 6.95rem;
                    top: 0;
                    z-index: 2;
                    background-color: $white;
                }

                &:after {
                    left: inherit;
                    width: 5.3rem;
                    right: 0;
                }

                iframe {
                    position: absolute;
                    top: -5px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto -0.2rem;
                    width: 102%;
                    height: 2rem;
                }
            }
        }
    }

    .coupnCode {
        .bloc_paiement {
            border: none;

            h2 {
                margin-bottom: 1rem;
            }

            .c_both {
                margin-bottom: 2rem;
            }
        }
    }
}

.cart .wrapper_panier .cart_product_line .cart_product_desc,
.checkout .wrapper_panier .cart_product_line .cart_product_desc {
    justify-content: center;
    width: 60%;
}

#cart_slider_wrapper {
    display: none !important;
}

/* dragNdrop */
.cart {
    .dropper {
        border: 1px dashed transparent;
        min-height: 100px;
        transition: all 0.2s ease-out;

        &.drop_hover {
            border-color: $grey3;
            background-color: $lightgrey2;
        }
    }

    .dragHandle {
        position: absolute;
        right: 2%;
        bottom: -8%;
        cursor: -webkit-grab;
        cursor: grab;
        z-index: 10;
        transition: all .2s ease-out;

        @media screen and (max-width: 1250px) {
            bottom: -5%;
        }

        &:after {
            content: '\2807\2807';
            font-size: 1.125rem;
            letter-spacing: -0.5rem;
            line-height: 0;
            margin-right: 5px;
            color: $grey3;
        }
    }

    .dragOverlay {
        position: absolute;
        width: 100%;
        height: 0;
        bottom: 0;
        background-color: rgba(92, 115, 206, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transition: all 0.2s ease-out;

        span {
            color: $white;
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;
            font-size: 1rem;
        }
    }

    .draggableElem {
        border: 1px dashed transparent;
        transition: all 0.2s ease-out;

        &.dragHover {
            border-color: $grey3;

            .dragOverlay {
                height: 100%;
            }
        }

        &[draggable='true'] {
            border-color: transparent;

            .dragHandle {
                cursor: grabbing;
            }
        }
    }
}

/* RESPONSIVE */
/* TABLETTES */
@media screen and(min-width: 768px) {
    .wrapper_basket_content {
        .form-submit {
            flex-wrap: wrap;

            .loader-wrapper {
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 750px) and (max-width: 1024px) {
    .tunnel_track_pusher {
        .tunnel_track_wrapper {
            width: 100%;
            max-width: 65%;
        }
    }

    .cart .go_back {
        top: 30%;
    }
}

#paymentLinkValidate p {
    font-size: 1.4vmax;
}

div#paymentLinkSent p {
    font-family: $fourthFont;
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 20px;
    padding: 20px 20px 20px 0;
    text-align: left;
    color: $iridium;
}