@keyframes fadeOutUp {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(-30px);
	}
}

@keyframes slideInRight {
	0% {
		transform: translateX(105%);
	}
	100% {
		transform: translateX(0);
	}
}

.fadeOutUp {
	animation: fadeOutUp .3s forwards;
}

.slideInRight {
	animation: slideInRight .3s forwards;
	animation-timing-function: ease-in-out;
}

.push_banner_eshop {
	padding: 0 2rem;
	display: grid;
	display: -ms-grid;
	grid-template-columns: repeat(3, auto);
	grid-template-rows: auto auto;
	justify-content: space-between;
	align-items: end;

	/***@ IE only css **/
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {

		.menu_visuel_wrapper {
			padding: 0 2rem;
			padding-bottom: 6rem;
		}
		#push_5 {
			margin-top: -6rem;
		}
	 }

	#push_1,
	#push_2,
	#push_3 {
		grid-row: 1;
		-ms-grid-row: 1;
	}

	#push_4,
	#push_5,
	#push_6 {
		grid-row: 2;
		-ms-grid-row: 2;
	}

	#push_1,
	#push_5 {
		grid-column: 1;
		-ms-grid-column: 1;
	}

	#push_2,
	#push_4 {
		grid-column: 2;
		-ms-grid-column: 2;
	}

	#push_3,
	#push_6 {
		grid-column: 3;
		-ms-grid-column: 3;
	}

	#push_1,
	#push_2 {
		align-self: start;
	}

	#push_3 {
		margin-top: 6rem;
	}

	#push_5 {
		margin-bottom: 6rem;
	}

	div.menu_visuel {
		width: 28vw;

		img {
			width: 100%;
			height: auto;
		}

		p {
			@include wshopFont($primaryFont, .875rem, unset, .1rem, 500, $primaryColor);
			text-transform: uppercase;
		}

		h2 {
			@include wshopFont($primaryFont, .875rem, unset, .1rem, 500, $primaryColor);
			text-transform: uppercase;
		}
	}
}

.category .main_wrapper.special {
	#breadcrumbs {
		.breadcrumb:nth-child(2) {
			display: none;
		}
	}
}

//Loader animé, remplace l'affichage pendant le chargement
//Utilisé pour le scroll infini

.loader_scroll {
	position: relative;
    text-align: center;
	width: fit-content;
	margin: 0 auto;

	&.loading {
		.loader {
			z-index: 2;
			opacity: 1;
		}
	}

	.button {
		display: block;
		&:not(.loader) {
			width: auto;
			padding: 0 15px;
			text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
			background-color: transparent !important;

			&:focus  {
				// color: $primaryColor;
				text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
				background-color: transparent !important;
			}
			&:hover {
				background-color: $primaryColor!important;
			}
		}
	}

	.loadedGauge {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background-color: #D9D9D957;
		z-index: -1;
	}

	.loader {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: -1;
		width: 100%;
		background-image: url(../svg/three_dots.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 16%;
	}
}

.see_all_auto {
	appearance: none;
	display: block;
	width: fit-content;
	background-color: transparent;
	text-decoration: underline;
	text-underline-offset: 4px;
	margin: 2rem auto 0;
	cursor: pointer;
}

