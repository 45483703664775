.home-columns-container {
    .swiper-slide {

        img {
            width: 100%;
            height: auto;
        }

        .eclat_rond img {
            width: 40px !important;
        }

        .eclat_rect img {
            width: 190px !important;
        }

        .boxText {
            text-align: center;
            font-family: $primaryFont;
            color: white;

            .home-module-pretitle {
                font-size: 20px;
                text-transform: uppercase;
                letter-spacing: .2rem;
            }

            .home-module-title {
                font-size: 55px;
                text-transform: uppercase;
                letter-spacing: .2rem;
                margin: 40px 0 50px;
            }

            .rowCta {
                width: 100%;
                display: flex;
                justify-content: center;

                .home-module-cta {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid white;
                    font-size: 11px;
                    letter-spacing: .1rem;
                    font-weight: bold;
                    color: white;
                    width: 100%;
                    max-width: 245px;
                    height: 45px;
                    text-decoration: none;
                    background-color: transparent;

                    &+.home-module-cta {
                        margin-left: 2rem;
                    }
                }
            }

            .home-module-contents {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 52%;
                left: 0;
                right: 0;
                margin: 0 auto;

                &.align-left {
                    left: 14%;
                    right: auto;
                }

                &.align-right {
                    right: 12%;
                    left: auto;
                }

                &.align-top {
                    top: 13%;
                    bottom: auto;
                }

                &.align-bottom {
                    bottom: 20%;
                }

                &.align-middle {
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }
    }
}

.home-module.signup-module {
    font-family: $primaryFont;
    margin-top: 4rem;

    .home-module-title {
        font-size: 0.8vw;
        flex-grow: 1;

        span {
            display: block;
            margin-top: 2rem;
            font-size: 1.15vw;
        }
    }

    .logoLight {
        &::before {
            display: block;
        }
    }



    form {
        margin-top: 4rem;
    }


}


.btn-next-swipe-tg,
.btn-back-swipe-tg,
.btn-next-swipe,
.btn-back-swipe {
    background-image: url(../svg/spritesheet.svg);
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    top: 50%;
    z-index: 6;

}

.btn-next-swipe-tg,
.btn-next-swipe {
    @include bgIconCoord(6, 5, 5);
    transform: rotate(-90deg) translateX(-50%);
    right: .5rem;
}

.btn-back-swipe-tg,
.btn-back-swipe {
    @include bgIconCoord(6, 5, 5);
    transform: rotate(90deg) translateX(50%);
    left: .5rem;
}

.main_wrapper .home-module:first-of-type {
    max-height: 781px;
    overflow: hidden;
    margin-top: 0;
}

.home-module .slide_video iframe {
    width: 100%;
    height: 100%;
}

.image-image-text {
    max-width: 1170px;
    width: 98%;
    margin: 100px auto 0;

    @include medium {
        padding: 0 2rem;
    }

    .insideImg {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .rightSide {
            width: 49%;
            max-width: 515px;
            display: flex;
            flex-direction: column-reverse;
            padding-top: 56px;

            h2.home-module-title {
                margin-bottom: 32px;
                @include wshopFont($primaryFont, 1.5rem, unset, .1rem, 700, $primaryColor);
                text-transform: uppercase !important;
            }

            p {
                font-size: 1rem;
                margin-bottom: 62px;
                font-weight: 400;
                color: $primaryColor;
                text-align: justify;
            }

            img {
                width: 100%;
            }
        }

        .leftSide {
            width: 49%;

            img {
                width: 100%;
            }
        }

        .home-module-cta {
            display: flex;
            justify-content: center;
            font-family: $primaryFont;
            color: $primaryColor;
            margin-top: 5px;
            text-decoration: none;

            span {
                font-size: 14px;
                letter-spacing: .1rem;
                text-transform: uppercase;
            }
        }
    }
}

.image-full {
    max-width: 1620px;
    width: 98%;
    margin: 0 auto;
    position: relative;
    margin-top: 102px;

    .home-module-contents {
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .text_content {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            right: 6%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .home-module-pretitle {
                font-size: 20px;
                font-weight: 300;
                letter-spacing: .1rem;
                font-family: $primaryFont;
                text-transform: uppercase;
                margin-bottom: 34px;
            }

            .home-module-title {
                font-family: $primaryFont;
                font-size: 55px;
                letter-spacing: .2rem;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
}

body.homepage {
    .home-module.cover-module {
        +.home-module.text-module {
            +.home-module.image-full {
                margin-top: 1.2rem !important;
            }
        }
    }
}

.image-image-video-text {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1620px;
    width: 98%;
    margin: 0 auto;
    position: relative;
    margin-top: 102px;

    .rowTitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $primaryFont;

        h2 {
            @include wshopFont($primaryFont, 1.5rem, unset, .1rem, 700, $primaryColor);
            text-transform: uppercase;
        }

        h3 {
            margin: 1rem 0 2rem;
            @include wshopFont($secondaryFont, 1rem, unset, unset, 400, $primaryColor);
        }
    }

    .insideImg {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        max-width: 1460px;
        margin: 0 auto;
    }

    .leftSide {
        width: 66%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        .side1 {
            width: 32%;
            margin: 0 2rem;
            display: flex;
            justify-content: flex-start;

            img {
                width: 100%;
            }
        }

        .side2 {
            width: 60%;
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-start;

            .home-column {
                img {
                    height: 100%;
                    width: 80%;
                }

                iframe {
                    width: 100%;
                }

                &+.home-column {
                    margin-bottom: 26px;
                }
            }
        }
    }

    .rightSide {
        width: 32%;
        padding-right: 2rem;

        p {
            font-size: 15px;
            text-align: justify;

            strong {
                font-size: 16px;
            }
        }

        .empty {
            display: none;
        }
    }
}

.social-module {
    margin-top: 4rem;

    .home-columns-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .home-column {
            text-align: center;

            .home-module-title {
                font-family: $primaryFont;
                font-size: 25px;
                letter-spacing: .1rem;
                text-transform: uppercase;
                margin-bottom: 1rem;
            }

            .home-module-text {
                margin-bottom: 1rem;
                font-size: 1rem;
                text-align: center;
                line-height: .8;

                strong {
                    @include wshopFont($primaryFont, 1.5rem, unset, .1rem, 700, $primaryColor);
                    text-transform: uppercase;
                }
            }

            &+.home-column {
                width: 100%;

                .social-media-container {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    overflow: hidden;

                    .insta-link {
                        width: 296px;
                        height: auto;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.slider-module {
    margin-bottom: 2rem;

    .home-columns-container {
        max-width: 1620px;
        width: 98%;
        margin: 0 auto;
        position: relative;
        margin-top: 102px;

        .home-column {
            padding: 0 2rem;
        }
    }

    .home-module-title.uppercase_title {
        margin-bottom: 1rem;
        text-align: center;
        @include wshopFont($primaryFont, 1.5rem, unset, .1rem, 700, $primaryColor);
        text-transform: uppercase;
    }

    .home-column>span {
        margin-bottom: 1rem;
        text-align: center;
        @include wshopFont($primaryFont, 1.5rem, unset, .1rem, 700, $primaryColor);
        text-transform: uppercase;
        display: block;
    }

    .home-module-text {
        margin-bottom: 1rem;
        text-align: center;
        @include wshopFont($secondaryFont, 1rem, unset, unset, 400, $primaryColor);
    }

    p.item_name .subtitle {
        display: block !important;
    }
}

.tg-module {
    margin-top: 2rem;

    .home-columns-container {
        max-width: 1620px;
        width: 98%;
        margin: 0 auto;
        position: relative;
        margin-top: 1.3rem;

        .home-column {
            padding: 0 2rem;
        }
    }

    .home-module-title {
        margin-bottom: 15px;
        text-align: center;
        @include wshopFont($primaryFont, 2.19rem, unset, .1rem, 700, $primaryColor);
        text-transform: uppercase;
    }

    .home-module-cta {
        margin-bottom: 1rem;
        text-align: center;
        font-family: $secondaryFont;
        font-size: 1rem;
        line-height: unset;
        letter-spacing: unset;
        font-weight: 400;
        width: 100%;
        text-align: center;
        display: block;
    }

    .home-module-text {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;

        &>span {
            font-size: 1rem;
            font-family: $secondaryFont;
            margin-bottom: 30px;
        }
    }

    .wrapper_description p.item_name {
        font-size: 12px;
        font-weight: 600;

        .subtitle {
            display: block;
            font-weight: 500;
        }
    }

    .swiper-container {
        padding-bottom: 40px;
    }

    .swiper-scrollbar {
        background-color: #eee;
        cursor: pointer;
        opacity: 0.1;

        .swiper-scrollbar-drag {
            background-color: #8d8d8d;
        }
    }

    .swiper-slide {
        width: 100%;
    }
}

.sliderMainHome#super_home_swiper {
    img {
        width: 100%;
        height: auto;
    }
}

.slide_video+.swiper-lazy-preloader {
    display: none;
}

/*********************************************/
/******   RESPONSIVE   *********/
/*********************************************/

/* TABLETTES **********/
@media screen and (min-width: 750px) and (max-width: 1024px) {

    .image-image-video-text .leftSide .side1,
    .image-image-video-text .leftSide .side2 {
        width: 100%;
        justify-content: center;
    }

    .side2 {
        margin: 0 2rem;

        .home-column {
            text-align: center;

            &:nth-of-type(2) {
                margin-top: 1rem;
                position: relative;
                height: 20.8125rem;
                width: 100%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.cover-module {

    @media screen and (max-width: 1440px) {
        min-height: auto;
    }

    .home-module-body {
        position: relative;

        .home-columns-container {
            .swiper-slide {
                .home-module-bg {
                    position: relative;
                    width: 100% !important;
                    height: auto;
                    vertical-align: top;
                }
            }
        }
    }

    .slide_video {
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: 46.05%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            .vp-controls {
                display: none !important;
            }
        }

        .block_redirect {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 1;
            top: 0;
            left: 0;
        }
    }

    .home-module-cta {
        min-width: 245px;
    }

    small.legal_mentions {
        display: block;
        width: 100%;
        margin: 1rem 0 0;
        text-align: center;
        font-family: $primaryFont;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: .02rem;
    }
}

.home-module.four-images {
    display: flex;
    width: 98%;
    max-width: 1620px;
    gap: 1.045vw;
    margin: 40px auto 0;

    .home-column {
        position: relative;
        width: 25%;
    }

    .home-module-contents-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0 2rem;

    }

    .home-module-title {
        text-align: center;
        font-family: $primaryFont;
        font-weight: 700;
        font-size: 1.5rem;
        text-transform: uppercase;

        span {
            text-align: center;
            width: 100%;
            cursor: pointer;

            &:hover {
                border-bottom: 0.1px solid rgb(255, 255, 255);
            }
        }

        a {
            color: inherit;
        }
    }

    .home-module-contents-image>span,
    .home-module-contents-image>a {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: calc((540 / 405) * 100%);
    }

    .home-module-contents-image {
        .slide_video {
            >a {

                .block_redirect {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    .home-module-bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
    }
}

.home-module.image-image-image {
    width: 98%;
    max-width: 1620px;
    margin: 50px auto 0;

    .image-image-image-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1.045vw;
    }

    .home-column {
        position: relative;
    }

    .home-module-title {
        text-align: center;
        font-family: $primaryFont;
        font-weight: 700;
        text-transform: uppercase;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
        z-index: 1;

        &:hover span {
            border-bottom: 0.1px solid rgb(255, 255, 255);
        }
    }

    .home-column>span,
    .home-module-link {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: calc((540 / 1680) * 100%);
    }

    .home-module-img,
    .home-module-bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
    }
}

.home-module.image-image {
    display: flex;
    width: 98%;
    max-width: 1620px;
    gap: 1.045vw;
    margin: 50px auto 0;

    .home-column {
        position: relative;
        width: 50%;
    }

    .home-module-contents-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0 2rem;
    }

    .home-module-title {
        text-align: center;
        font-family: $primaryFont;
        font-weight: 700;
        font-size: 30px;
        text-transform: uppercase;

        &:hover span {
            border-bottom: 0.1px solid rgb(255, 255, 255);
        }
    }

    .home-module-contents-image>span,
    .home-module-contents-image>a {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: calc((826 / 830) * 100%);
    }

    .home-module-bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
    }

    .slide_video {
        >a {
            position: relative;
            display: block;
            height: 0;
            width: 100%;
            padding-top: calc((826 / 830) * 100%);

            iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .block_redirect {
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 1;
                top: 0;
                left: 0;
            }
        }
    }
}