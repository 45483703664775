$phone			: 599px;	//phone
$tabletH		: 1024px; 	//horizontal
$tabletV		: 899px;	//vertical
$smallDesk 		: 1280px;	//small Desktop

@mixin queries($media) {
	@if $media == smallDesk {
		@media only screen and (max-width: $smallDesk) { @content; }
	}
	@else if $media == tabletH {
		@media only screen and (max-width: $tabletH) { @content; }
	}
	@else if $media == tabletV {
		@media only screen and (max-width: $tabletV) { @content; }
	}
	@else if $media == phone {
		@media only screen and (max-width: $phone) { @content; }
	}
}


@mixin queriesWshop($varMedia, $width) {
	@if ($width == max){
		@media only screen and (max-width: $varMedia) {
			@content;
		}
	}
	@if ($width == min){
		@media only screen and (min-width: $varMedia) {
			@content;
		}
	}
}

@mixin ie(){
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}

@mixin flexbox($direction, $justify, $align, $wrap: 'unset') {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
	align-items: $align;
	flex-wrap: $wrap;
}


//exemple

/* 
@include queries($testn max){
	width: 100px;
} 
/*
.test {
	width: 250px;
	@include queries(smallDesk) {
		width: 100% ;
	}
	@include queries(tabletH) {
		width: 125px;
	}
	@include queries(tabletV) {
		width: 125px;
	}
	@include queries(phone) {
		width: 100% ;
	}
}
*/