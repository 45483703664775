.categoryNav .wrap_flitre_pager .pagerNav {
    max-width: 380px;
    margin: 0 auto 120px;

    .pager_wrapper {
        @include flexbox(row-reverse, center, center);

        .pagination,
        .see_more_product {
            @include wshopFont($primaryFont, .75rem, 1.5, unset, 500, $primaryColor);
        }

        .pagination {

            span,
            a {
                margin-right: 2rem;
            }

            a {
                text-decoration: none;
            }

            .active {
                color: $secondaryColor;
                font-weight: 700;
            }

            .suivant,
            .precedent {
                display: none;
            }
        }

        .see_more_product {
            text-decoration: underline;
            cursor: pointer;
        }

        .see_all_product {
            text-decoration: underline;
            cursor: pointer;

            &:not(.see_all) {
                display: flex;
                align-items: center;
                font-family: $primaryFont;
                font-size: .688rem;
                letter-spacing: .05rem;
                font-weight: 700;
                text-transform: uppercase;
                text-decoration: none;
                text-shadow: 0 4px 4px rgba(0, 0, 0, .25), 0 4px 4px rgba(0, 0, 0, .25);
                border: solid 1px $grey2;
                height: 2.8125rem;
                padding: 0 15px;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
    
                &:hover {
                    background: $grey2;
                    color: $white;
                }
            }
        }
    }
}