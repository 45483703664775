.wrapper_tunnel {
    max-width: 1440px;
    width: 100%;
    margin: 36px auto ;
    .login_page {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .wrapper_login {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 48%;
            padding:40px 30px;
            border: 1px solid rgba($primaryColor, $alpha: .1);

            .login_bloc {
                width: 54%;
            }
        }
    }
}

.wrapper_moncompte,.faq {

    .accountTitleHolder {
        .lowercase_title {
            display: block;
            max-width: 50%;
            font-size: 1rem;
            margin: 1rem 0;
        }
    }

    .update_success {
        margin-bottom: 1rem;
        background-color: rgba($secondaryColor, $alpha: .1);
        padding: .2rem;
    }

    .flexAccount {
        width: 100%;
        max-width: 1620px;
        margin: 0 auto;
        padding: 0 2rem;


        .wrapper_menu_compte {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 65px;


            .welcome_client {
                display: none;
            }

            .item {
                text-transform: uppercase;
                font-family: $primaryFont;
                color: $primaryColor;
                font-weight: 500;
                font-size: 13px;
                a {
                    text-decoration: none;
                    display: flex;
                    position: relative;
                }

                &.actif {
                    a {
                        color: $secondaryColor;
                        font-weight: bold;
                        &:before {
                            content: '';
                            width: 20px;
                            height: 20px;
                            background-image: url(../svg/spritesheet.svg);
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                            @include bgIconCoord(16, 5, 5);
                        }
                    }

                }

                &+.item {
                    margin-left: 76px;
                }
            }
        }

        @media screen and (max-width: 768px) {
            .wrapper_menu_compte {
                justify-content: space-around;

                .item+.item {
                    margin-left: 0;
                }
            }
        }

        .wrapper_moncompte_main {
            border-top: 1px solid rgba($color: #000000, $alpha: .1);
            width: 100%;

            @media screen and (max-width: 1024px) {
                padding: 0 3rem;
            }

            .update_success {
                font-weight: bold;
                font-size: 18px;
                color: #000BD6;
            }

            .wrapper_msg_bounce {
                text-align: center;
                padding-top: 2rem;
            }

            &.wrapper_mes_adresses {
                .button {
                    display: block;
                    height: auto;
                    padding: 1rem;
                    text-align: center;
                    text-decoration: none;
                }

                .conteneur_adresse {
                    padding: 3rem 0 2rem;
                    &::after {
                        content: "";
                        display: block;
                        width: 32%;
                    }
                }

                .adresse {
                    .modlink.delete {
                        margin-left: 0.5rem;
                    }
                }

                .addr_titre,
                .addr_type {
                    text-transform: uppercase;
                    font-family: $primaryFont;
                    padding-bottom: 0.5rem;
                    letter-spacing: 0.1rem;
                }
                .addr_type {
                    font-size: 0.8rem;
                }
                .addr_titre {
                    font-weight: 700;
                }
            }

            .wrapper_form,
            .wrapper_form_adresse,
            .addressWrapper,
            .content_commandes,.intro {
                width: 100%;
                max-width: 1024px;
                margin: 44px auto 0;
            }

            .wrapper_form,
            .wrapper_form_adresse {
                .w-input-container > .w-input.w-tel-input ~ small.w-input-error {
                    top: 89%;
                }
            }

            .intro {
                text-align: center;
            }

            .addressWrapper {
                .form_submit.add {
                    max-width: 245px;
                    .button {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        margin-top: 30px;
                        text-transform: uppercase;
                        font-size: 11px;
                    }
                }
            }

            .choix_type_adresse {
                height: 44px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                .type_adresse {
                    text-transform: uppercase;
                    cursor: pointer;
                    font-family: $primaryFont;
                    font-size: 14px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    &::before {
                        content: '';
                        width: 20px;
                        height: 20px;
                        background-image: url(../svg/spritesheet_2.svg);
                        margin-right: .2rem;
                    }

                    &.maison::before {

                        @include bgIconCoord(1, 5, 5);
                    }

                    &.bureau::before {

                        @include bgIconCoord(2, 5, 5);
                    }

                    &.autre::before {

                        @include bgIconCoord(3, 5, 5);
                    }

                    &.actif {
                        color: $secondaryColor;
                        font-weight: bold;

                        &.maison::before {
                            @include bgIconCoord(6, 5, 5);
                        }

                        &.bureau::before {

                            @include bgIconCoord(7, 5, 5);
                        }
                        &.autre::before {

                            @include bgIconCoord(8, 5, 5);
                        }
                    }

                    &+.type_adresse {
                        margin-left: 36px;
                    }
                }
            }

            .conteneur_adresse {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                flex-wrap: wrap;

                .adresse {
                    background-color: $quaternaryColor;
                    display: flex;
                    justify-content: center;
                    padding: 34px;
                    text-align: center;
                    width: 32%;
                    margin-bottom: 1rem;


                    .wrap_adresse {
                        .addr_label {
                            font-family: $primaryFont;
                            font-weight: bold;
                            text-transform: uppercase;
                            font-size: 14px;
                            display: flex;
                            flex-direction: column;
                            span+span {
                                margin-top: .2rem;
                                font-weight: 400;
                                letter-spacing: .1rem;
                                font-size: 11px;
                            }
                        }

                        .infos {
                            margin: .5rem 0;
                            font-family: $secondaryFont;
                            font-size: 1rem;
                            line-height: 1.5;


                        }
                    }

                    .modlinks_wrapper {
                        font-family: $primaryFont;
                        color: $tertiaryColor;
                        letter-spacing: 0;
                        font-size: 12px;

                    }
                }
            }

            .wrapper_menu_tab {
                display: flex;
                justify-content: space-between;
                align-items: center;

                #com_onglet {
                    display: flex;
                    font-family: $primaryFont;
                    font-size: 11px;
                    font-weight: 500;
                    .onglet {
                        cursor: pointer;
                        font-size: 11px;
                        letter-spacing: .1rem;
                        text-transform: uppercase;
                    }

                    .onglet+.onglet {
                        margin-left: 1rem;
                    }
                }
            }

            #orders_by_type {
                margin: 58px 0;

                .header {
                    font-family: $primaryFont;
                    text-transform: uppercase;
                    letter-spacing: .1rem;
                    border-bottom: 1px solid rgba($color: #000000, $alpha: .1);

                    th {
                        font-weight: 500;
                        font-size: 11px;
                        padding-bottom: 1rem;
                        letter-spacing: .1rem;
                        text-transform: uppercase;
                    }

                }

                tbody.content {
                    td {
                        padding: 1rem 0;
                    }
                }

            }

            .date_select {
                border: 1px solid rgba($color: #000000, $alpha: .4);
                width: 240px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;


                select {
                    width: 100%;
                    -webkit-appearance: none;
                    height: 100%;
                    padding: 0 1rem;
                    display: inline-block;
                    box-sizing: border-box;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    z-index: 2;
                    background-color: transparent;
                    font-size: 12px;
                    font-family: $primaryFont;
                    text-transform: uppercase;
                    letter-spacing: .1rem;
                    font-weight: 500;
                }

                &::before {
                    content:"";
                    position: absolute;
                    top: 50%;
                    right: 1rem;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(6, 5, 5);
                    z-index: 1;
                }
            }

            .newMsg {
                width: 100%;
                max-width: 1024px;
                margin: 44px auto 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .button.dark {
                    @extend .button;
                    width:245px;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    display: flex;

                }

            }

            .wrapper_message,#orders_by_type {
                width: 100%;
                max-width: 1024px;
                margin: 24px auto ;

                .table_mes_messages,.table_mon_compte {
                    width: 100%;
                    thead {
                        border-bottom: 1px solid rgba($color: #000000, $alpha: .1);
                    }

                    th {
                        font-weight: 500;
                        font-size: 11px;
                        letter-spacing: .1rem;
                        text-transform: uppercase;
                        white-space: nowrap;
                        font-family: $primaryFont;
                        padding: .5rem;
                    }

                    td {
                        font-family: $primaryFont;
                        font-size: 13px;
                        font-weight: 400;
                        padding: 1rem 0;
                    }
                }

                .message_detail {
                    .infos_message {
                        line-height: 1.6;
                    }

                    .detail_msg_label {
                        label {
                            margin-bottom: .5rem;
                            display: block;
                        }
                    }

                }

                #filepj1 {
                    display: none;
                }

                .w-reply-form {
                    .ajout_pj_label {
                        padding: 0 1rem;
                    }

                    .file-list {
                        margin: .5rem 0;
                        padding: .2rem 0;
                        .rowList {
                            display: flex;
                            align-items: center;
                        }
                        .uploadedFile {
                            background-color: rgba($secondaryColor, $alpha: .1);
                            display: block;
                            max-width: 250px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            padding: 0.2rem 0.5rem;
                        }

                        .closePj {
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(8, 5, 5);
                            width: 20px;
                            height: 20px;
                            background-color: white;
                        }
                    }
                }
            }
        }

        .w-newspreferences-form {
            .w-form-line {
                .multiline {
                    strong {
                        font-family: $primaryFont;
                        text-transform: uppercase;
                        letter-spacing: .05rem;
                        font-size: 13px;
                        font-weight: 500;
                    }

                    span {
                        font-family: $secondaryFont;
                        font-size: 16px;

                        &.w-newsletter-next-label{
                            font-family: $primaryFont;
                            font-style: italic;
                            font-weight: 600;
                            font-size: 10.5px;
                        }

                    }
                }
                &:nth-child(5) {
                    display: none;
                }
            }

            .w-checkbox-input, .w-radio-input {
                height: auto;
            }

            .w-checkbox-input .w-input-element+.w-input-label, .w-radio-input .w-input-element+.w-input-label {
                align-items: flex-start;
            }
        }

        .montant {
            text-align: center;
        }

        .btn_ouvrir_message, .btn_detail {
            cursor: pointer;
            text-decoration: underline;
            font-size: 12px;
            color: #949494;
        }

    }
}

.cmd_details {
    max-width: 1040px;
    width: 100%;
    margin: 60px auto 0;

    .go_back {
        display: flex;
        justify-content: flex-start;
        font-family: $primaryFont;
        text-transform: uppercase;
        font-size: 11px;

        a {
            text-decoration: none;
            color: #949494;
            display: flex;
            margin-left: .5rem;
        }

        .arrowBottom {
            &::before {
                transform: rotate(90deg);
            }
        }

    }

    .order_detail_head {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;

        .wrapper_infos_cmd,.wrapper_delivery_addr {
            width: 48%;
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            margin-bottom: 2rem;
            align-items: center;
            background-color: $quaternaryColor;
            padding: 36px 0;

            p span {
                font-weight: 500;
                font-size: 1rem;

            }
        }

        .order_title, .addr_title {
            font-family: $primaryFont;
            font-weight: 700;
            letter-spacing: .1rem;
            text-transform: uppercase;
            font-size: 14px;
            margin-bottom: 1rem;
        }
    }

    .cmd_detail {
        padding: 52px 86px ;

        .cmd_items {
            display: flex;
            flex-direction: column;

            .product-link {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .cart_thumb_holder{
                    max-width: 160px;

                    img {
                        width: 100%;
                    }
                }

                .cart_detail_box {
                    width: 75%;
                }

                .cart_product {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;


                    .title {
                        font-family: $primaryFont;
                        font-size: 1rem;
                        margin-bottom: 24px;
                    }

                    .sub_couleur,.sub_taille {
                        font-size: 12px;
                        font-family: $primaryFont;
                        margin-top: .5rem;

                        label {
                            text-transform: uppercase;
                            font-weight: 700;
                        }

                    }

                    .configurator {
                        .cart_product_sizecol {
                            font-size: 12px;
                            font-family: $primaryFont;
                            margin-top: .5rem;

                            label {
                                text-transform: uppercase;
                                font-weight: 700;
                            }
                        }
                    }

                    .remove {
                        font-size: 12px;
                        font-weight: 500;
                        color: rgba($primaryColor, $alpha: .4);
                    }


                    .wrapper_price {
                        .pricetag {
                            font-family: $primaryFont;
                            font-size: 1rem;
                            font-weight: 500;
                            text-transform: uppercase;
                        }
                    }

                }

                &+.product-link {
                    margin-top: 1.5rem;
                }
            }
        }
    }
}

.cmd_total_container {
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;

    .cmd_total {
        width: 83%;
        margin: 0 auto;

        .formRow {
            display: flex;
            justify-content: space-between;
            font-family: $primaryFont;
            margin-bottom: .5rem;
            align-items: center;

            label {
                font-size: 12px;
            }

            .pricetag {
                font-size: 14px;
                font-weight: 400;
            }

            &:last-child {
                margin:36px 0 0;
                label {
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 700;
                }

                .pricetag {
                    font-size: 1rem;
                    font-weight: 700;
                }
            }


        }
    }

    #comment_retourner_cmd {
        width: 86%;
        margin: 2rem auto 0;

        #createNumRetour {
            display: flex;
            align-items: center;
            background-color: $quaternaryColor;
            padding: 20px;
            border: 1px solid rgba($primaryColor, $alpha: .3);

            .intro {
                margin-top: 0;
                text-align: left;
                line-height: 1;
                width: 68%;
                font-size: 15px;
            }

            .btn_container {
                .button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                }
            }

            .link_retour {
                display: none;
            }
        }

        #printPdf {
            #btnPrintPdf {
                margin-top: .5rem;
                .btn_container .button  {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .print-return+.print-return {
                display: none;
            }
            .content {
                margin-top: 1rem;
                font-size: 0.9rem;
            }
        }
    }
}

.wishListWrapper {

    .wishlist_bloc {
        width: 100%;
        max-width: 1040px;
        margin: 24px auto ;
        display: flex;
        flex-direction: column;

        .main_bloc {
            display: flex;
            justify-content: flex-start;

            .intro_and_totaux {
                margin-bottom: 1rem;
            }

            .wishlist_intro {
                font-size: 1rem;
                margin-top:15px;
            }

            #nrArticlesSelected {
                span {
                    font-family: $primaryFont;
                    color: $primaryColor;
                    font-size: .8rem;
                    letter-spacing: .05rem;
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }
        }

        .wishlist_content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .wish_liste_product {
                display: flex;
                flex-direction: column;
                width: 772px;
                .product_ctn {
                    border: 1px solid rgba($color: #000000, $alpha: .1);
                    padding: 20px;
                    width: 98%;
                    margin: 0 auto;
                    position: relative;

                    &+.product_ctn {
                        margin-top: 30px;
                    }

                    .product_check {
                        position: absolute;
                        left: -8px;
                        top: 50%;
                        transform: translateY(-50%);


                        .checkbox_select_product {
                            -webkit-appearance: none;
                            display: block;
                            width: 16px;
                            height: 16px;
                            box-sizing: border-box;
                            box-shadow: inset 0 0 0 1px #fff;
                            transition: all .2s ease-in-out;
                            outline: 0;
                            border: 1px solid rgba($primaryColor, $alpha: .1);
                            background-color: #fff;

                            &:checked {
                                background-color: $secondaryColor;
                            }
                        }

                        label {
                            display: none;
                        }
                    }

                    .wrapper_product {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .photo_product {
                            display: flex;
                            justify-content: center;
                            width: 28%;

                            img {
                                width: 100%;
                                max-width: 160px;
                                height: auto;
                            }
                        }

                        .desc_and_price {
                            width: 70%;
                            display: flex;
                            justify-content: space-between;
                            font-family: $primaryFont;

                            .product_desc {
                                .title {
                                    font-family: $primaryFont;
                                    font-size: 1rem;
                                    margin-bottom: 24px;
                                }

                                .wrapper_details {
                                    font-size: 12px;
                                    .feats {
                                        margin-bottom: 10px;

                                        label {
                                            text-transform: uppercase;
                                        }

                                        &:last-child {
                                            display: none;
                                        }
                                    }
                                }

                                .remove {
                                    font-size: 12px;
                                    font-weight: 500;
                                    color: rgba($primaryColor, $alpha: .4);
                                }
                            }

                            .product_prix {
                                .wrapper_price {
                                    margin-left: 0.5rem;
                                }

                                .pricetag {
                                    font-size: 1rem;
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    visibility: visible;
                                }
                            }
                        }


                    }
                }
            }

            .bloc_right {
                width: 245px;
                display: flex;
                flex-direction: column;

                .form_submit,.input_container  {
                    .button {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-decoration: none;

                        &.full {
                            background-color: $primaryColor;
                            color: white;
                        }


                        &.selec_all {
                            margin-top: 1rem;
                        }
                    }
                    #bloc_btn_loader {
                        width: 100%;
                        height: 100%;
                    }

                }

                .share_wl {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    height: 3rem;
                    align-items: center;
                    margin-top: 1rem;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

        }
    }

}

/*********************************/
/* MON COMPTE - UPDATE CONFIRM */
/*********************************/
.wrapper_moncompte .update_success {
    background-color: transparent;
}

.wrapper_moncompte .w-form button.w-submit-button.w-loader{
    height: 100%;
}

/*********************************/
/* MON COMPTE - NEWSLETTER */
/*********************************/
.w-checkbox-input .w-input-element+.w-input-label span.multiline {
    margin-left: 1rem;
}

.wrapper_moncompte .flexAccount .w-newspreferences-form .w-form-line .multiline strong {
    font-weight: 700;
}

/*********************************/
/* MON COMPTE - MESSAGES */
/*********************************/

.customer {
    .message_account_container {
        .details_msg_content {padding-bottom: 3rem;}
        .infos_message p {padding-bottom: 0.2rem;}
    }

    .newlogin_page {
        padding-top: 0;
    }

    .wrapper_moncompte {

        h1.pwd_forget_title,
        .wrapper_password {
            max-width: 1440px;
            width: 100%;
        }

        h1.pwd_forget_title {
            font-family: $primaryFont;
            font-size: 1rem;
            line-height: unset;
            letter-spacing: .1rem;
            font-weight: 700;
            color: #242424;
            margin: 6rem auto 2rem;
            text-align: center;
            text-transform: uppercase;
        }

        .wrapper_password {
            margin: 2rem auto;
            .content_password {
                width: 43%;
                margin: 0 auto;

                .w-input-element:not([type=radio]):not([type=checkbox]),
                input[type=password].w-input-element {
                    &:focus {
                        border-color: $black;
                        box-shadow: none;
                    }
                }

                .valid_password {
                    text-align: center;
                }

                .w-submit-button {
                    margin: 2rem 11.5rem;
                    text-align: center;

                    span {
                        display: inline-block;
                        width: 100%;
                        height: 2.8125rem;
                        line-height: 2.8125rem;
                    }
                }

            }
        }
    }
}
.wrapper_moncompte .flexAccount .wrapper_moncompte_main .wrapper_message .message_detail .detail_msg_label label {margin-bottom: 1rem;}

.wrapper_tunnel {
    #outsite_ue_notice {
        max-width: 1160px;
        width: 100%;
        margin: 0 auto 2rem;
        font-size: 17px;
        font-weight: 500;
        color: #242424;
        text-align: left;
        font-family: montserrat,sans-serif;
        padding-top: 2rem;
    }
}

/*********************************************/
/******   RESPONSIVE   *********/
/*********************************************/

/* TABLETTES **********/
@media screen and (min-width: 750px) and (max-width: 1024px) {
    .faq .flexAccount .wrapper_menu_compte .item+.item,
    .wrapper_moncompte .flexAccount .wrapper_menu_compte .item+.item {
        margin-left: 3rem;
    }
}

body.customer,
body.in_tunnel {
    .w-has-tel {
        #iconValidclientTel,
        #iconValidtelephone,
        #iconValidtelephone {
            display: none;
        }
    }
}

body.body_login {
    .w-oneclick-connect {
        #googleConnect {
            background: $aquaHaze;
            margin: 0 1.3rem;

            .g_id_signin {
                div:first-child {
                    div:first-child {
                        div:first-child {
                            width: 2.4rem;
                            height: 2.4rem;
                        }
                    }
                }

                iframe[id^="gsi"] {
                    height: 2.4rem !important;
                    width: 2.4rem !important;
                    margin: 0 !important;
                }
            }
        }
    }
}