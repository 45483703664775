body.category {
	@media only screen and (max-width: 1024px) {
		min-width: 1005px;
	}

}

.category .page_wrapper .catPush {
	max-width: 1420px;
	margin: 0 auto;
	text-align: center;

	h2 {
		@include wshopFont($primaryFont, 1.5rem, unset, unset, 700, $primaryColor);
		text-transform: uppercase;
	}

	h3 {
		@include wshopFont($secondaryFont, 1rem, unset, unset, 400, $primaryColor);
	}

	h2,
	h3 {
		margin-bottom: 1rem;
	}

	.catPush_list {
		@include flexbox(row, space-between, center);
		margin-top: 1rem;
		width: 100%;
		
		box-sizing: border-box;

		@media only screen and (max-width: 1160px) {
			padding: 0 1rem;
		}

		.catPush_list_item {
			position: relative;
			cursor: pointer;
			width: calc(98% / 3);
			max-width: 465px;

			


			.infos {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 50%;
				@include wshopFont($primaryFont, 2.188rem, unset, .1rem, 700, $white);
				text-transform: uppercase;
				text-align: center;
			}

			img {
				width: 100%;
			}
		}
	}
}
