.home-module.cover-module .home-module-body {
    .home-columns-container {
        .swiper-slide {
            a {
                .home-module-bg {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.swiper-pagination {
    height: 50px;
    width: 50%;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    @include flexbox(row, center, center);

    .swiper-pagination-bullet {
        width: 40px;
        height: 2px;
        margin: 0 .2rem;
        border-radius: 0;
        background: $white;
        opacity: .5;
        transition: opacity .3s;
        outline: 0;

        &.swiper-pagination-bullet-active {
            background: $white;
            opacity: 1;
        }
    }
}

body.en{
    #locatShop{
        p{  
            &::before{
                content: "";
                background-image: url("../img/france_flag.png");
                position: absolute;
                left: -34px;
                top: 0;
                height: 14px;
                width: 21px;
            }
        }
    }
}

body.fr{
    #locatShop{
        p{  
            &::before{
                content: "";
                background-image: url("../img/pin.png");
                background-repeat:  no-repeat;
                position: absolute;
                background-position: center;
                left: -24px;
                top: -3px;
                height: 20px;
                width: 17px;
                background-size: 56%;
            }
        }
    }
}
#locatShop{
    p{  
        font-weight: 300;
        position:relative;
        font-size: 10px;
        .locaLink{
            text-decoration: underline;
        }
    }
}