.satellite_content {
    text-align: center;

    h3 {
        @include wshopFont($primaryFont, 1.25rem, unset, unset, 700, $primaryColor);
        text-transform: uppercase;
    }

    h4 {
        @include wshopFont($primaryFont, .75rem, unset, unset, 400, $primaryColor);
        text-transform: uppercase;
    }

    h3,
    h4 {
        margin-bottom: .5rem;
    }

    .article {
        margin-bottom: 2.5rem;
    }
}

.groupe{
    display: flex;
    flex-wrap: wrap;
}