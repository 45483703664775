.page_rayon:not(.rayon_lookbook) {
    max-width: 1620px;
    margin: auto;

    .pagination {
        display: none!important;
    }
}

.page_rayon:not(.rayon_lookbook) .items_wrapper {
    width: 100%;
    margin: 2rem auto 0;
    @include flexbox(row, center, flex-start);
    flex-wrap: wrap;

    .item .imgWrapper img {
        width: 100%;
        height: auto;
    }

	.item {

        margin: 0 0 2%;
        width: calc(93.5% / 4);
        max-width: 377px;
        min-height: 474px;
        margin-right: 2%;

        @media only screen and (max-width: 1460px) {
            min-height: auto;
        }

        &:nth-of-type(4n) {
            margin-right: 0;
        }

        /*.imgWrapper {
            margin-bottom: 13px;
        }*/

        .imgWrapper {
            .wrap_rolloverproduit {
                form {
                    .rollover_left {
                        min-width: 55%;
                        margin-right: 5px;
                        .ligne_form {
                            .choices_list {
                                max-height: 72px;
                                padding-top: 3px;
                                padding-left: 3px;
                                overflow-x: visible;
                                overflow: auto;


                                .simplebar-content {
                                    display: flex;
                                    flex-wrap: wrap;
                                }
                            }
                        }
                    }
                }
            }
        }
	}

	&:not(.fourRows), &.threeRows {
        @include flexbox(row, center, flex-start);
        flex-wrap: wrap;
		width: unset;
		margin: 2rem 0 0;

		.item {
            width: calc(96.17% / 3);
            margin: 0 0 1.915%;
            max-width: 517px;
            min-height: 646px;
            margin-right: 1.915%;

            @media only screen and (max-width: 1460px) {
            min-height: auto;
            }

            &:nth-of-type(3n) {
                margin-right: 0;
            }
		}
	}

	.item .imgWrapper img {
		width: 100%;
		height: auto;
	}
}

.category {
    .push,
    .catPush_list {
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        .video_container {
            position: relative;
            display: block;
            width: 100%;
        }

        .bandeau_link {
            width: 100%;
            height: 100%;
        }
    }

    /* Push video */
    #push_rayon_2 {
        .video_container {
            padding-bottom: calc(589 / 470 * 100%);
        }
    }

    #push_rayon_3 {
        .video_container {
            padding-bottom: calc(276 / 1920 * 100%);
        }
    }

    #push_rayon_4 {
        .video_container {
            padding-bottom: calc(789 / 632 * 100%);
        }
    }

    // push rayon desktop 5,6 & 7
    .catPush_list {
        .video_container {
            padding-bottom: calc(523 / 466 * 100%);
        }
    }
}