.category_nav_wrapper .wrap_flitre_pager .filters_left {
	position: absolute;
	top: 40%;
	right: 0;
	width: 10%;

	@media screen and (max-width: 1340px) {
        max-width: 95px;
    }

	.viewChange {
		@include flexbox(row, flex-end, center);
		text-transform: uppercase;

		p {
			@include wshopFont($primaryFont, clamp(7.5px,fs-vw(9.5,1920),9.5px), normal, 0.76px, 800, $primaryColor);
		}

		span {
			@include wshopFont($primaryFont, clamp(10.5px,fs-vw(12.5,1920),12.5px), normal, 1px, 800, $primaryColor);
			cursor: pointer;

			&:not(.actif) {
				opacity: .5;
			}

			&.actif {
				color: $secondaryColor;
			}
		}

		p,
		span {
			margin-left: .5rem;
		}
	}
}
