@import '../components/rayon/rayon_products';
@import '../components/rayon/rayon_filter';
@import '../components/rayon/rayon_filter_meta';
@import '../components/rayon/rayon_filter_views';
@import '../components/rayon/rayon_seotext';
@import '../components/rayon/rayon_cat_push';
@import '../components/rayon/rayon_pagination';
@import '../components/rayon/rayon_search_page';

.page_rayon {
	margin-bottom: 10rem;
}

.category {

	.main_ariane {
		margin: 0 auto;
		padding: 0.5rem 0 0;
	}
}

body.category{
    #scroll_items #list_item .item{
        .imgWrapper{
            margin-bottom: 0;
        }
        .wrapper_description{
            margin-top: 1px;
            margin-left: 2px;
            .product_name{
                p.item_name{
                    font-size: .73rem;
                }
            }
            .wrap_prix_eclat{
                .pricetag{
                    margin-left: 0.5px;
                }
            }
        }
    }
}

#scroll_items {
    &.no_results {
        margin-bottom: 3rem;

        & + .categoryNav {
            display: none;
        }
    }
}