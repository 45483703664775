
.cms_page,
.cms_page_new {
    .historyMainNav,
    .cms_page_content {
        padding: 0 2rem;
    }

    .cms-page-columns-container {
        margin: 7rem;
    }

    .cms-page-module-title span,
    .go_back {
        display: none;
    }

    .insideImg,
    .img_wrapper_column_2 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cms-page-module {
        @media screen and (min-width: 768px) and (max-width: 1366px) {
            &:nth-of-type(2n) {
                .imgWrapper {
                    &:first-of-type {
                        max-width: 51.4vw;
                        width: 100%;
                        margin-left: -9rem !important;
                    }

                    &:nth-of-type(2) {
                        max-width: 46.5vw;
                        width: 100%;
                        margin-right: -9rem;
                        margin-left: 5rem;
                    }
                }
            }
        }

        &:nth-of-type(2) {
            .imgWrapper {
                &:nth-of-type(2) {
                    margin-left: 5rem;
                }

                &:first-of-type {
                    margin-left: -15rem;
                }
            }
        }

        &:nth-of-type(4) {
            .imgWrapper:nth-of-type(2) {
                margin-left: 5rem;
            }
        }

        &.text-image-5 {
            position: relative;
            align-items: center;

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 0.05rem;
                left: 0;
                background-color: $quaternaryColor;
            }

            &::before {
                bottom: 100%;
            }

            &::after {
                top: 100%;
            }

            .leftPart {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }

            .cms-page-module-contents {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;
                padding: 2rem 0;

                a {
                    text-decoration: underline;
                    margin-top: 50px;
                    color: #444;
                }

                a.home-module-cta {
                    font-family: $primaryFont;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: 500;
                }

                .cms-page-module-title:nth-child(2) {
                    font-family: $secondaryFont;
                    font-weight: normal;
                    font-size: 1rem;
                }

                .cms-page-module-title:first-of-type {
                    margin-top: 0;
                    margin-bottom: 5px;
                    font-size: 0.75rem;
                    letter-spacing: 0.15em;

                    span {
                        display: block;
                    }
                }

                span {
                    margin-top: 0;
                    font-size: 0.75rem;
                    display: block;
                    font-family: $primaryFont;
                }

                h1:nth-of-type(2) {
                    display: none;
                }
            }
        }
    }

    .imgWrapper,
    .insideImg {
        img {
            width: 100%;
        }
    }

    .cms-page-column {
        display: flex;
        justify-content: center;
        align-items: center;

        &.column_2 {
            margin: 8rem 0;
        }

        div > span {
            margin-right: 3rem;
        }

        span {
            margin-left: 3rem;
        }

        &.column_2 {
            .cms-page-module-contents {
                display: none;
            }
        }

        .cms-page-module-contents {
            .cms-page-module-title {
                font-family: $primaryFont;
            }

            .cms-page-module-title:first-of-type {
                margin-top: 2rem;
            }

            p {
                text-decoration: underline;
            }
        }
    }

    .cms-page-column.column_2:nth-child(3) {
        .img_wrapper_column_2 {
            .imgWrapper:first-child {
                height: 575px;
                width: 861px;
            }

            .imgWrapper:last-child {
                height: 767px;
                width: 537px;
            }
        }
    }

    #cms_page_ariane,
    #cms_page_btn_back,
    #cms_page_title {
        display: none;
    }

    .cms-page-columns-container {
        margin: 3rem 7rem;
    }

    .cms_page {
        .cms_page_content {
            .text-image-4 {
                .img_wrapper_column_2 {
                    .imgWrapper {
                        & + .imgWrapper {
                            margin-left: 4%;
                        }
                    }
                }
            }
        }
    }
}

.special {
    .signup-module {
        .home_news_middle {
            position: relative;

            .home-txt-bloc {
                position: absolute;
                max-width: 33%;
                max-height: 95%;
                height: auto;
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                right: 10%;
                top: 50%;
                transform: translateY(-50%);
                background-color: #fff;
                padding: 4rem 2.625rem;

                .home-module-title {
                    font-family: $primaryFont;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.1rem;
                    color: $primaryColor !important;
                    margin-bottom: 2rem;

                    .bgSprite {
                        width: 5rem;
                        height: 5rem;
                        margin: 0 auto 34px;

                        &:before {
                            width: 5rem;
                            height: 5rem;
                            transform: scale(1.5);
                            box-sizing: border-box;
                            display: flex;
                        }
                    }

                    span {
                        display: block;
                        font-size: 1.875rem;
                        font-weight: 700;
                        margin-top: 1rem;

                        &:first-child {
                            display: flex;
                        }
                    }

                    @media screen and(max-height:578px) {
                        span {
                            font-size: 1.25rem;
                        }
                    }
                }

                p {
                    font-family: $secondaryFont;
                    font-size: 1rem;
                    margin-bottom: 1rem;
                }
            }

            @media screen and (max-width: 1430px) {
                .home-txt-bloc {
                    max-height: 95%;
                    height: auto;
                    padding: 1rem 2rem;

                    .home-module-title .bgSprite {
                        margin: 0 auto 1.25rem;
                    }
                }
            }

            @media screen and (max-height: 578px) {
                .home-txt-bloc {
                    .home-module-title .bgSprite {
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        .w-newsletter-form .w-form-line .w-input {
            &.w-email-input {
                input[type='email'].w-input-element {
                    height: 3rem;
                }

                &.w-nonempty {
                    input[type='email'].w-input-element + .w-input-label {
                        font-size: 0.8rem;
                        top: 60%;
                    }
                }

                @media screen and(max-height:578px) {
                    &.w-nonempty {
                        input[type='email'].w-input-element + .w-input-label {
                            top: 64%;
                        }
                    }
                }
            }
        }

        .w-newsletter-form .w-form-line.w-submit {
            height: 3rem;
            margin: 0 auto;
            position: relative;
            border: 1px solid $black;

            .w-btn-wrapper {
                height: 100%;
            }

            .w-submit-button {
                border: 0;
                height: 100%;
                width: 100%;
            }
        }

        @media screen and (max-height: 578px) {
            .w-newsletter-form .w-form-line.w-submit,
            .w-newsletter-form
                .w-form-line
                .w-input.w-email-input
                input[type='email'].w-input-element {
                height: 2.5rem;
            }
        }

        .w-input-container {
            border: 1px solid rgba($color: #000000, $alpha: 0.2);
        }

        @media screen and(max-height:578px) {
            .w-newsletter-form {
                .w-form-line:not(.w-submit) {
                    margin-bottom: 1.6rem;
                }
            }
        }
    }
}

/**************************/
/* CMS PRESSE */
/**************************/
.cms_page,
.cms_page_new {
    .satellite_nav_bar {
        position: relative;
        margin-bottom: 2rem;
        padding-bottom: 0.5rem;

        &::after {
            content: '';
            position: absolute;
            height: 0.1rem;
            background-color: $quaternaryColor;
            width: 100%;
            top: 100%;
            left: 0;
        }

        a {
            margin-bottom: 0;
            margin: 0 1rem 2rem;
        }
    }
}

/*********************************************/
/**************   RESPONSIVE   ***************/
/*********************************************/
/* TABLETTES **********/
@media screen and (min-width: 750px) and (max-width: 1024px) {
    .cms_page .cms-page-module.text-image-5 .cms-page-module-contents {
        flex-direction: column;
        align-items: flex-start;
    }

    .cms_page .cms-page-column .cms-page-module-contents {
        p span {
            display: block;
        }
    }
}

#homeSwiper .item a {
    .wrapper_description {
        padding-top: 0.35rem;
    }
}

.home-columns-container {
    position: relative;

    .home-txt-bloc {
        position: absolute;
        top: 15.6%;
        left: 62.75%;
        height: 67.5%;
        width: 24.5%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0 3vw;

        & > div {
            font-size: 0.8vw;
            line-height: 1vw;
        }

        h2 {
            text-align: center;
            font-size: 1.15vw;
        }

        .bgSprite {
            height: auto;
        }

        .bgSprite:before {
            content: '';
            width: 9vw;
            height: 9vw;
        }
    }

    .home-visual {
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }
}
