/******************************************************************************/
/* GENERAL LIGHTBOX STYLES */
/******************************************************************************/
.lightbox {
    display: none;
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: $white;

    .close_pop {
        position: absolute;
        z-index: 5;
        top: 25px;
        right: 25px;
        background-color: transparent;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;

        &:focus {
            outline: none;
        }

        span {
            font-weight: 600;
            font-size: 0;
            color: $iridium;
            background: url(../svg/spritesheet.svg) center center no-repeat;
            @include bgIconCoord(8, 5, 5);
            width: 16px;
            height: 16px;
            display: block;
        }

        &:hover {
            opacity: 0.75;
        }
    }

    .lightbox_content {
        padding: 3em 2em;
    }

    &#lightbox_achat_express {
        align-items: flex-start;
        justify-content: center;
        text-align: left;
        width: 840px;
        height: 598px;

        .flex_container {
            text-align: left;
        }

        #product_addcart {
            position: relative;

            .w-input-note.w-input-error {
                text-align: left;
            }

            .disabledBtnTooltip {
                top: -4rem;
                left: 2.5rem;
            }
        }

        #bloc_add_basket {
            &.form_submit {
                display: flex;
            }
        }

        .main_ariane,
        .learn_more,
        .metaCat {
            display: none;
        }

        .achat_express {
            display: flex;
            justify-content: space-between;

            #productVisu {
                height: 100%;
                width: auto;
            }

            .productRightPart {
                height: 100%;
                max-width: auto !important;
            }

            .product_main_wrapper {
                margin: 0;
                width: 48%;
            }

            .product_info_wrapper {
                width: 50%;
                height: 598px;

                .productDescrWrapper {
                    #wrap_info_np {
                        text-align: left;

                        .eclat_rect {
                            position: absolute;
                            top: 75px;
                            left: 0;
                            right: 0;
                            margin: auto;
                        }

                        div.item_name {
                            display: flex;
                        }

                        .title {
                            flex-direction: column;
                            width: 100%;

                            #itm_name {
                                width: 100%;

                                span {
                                    display: block;
                                }

                                .itm_name {
                                    text-transform: uppercase;
                                    font-family: $primaryFont;
                                    font-size: 0.813rem;
                                    font-weight: 500;
                                    color: $black;
                                    margin-bottom: 0.75rem;
                                }

                                #item_des {
                                    font-family: $primaryFont;
                                    font-size: 1rem;
                                    line-height: unset;
                                    letter-spacing: 0.05rem;
                                    font-weight: 400;
                                    color: #242424;
                                    text-transform: unset;
                                    width: 100%;
                                }
                            }
                        }

                        .bgSprite.addToWishlistButton {
                            display: none;
                        }

                        .item_price_wrapper {
                            margin: 1.3rem 0;

                            .eclat_rond {
                                position: static;
                                margin-right: 1rem;
                                line-height: 2.4rem;
                                order: -1;
                            }

                            .item_price {
                                display: flex;
                                align-items: center;
                                flex-wrap: wrap;

                                .pricetag {
                                    font-size: 1.6rem;
                                    font-weight: 400;
                                    order: 2;

                                    &+.pricetag {
                                        margin-right: 1rem;
                                        order: 1;
                                    }
                                }

                                .pricetag:not(:first-child) {
                                    &::after {
                                        top: 60%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .productRightPart h2,
        .productRightPart .titre_couleur,
        .productRightPart .titre_taille {
            text-align: left;
        }

        .prod_top_wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            #buyNowSwiper {
                width: 400px;
                height: 598px;
                margin: 0;

                img {
                    width: auto;
                    height: 100%;
                }
            }

            .product_info_wrapper {
                position: relative;
                width: calc(100% - 400px);
                height: 598px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 0 40px;

                .productDescrWrapper {
                    width: 100%;
                }

                .product_info {
                    width: 100%;
                    margin-top: 70px;

                    #wrap_info_np {
                        text-align: left;

                        .eclat_rect {
                            position: absolute;
                            top: 75px;
                            left: 0;
                            right: 0;
                            margin: auto;
                        }



                        .item_name {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            h1 {
                                font-family: $secondaryFont;
                                font-size: 2rem;
                                color: $primaryColor;
                                text-transform: uppercase;
                                letter-spacing: 0.2rem;
                                font-weight: bold;

                                span#item_des {
                                    display: block;
                                    text-transform: none;
                                    font-size: 0.9rem;
                                    font-weight: 300;
                                    letter-spacing: 0.05rem;
                                }
                            }
                        }

                        .item_price_wrapper {
                            margin: 1.3rem 0;

                            .eclat_rond {
                                position: static;
                            }

                            .item_price {
                                display: flex;
                                flex-direction: row-reverse;
                                justify-content: center;

                                .pricetag {
                                    font-size: 1.6rem;
                                    font-weight: 600;

                                    &+.pricetag {
                                        margin-right: 1rem;
                                    }
                                }
                            }
                        }
                    }

                    .sizeguide {
                        font-size: 0.75rem;
                        text-decoration: none;
                        color: $primaryColor;
                        position: relative;
                        display: inline-block;
                        margin: 8px 0;

                        &:after {
                            content: '';
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            bottom: 1px;
                            right: 0;
                            background: $primaryColor;
                            opacity: 0.2;
                        }
                    }

                    .flex_container {
                        display: flex;
                        align-items: center;

                        .taunt_text {
                            font-size: 0.75rem;
                            color: $lightgreen;

                            &:before {
                                content: '\02022';
                                margin: 0 10px;
                                font-size: 0.85rem;
                            }
                        }
                    }

                    .date {
                        font-size: 0.75rem;
                        text-align: center;
                        letter-spacing: 0.02rem;
                        margin-top: 2rem;
                    }

                    #product_selects {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;

                        .prod_listes {
                            display: flex;
                            align-items: center;
                            margin-top: 0.7rem;
                        }

                        #ligne_couleur {
                            width: 100%;

                            .titre_couleur {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                font-weight: 400;
                                font-size: 0.68rem;
                                line-height: 10px;
                                letter-spacing: 0.08rem;
                                text-transform: uppercase;
                                color: $primaryColor;
                                min-width: 80px;
                                margin-right: 3%;
                            }

                            .choices_list {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;

                                .form_itm {
                                    &.color {

                                        [type='radio']:checked+label,
                                        [type='radio']:not(:checked)+label {
                                            position: relative;
                                            width: 33px;
                                            height: 33px;
                                            margin: 0 14px 0 0;
                                            border-radius: 50%;
                                            border: 4px solid $white;
                                        }

                                        [type='radio']:checked+label:after,
                                        [type='radio']:not(:checked)+label:after {
                                            content: '';
                                            position: absolute;
                                            top: -4px;
                                            left: -4px;
                                            border-radius: 50%;
                                            width: 31px;
                                            height: 31px;
                                            background: transparent;
                                            opacity: 0;
                                            border: 1px solid $secondaryColor;
                                            transition: opacity 0.3s ease-in-out;
                                        }

                                        [type='radio']:checked+label:after {
                                            opacity: 1;
                                        }

                                        [type='radio']:checked+label:before,
                                        [type='radio']:not(:checked)+label:before {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        #ligne_pointure {
                            width: 100%;

                            .titre_taille {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                font-weight: 400;
                                font-size: 0.68rem;
                                line-height: 10px;
                                letter-spacing: 0.08rem;
                                text-transform: uppercase;
                                color: $primaryColor;
                                min-width: 80px;
                                margin-right: 3%;
                            }

                            .choices_list {
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;
                                flex-wrap: wrap;

                                .form_itm {
                                    &.size {
                                        span {
                                            font-size: 0.8rem;
                                        }

                                        label {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            font-weight: 500;
                                            font-size: 10px;
                                            color: $primaryColor;
                                            text-transform: uppercase;
                                            letter-spacing: 1.5px;
                                            width: 33px;
                                            height: 33px;
                                            margin: 0 14px 0 0;
                                            transition: all 0.3s ease-in-out;
                                        }

                                        [type='radio'] {
                                            +label {
                                                &:before {
                                                    content: none !important;
                                                }

                                                &:after {
                                                    content: '';
                                                    width: 1.3rem;
                                                    height: 1px;
                                                    top: auto;
                                                    bottom: 5px;
                                                    left: auto;
                                                    opacity: 0;
                                                    transition: opacity 0.3s ease-in-out;
                                                }
                                            }

                                            &:checked+label {
                                                &:after {
                                                    opacity: 1;
                                                }

                                                span {
                                                    color: $secondaryColor;
                                                    font-weight: 600;
                                                }
                                            }

                                            &.disabled {
                                                &+label {
                                                    span {
                                                        color: rgba($primaryColor,
                                                                0.5);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .disabledBtnTooltip {
                            top: 100%;
                            font-family: $secondaryFont;
                            font-weight: 300;
                            font-size: 0.68rem;
                            letter-spacing: 1.5px;
                            text-transform: uppercase;
                            border: none;
                            background-color: $tertiaryColor;

                            &:before {
                                border: none;
                                border-width: 0 1px 1px 0;
                                bottom: 79%;
                                background-color: $tertiaryColor;
                            }
                        }
                    }

                    #product_addcart {
                        #bloc_add_basket {
                            width: 100%;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 10px;
                            text-transform: uppercase;
                            text-decoration: none;
                            letter-spacing: 1.5px;
                            margin: 10px 0;
                            max-width: unset;
                        }

                        #bloc_add_alert {
                            margin: 15px 0;

                            .alert_stock {
                                color: $red2;
                                font-size: 0.8rem;
                                text-align: center;

                                span {
                                    display: block;
                                }
                            }

                            .bloc_add_alert_form {
                                margin-top: 10 / 16 * 1rem;
                            }

                            .bloc_add_alert_confirmation,
                            .bloc_add_alert_erreur {
                                margin-top: 20px;

                                p {
                                    font-size: 0.8rem;
                                    color: $primaryColor;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &#lightbox_achat_express_v2 {
        .swiper-container {
            margin: 0;
        }

        .page_look {
            display: flex;
            height: 100vh;
            justify-content: flex-start;
            position: relative;

            .product_main_wrapper {
                width: 34%;
                margin: 0;

                .look {
                    height: 100%;
                    overflow: hidden;
                    background-size: cover !important;
                }

                img {
                    width: auto;
                    height: 100%;
                }
            }

            h2:not(.item_name) {
                position: absolute;
                top: 4%;
                left: 36%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-family: $primaryFont;
                font-size: 1.5rem;
                text-transform: uppercase;

                &:before {
                    content: '';
                    width: 50px;
                    height: 50px;
                    display: flex;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(2, 5, 5);
                }
            }

            .product_right {
                width: 60%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                margin-left: 4%;
            }
        }
    }

    &#zoomBox {
        width: 100%;
        height: 100%;
        transform: none;
        top: 0;
        left: 0;
        background: $white;

        .close_pop {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 11px;
            line-height: 11px;
            letter-spacing: 0.1rem;
            margin-right: 1%;
            color: $primaryColor;

            span {
                margin-left: 12px;
            }
        }

        .thumbs_vis {
            max-width: 115px;
            max-height: 560px;
            position: absolute;
            right: 2%;
            top: 175px;

            .swiper-container {
                .swiper-wrapper {
                    .swiper-slide {
                        height: auto !important;

                        img {
                            width: 100%;
                            height: auto;
                            vertical-align: top;
                        }

                        &.swiper-slide-thumb-active {
                            opacity: 1;
                            position: relative;
                        }

                        &:not(.swiper-slide-thumb-active) {
                            opacity: 0.2;
                        }
                    }
                }
            }
        }

        #zoom_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 1440px;
            height: 100%;
            margin: 0 auto;

            .swiper-slide {
                text-align: center;

                img {
                    position: relative;
                    width: 100%;
                    height: auto;
                }
            }
        }

        .img_large {
            width: 100%;
            height: 100%;
            cursor: url('../img/dezoom_cursor.svg') 13 13, auto;
        }
    }

    &#abox {
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        max-width: 90%;
        width: 646px;
        // height: 288px;
        padding: 62px 100px;
        top: 30%;
        bottom: auto;

        .alert_wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }

        .light_title .txt_alert {
            margin-bottom: 2rem;
            font-size: 1.2rem;

            @media screen and (max-width: 1200px) {
                font-size: 17px;
            }
        }

        .box_wishlist {
            .light_title {
                font-size: 0.88rem;
                text-transform: uppercase;
                font-weight: bold;
                color: $primaryColor;
                letter-spacing: 0.08rem;
            }

            p {
                font-size: 0.88rem;
                letter-spacing: 0.03rem;
                color: $primaryColor;
                margin: 35px;
                line-height: 1.4;
            }
        }

        .wrap_btn_abox {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &#sendfriendbox {
        align-items: flex-start;
        justify-content: center;
        width: 600px;
        height: 800px;
        padding: 45px;
        top: 50%;
        bottom: auto;
        transform: translate(0%, -50%);

        .popup_title {
            font-size: 2.2rem;
            color: $primaryColor;
            text-transform: uppercase;
            letter-spacing: 0.28rem;
            font-weight: bold;
            text-align: center;
            margin-top: 20px;

            span {
                display: block;
                text-transform: initial;
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0.03rem;
            }
        }

        .form_title {
            font-size: 0.75rem;
            color: $primaryColor;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 0.05rem;
            margin: 30px 0 10px 0;
        }

        .w-input-note:not(.w-input-error) {
            color: $grey;
        }

        #sendfriend_form_buttons {
            max-width: 233px;
            margin: 0 auto;
        }
    }

    &#sizeguideBox {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background: $white;
        width: 896px;
        height: 560px;

        .os-scrollbar.os-scrollbar-vertical {
            display: none;
        }

        .header_lightbox {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $primaryFont;
            width: 100%;
            height: 60px;
            font-weight: bold;
            font-size: 1.2rem;
            color: $primaryColor;
            text-transform: uppercase;
            letter-spacing: 0.08rem;
        }

        .header_lightbox {
            padding-top: 2rem;
        }

        .close_pop span {
            margin-top: 0.9rem;
        }

        .taille_content_wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            padding: 0 15px 30px 15px;

            .taille_img_wrapper {
                width: 335px;
                height: 470px;
                position: relative;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;

                    &.hm {
                        z-index: 1;
                    }
                }
            }

            .tablesWrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                flex: 1;
                padding-left: 15px;
                max-height: 470px;
                overflow: hidden;

                #wrap_itable {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: 100%;
                    height: 470px;
                    padding-right: 10%;

                    .tab {
                        display: flex;
                        justify-content: space-between;

                        button {
                            border: none;
                            outline: none;
                            cursor: pointer;
                            text-transform: uppercase;
                            font-size: 13px;
                            color: $grey;
                            font-weight: 500;
                            letter-spacing: 0.05rem;

                            &.active {
                                color: $primaryColor;

                                &:before {
                                    content: ' ';
                                    display: block;
                                    width: 28px;
                                    height: 1px;
                                    position: relative;
                                    bottom: -23px;
                                    background: $primaryColor;
                                }
                            }
                        }
                    }

                    .tabcontent {
                        opacity: 0;
                        height: 0;
                        overflow: hidden;
                        transition: opacity 0.5s ease-in;

                        &.active {
                            opacity: 1;
                            height: auto;
                        }
                    }

                    .sg-container,
                    .sg-inner-container {
                        display: flex;
                        margin-right: -1.8rem;
                    }

                    .os-viewport {
                        display: flex;
                        align-items: center;
                    }

                    .os-content {
                        padding: 0 !important;
                    }

                    .sg-container {
                        justify-content: space-around;
                        margin: 1rem 0 0 -0.8rem;

                        h5,
                        .sg-right,
                        .sg-left {
                            text-transform: uppercase;
                            text-align: center;
                            padding-right: 5rem;
                            margin: 1rem 0 2rem 1.5rem;
                            font-weight: bold;
                            line-height: 3;
                            font-size: 13.34px;
                        }

                        p {
                            font-size: 0.8rem;
                            font-weight: 500;
                            padding-right: 0;
                            text-transform: none;
                        }

                        h5,
                        p,
                        div {
                            font-family: $primaryFont;
                            line-height: 3;
                            padding-right: 0;
                            text-transform: none;
                        }

                        .sg-details {
                            margin: 1rem 1.5rem 0;
                            text-align: center;
                            padding-right: 0;
                            text-transform: none;

                            div {
                                font-size: 0.8rem;
                                color: $grey4;
                                font-weight: normal;
                            }
                        }
                    }

                    .sg-intro {
                        font-size: 0.75rem;
                        color: $grey;
                        font-weight: 300;
                        line-height: 1.4;
                    }

                    .sg-title {
                        font-size: 0.8rem;
                        color: $secondaryColor;
                        text-transform: uppercase;
                        font-weight: bold;
                        margin-top: 27px;
                        margin-bottom: 5px;
                        letter-spacing: 0.07rem;
                    }

                    .sg-text {
                        font-size: 0.8rem;
                        color: $primaryColor;
                        letter-spacing: 0.03rem;
                        line-height: 1.4;
                    }

                    .sg-flex {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 27px;

                        .sg-mensurations-img {
                            width: 17%;

                            img {
                                width: 100%;
                                height: auto;
                            }
                        }

                        .sg-text {
                            width: 75%;
                            font-size: 0.75rem;
                        }

                        h4 {
                            font-size: 0.75rem;
                            font-weight: 600;
                            text-transform: uppercase;

                            span {
                                color: $secondaryColor;
                            }
                        }
                    }

                    .sg-table {
                        width: 100%;
                        text-align: center;
                        border-collapse: collapse;
                        margin-top: 20px;

                        thead {
                            background: $tertiaryColor;

                            th {
                                font-weight: 600;
                            }
                        }

                        tr {
                            height: 24px;
                        }

                        th:first-of-type,
                        td:first-of-type {
                            text-align: left;
                            padding-left: 5px;
                        }
                    }

                    table,
                    th,
                    td {
                        border: 1px solid $tertiaryColor;
                        font-size: 0.65rem;
                    }

                    /*Scrollbar*/
                    .os-scrollbar {
                        padding: 0;

                        .os-scrollbar-track {
                            position: relative;

                            &:before {
                                content: ' ';
                                display: block;
                                background: $subMenu;
                                width: 1px;
                                height: 100%;
                                position: absolute;
                                right: 4px;
                                top: 0;
                            }

                            .os-scrollbar-handle {
                                background: $grey;
                                min-height: 10px !important;
                                height: 10px !important;

                                &:after {
                                    content: ' ';
                                    display: block;
                                    background: $grey;
                                    width: 1px;
                                    height: 600px;
                                    position: absolute;
                                    left: 50%;
                                    bottom: 0;
                                }
                            }
                        }
                    }

                    @media all and (-ms-high-contrast: none) {
                        .os-scrollbar-vertical {
                            right: 1px;
                        }

                        .os-host-resize-disabled.os-host-scrollbar-horizontal-hidden>.os-scrollbar-vertical {
                            bottom: 1px;
                        }
                    }

                    @supports (-ms-ime-align: auto) {
                        .os-scrollbar-vertical {
                            right: 1px;
                            bottom: 1px !important;
                        }
                    }

                    /*End Scrollbar*/
                }
            }
        }
    }

    &#modboxpromo {
        width: 646px;
        height: 288px;
        text-align: center;
        padding: 62px 100px;

        .texte {
            font-size: 0.88rem;
            color: $primaryColor;
            letter-spacing: 0.03rem;
            margin: 35px;
            line-height: 1.4;
        }

        .form_submit {
            margin: 0 auto;
        }

        a.button {
            display: block;
            margin: 0 auto;
            line-height: 4;
        }
    }

    .g-recaptcha {
        margin-bottom: 31px;
    }

    &#popup_savoir_plus {
        text-align: center;
        width: 646px;
        height: 288px;
        padding: 62px 100px;
        top: 30%;
        bottom: auto;

        .title {
            text-transform: uppercase;
            font-family: $primaryFont;
            font-weight: 600;
            font-size: 0.9rem;
            letter-spacing: 0.7px;
            margin-bottom: 1rem;
        }

        .close:after {
            content: '\00d7';
            right: 5%;
            top: 5%;
            font-size: 1.7rem;
            position: absolute;
            cursor: pointer;
            color: #252525;
        }
    }
}

/* safari only */
form.email-form:not(:root:root) {
    input::-webkit-input-placeholder {
        line-height: normal !important;
    }
}

/*****************************************************************************/
/* LIGHTBOX RETOUR */
/*****************************************************************************/
#popup_numero_retour {
    width: 800px;
    padding: 2rem 8rem;
    margin: 2rem auto;

    .texte_num_retour {
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: 700;
        color: $primaryColor;
        letter-spacing: 0.08rem;
        text-align: center;
    }

    .recommandation {
        font-size: 0.89rem;
        line-height: 1.6;
        text-align: center;
        letter-spacing: 0.02rem;
        margin: 1rem 0 2rem;
    }

    #return_products_form {
        max-height: 68%;
        overflow: auto;

        .lb-v-scrollbar-slider {
            background: $secondaryColor;
        }

        .productReturnListWrapper {
            margin: 0 1rem;

            &:not(:first-of-type) .product_container {
                margin-top: 3rem;
            }

            .product_container {
                display: flex;
                border: 1px solid $darkred;
                position: relative;
                padding: 1.7rem;
                margin-bottom: 1rem;

                .imgWrapper {
                    width: 80px;
                    margin-right: 1.7rem;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .title {
                    color: $primaryColor;
                    letter-spacing: 0.03rem;
                    line-height: 1.4;
                    margin-bottom: 1.7rem;
                    max-width: 90%;

                    strong {
                        text-transform: uppercase;
                        letter-spacing: 0.13rem;
                        font-size: 1rem;
                    }

                    span {
                        font-size: 0.8rem;
                    }
                }

                .sub_couleur,
                .sub_taille {
                    color: $grey;
                    font-weight: 200;
                    letter-spacing: 0.08rem;
                    font-size: 0.8rem;
                }

                .pricetag {
                    position: absolute;
                    top: 1.7rem;
                    right: 1.7rem;
                }
            }

            .selectWrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 25px;

                .date_select {
                    margin: 0;
                    display: flex;
                    width: 23%;
                    align-items: center;

                    &+.date_select {
                        width: auto;
                    }

                    label {
                        color: $primaryColor;
                        font-weight: 200;
                        letter-spacing: 0.05rem;
                        font-size: 0.75rem;
                        white-space: nowrap;
                        display: flex;
                        align-items: center;
                        margin-right: 0.7rem;
                    }

                    select {
                        width: 100%;
                        height: 100%;
                        border: none;
                        border-bottom: 1px solid $grey;
                        color: $grey;
                        padding: 0;
                        background-position: 98% center;
                        outline: none;
                    }
                }
            }
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: $borderColor;
        }

        &::-webkit-scrollbar-thumb {
            background: $grey2;
            border-radius: 4px;
        }
    }

    .btn_container {
        width: 200px;
        margin: auto;
        margin-top: 2rem;

        a.button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.print-return {
    margin-top: 2em;
    padding: 2em;
    border: 1px solid $grey;

    .content {
        margin-bottom: 0.8em;
        line-height: 1.2em;

        .adresse {
            margin-top: 0.6em;
        }
    }

    .btn_container {
        margin-bottom: 1.2em;
    }
}

@import './lightbox_overlay';

#sendwishlistbox {
    position: fixed;
    z-index: 100;
    left: 50%;
    right: auto;
    top: 50%;
    bottom: auto;
    background: $white;
    max-width: 80%;
    width: 660px;
    transform: translate(-50%, -50%);

    .close_pop {
        span {
            background: url(../svg/spritesheet.svg) center center no-repeat !important;
            background-size: 500% auto !important;
            background-position: 50% 25% !important;
        }

        .cross {
            width: 20px;
            height: 20px;
            display: flex;
            position: relative;
            background-position: 50% 25%;
        }
    }

    #content_sendwishlistbox {
        padding: 3.375rem 4.375rem;
        display: flex;
        flex-direction: column;

        .formContent {
            width: 100%;
            padding-top: 0.5rem;

            .form_title {
                text-transform: uppercase;
                margin: 0.5rem 0;
                font-size: 0.8125rem;
                letter-spacing: 0.16rem;
            }
        }

        .popup_title {
            .title {
                font-family: $primaryFont;
                color: $primaryColor;
                display: flex;
                justify-content: center;
                font-weight: 700;
                font-size: 1rem;
                letter-spacing: 0.05rem;
                text-transform: uppercase;
                margin: 1rem;
            }

            .subtitle {
                display: flex;
                justify-content: center;
                font-size: 1rem;
                font-weight: 400;
                font-family: $secondaryFont;
            }
        }

        .w-input-note,
        .plusieur_add {
            font-family: $primaryFont;
            opacity: 0.8;
            font-size: 0.75rem;
        }

        .w-input-element,
        textarea {
            &::placeholder {
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 400;
                font-size: 0.75rem;
                letter-spacing: 0.25px;
                color: $purplegrey;
                font-style: italic;
            }
        }

        #sendfriend_alert_mail {
            margin: 0 auto;
            padding-top: 1rem;
        }

        #sendfriend_form_froms {
            padding-top: 1.5rem;

            #title_de_la_part {
                padding-bottom: 1rem;
            }

            .w-checkbox-input .w-input-label {
                font-style: normal;
            }

            .plusieur_add {
                opacity: 1 !important;
                display: flex;
                align-items: center;
                margin: 1rem 0;

                p {
                    margin-left: 0.5rem;
                }
            }
        }

        .g-recaptcha {
            margin-bottom: 30px;
        }

        .w-submit {
            width: 250px;
            margin: 0 auto;
        }

        #wrapper_sendwishlist {
            margin-top: 2.125rem;

            #sendfriend_form_to {
                #title_destinataire {
                    padding-bottom: 1rem;
                }

                .w-textarea {
                    textarea#sendWishListToMessage {
                        overflow-y: auto;
                    }
                }
            }

            input[type='text'],
            input[type='password'],
            input[type='email'],
            textarea {
                border: 1px solid $borderColor;

                &.inputErr {
                    border-color: $red2;
                }
            }

            .form_title {
                font-family: $primaryFont;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 11px;
            }
        }

        @media screen and (max-height: 578px) {

            #sendfriend_form_froms,
            #sendfriend_form_to {
                padding-top: 0.5rem;

                tr td {
                    height: 2rem;

                    & input[type='text'].w-input-element {
                        height: 2rem;
                    }
                }
            }

            #wrapper_sendwishlist {
                margin-top: 0.5rem;

                .form_title {
                    height: 1.5rem;
                }
            }
        }
    }

    @media screen and (max-height: 578px) {
        #content_sendwishlistbox {
            padding: 6.5rem 4.375rem 3.375rem;

            .popup_title {
                margin: 1rem 0.5rem 0.5rem;
            }
        }
    }

    .g-recaptcha {
        display: flex;
        justify-content: center;
    }

    #sendFriendCopy {
        -webkit-appearance: none;
        display: block;
        width: 16px;
        height: 16px;
        box-sizing: border-box;
        box-shadow: inset 0 0 0 1px $white;
        transition: all 0.2s ease-in-out;
        outline: 0;
        border: 1px solid $grey2;
        background-color: $white;

        &:checked {
            background-color: $secondaryColor;
        }
    }
}

@media screen and (max-height: 578px) {
    #sendwishlistbox>.close_pop {
        top: 7.4rem;
    }
}

/**
 * Tunnel pré-commande
 */
#lightbox_preco {
    width: 656px;
    height: 580px;
    text-align: center;

    .t_lightbox_preco_title {
        font-family: $primaryFont;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.08rem;
        margin-top: 50px;
    }

    .t_lightbox_preco {
        font-family: $secondaryFont;
        font-size: 16px;
        line-height: 1.2;
        margin: 10px auto 40px;
    }

    .bloc_lightbox_preco {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 532px;
        padding: 35px 0 25px;
        border: 1px solid $borderColor;
        margin: 0 auto;
        font-family: $primaryFont;
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.08rem;
        cursor: pointer;

        .ico {
            display: block;
            position: absolute;
            left: -7px;
            top: 50%;
            transform: translateY(-50%);
            width: 15px;
            height: 15px;
            border: 1px solid $borderColor;
            border-radius: 50%;
            background-color: $secondaryColor;
            box-shadow: inset 0 0 0 10px $white;
        }

        &.actif,
        &:hover {
            border: 1px solid $primaryColor;

            .ico {
                border: 1px solid $primaryColor;
                box-shadow: inset 0 0 0 2px $white;
            }
        }

        &.once {
            margin: 0 auto 52px;
        }

        label {
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 335px;
            height: 30px;
            background-color: $white;
            font-family: $primaryFont;
            font-size: 14px;
            letter-spacing: 0.08rem;
        }

        span {
            font-family: $secondaryFont;
            font-size: 15px;
            font-weight: 400;
            text-transform: initial;
            letter-spacing: initial;
            margin: 8px 0 0 0;

            &#ecoChoice {
                margin: -20px 0 20px;
            }
        }
    }

    .btn_container {
        margin-top: 30px;
    }
}

#availabilityInStore {
    z-index: 101;
    position: absolute;
    top: 55px;
    left: 50%;
    right: auto;
    bottom: auto;
    background-color: $white;
    transform: translateX(-50%);
    width: 694px;
    overflow: hidden;

    .close_pop {
        font-size: 0;
        background: url('../img/close_btn.png');
        background-repeat: no-repeat;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        background-position: center;
        top: 18px;
        right: 18px;
    }

    #stepStoreList {
        padding: 40px 60px 60px 60px;

        #storeList {
            #storeListScrollWrapper {
                .os-scrollbar.os-scrollbar-vertical {
                    margin-right: 10px;

                    .os-scrollbar-track.os-scrollbar-track-off {
                        width: 4px;
                        background-color: $lightgrey3;
                        border-radius: 0;

                        .os-scrollbar-handle {
                            width: 4px;
                            margin: 0 auto;
                            left: 0;
                            right: 0;
                            background-color: $grey7;
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }

    .availabilityInStoreContent {
        overflow-y: auto;
    }

    .availabilityInStoreTitle {
        font-family: $fourthFont;
        font-size: 25px;
        letter-spacing: 0.03em;
        line-height: 16px;
        color: $primaryColor;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
    }

    .availabilityInStoreSubtitle {
        text-align: center;
        font-family: $secondaryFont;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.1em;
        margin-top: 0.65rem;
    }

    .availabilityInStoreDescription {
        margin: 1.2rem 1.1rem 0.75rem;
        line-height: 1.45;
        font-size: 1rem;
        font-family: $secondaryFont;
        font-weight: 400;
        color: $black;
        text-align: center;
    }

    .store_locator {
        height: 90%;

        .country_select {
            width: 25%;
        }

        .formWrapper {
            display: flex;
            align-items: flex-end;
            margin-bottom: 0.75rem;
        }

        #paysIso {
            cursor: pointer;
            border: none;
            outline: none;
            box-shadow: none;
            background-color: transparent;
            padding: 0.5rem 0.5rem 0.5rem 0;
        }

        .field_address {
            display: flex;

            input {
                width: 100%;
                padding: 2% 0;
                background-color: transparent;
                border: none;
                outline: none;
                font-family: $secondaryFont;
                font-size: 1rem;
            }

            button {
                padding-left: 1rem;
                background-color: transparent;
                font-family: $secondaryFont;
                font-size: 1rem;
                border: none;
                outline: none;
                display: flex;
                align-items: center;
                font-size: 0;

                &::after {
                    content: '';
                    display: block;
                    width: 1.7rem;
                    height: 1.7rem;
                    margin-right: 0.5rem;
                    background-image: url('../svg/spritesheet.svg');
                    background-repeat: no-repeat;
                    @include bgIconCoord(19, 5, 5);
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }

        #paysIso,
        .field_address {
            border: 1px solid $borderColor;
            width: 98%;
            height: 47px;
        }

        #searchAddress,
        #paysIso {
            padding-left: 10px;
        }

        #paysIso,
        .field_address input::placeholder {
            color: $grey7;
            font-style: normal;
            font-weight: 300;
            line-height: 18px;
            font-family: $secondaryFont;
            font-size: 1rem;
        }
    }

    .liste_recherche_distributeur {
        .liste_distributeur {
            height: 35.5rem;
            overflow: auto;

            .liste_distributeur_scrollbar {
                display: block;
                height: 100%;
                width: 98%;
            }

            .elem_liste_distributeur {
                border: 1px solid $borderColor;
                width: 97%;
                font-family: $secondaryFont;
                padding: 18px 20px 0 20px;

                a {
                    text-decoration: none;
                }

                &:not(:first-of-type) {
                    margin-top: 0.75em;
                }

                li {
                    line-height: 1.6;
                }
            }
        }

        .elem_list_contents {
            margin-bottom: 1.45rem;
            padding-right: 1rem;
        }

        .store_name {
            span.distance {
                color: $black;
                display: contents;
                text-transform: lowercase;
                font-weight: 600;
                display: none;


                &::before {
                    content: ' • ';
                    color: $iridium;
                }
            }

            small {
                display: none;
            }
        }

        .store_address {
            line-height: 1.3;
            margin-top: 6px;

            >span {
                display: block;

                >span:nth-of-type(2) {
                    display: contents;
                }
            }
        }

        .see_map {
            display: none;
        }

        .store_phone {
            font-family: $secondaryFont;
            position: relative;
            margin-top: -4px;
            margin-bottom: 0.3rem;
            font-size: 16px;
            display: flex;

            span {
                display: block !important;
            }

            a {
                margin-left: 5px;
                font-weight: 500;
            }
        }

        .store_open_hours {
            display: block !important;
            font-family: $primaryFont;

            span {
                font-size: 14px;

                &:before {
                    content: '';
                    line-height: 0;
                    left: -15px;
                    display: inline-block;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    margin-right: 5px;
                    align-self: center;
                    margin-bottom: 1px;
                }

                &.txt_store_open:before {
                    background-color: $green;
                }

                &.txt_store_closed:before {
                    background-color: $red;
                }
            }

            span:nth-child(2) {
                font-family: $secondaryFont;
                font-size: 14px;
                letter-spacing: 0.037em;
                margin-left: -0.67rem;
                font-weight: 500;
            }

            .txt_store_open {
                color: $lightgreen;
                font-family: $fourthFont;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 0.04em;
            }

            .txt_store_closed {
                color: $red;
                font-family: $fourthFont;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 0.04em;
            }
        }

        .store_name_wrapper {
            display: flex;
            justify-content: space-between;

            .title {
                font-family: $fourthFont;
                font-size: 0.9rem;
                line-height: 1.3;
                text-transform: uppercase;
                color: $black;
                margin: 0 0 3px;
                font-weight: 600;
                letter-spacing: 1px;
            }

            .storeStock {
                position: absolute;
                font-family: $secondaryFont;
                display: flex;
                font-weight: 500;
                margin-left: 66%;
                margin-top: 10%;

                &:before {
                    content: '';
                    line-height: 0;
                    left: -15px;
                    display: inline-block;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    margin-right: 10px;
                    align-self: center;
                    margin-bottom: 1px;
                }

                &.high:before {
                    background-color: #93df93;
                }

                &.medium:before {
                    background-color: orange;
                }

                &.low:before {
                    background-color: $red;
                }
            }
        }

        .store_details {
            position: relative;
        }

        .link_store_wrapper {
            .show_schedule {
                font-size: 13px;
                width: fit-content;
                font-weight: 400;
                margin-top: 0.10rem;
                color: grey;
                font-family: $fourthFont;
                cursor: pointer;
            }
        }

        .store_week_schedule {
            display: none;
            margin-top: 0.3rem;

            &.actif {
                display: block;
            }
        }
    }

    .show_schedule,
    .choice_store {
        border-bottom: 1px solid grey;
        line-height: initial;
        color: $iridium;
    }

    .store_week_schedule p,
    .store_phone,
    .store_address {
        font-family: $secondaryFont;
    }

    .close_pop {
        cursor: pointer;
    }

    #availabilityInStoreConfirmation {
        padding: 60px;

        .availabilityInStoreTitle {
            margin-bottom: 3rem;
        }

        .availabilityInStoreDescription {
            border: 1px solid $borderColor;
            padding: 1rem;
            font-weight: 300;
        }
    }

    .bgSprite {
        height: auto;

        &.bfr:before {
            content: none;
        }
    }
}

/*  POP-IN PLUSIEURS TAILLES PRODUIT, PANIER*/
#cartSizeInfoLightbox {
    width: 658px;
    padding-top: 50px;
    height: 340px;
    margin: auto;

    .close_pop {
        right: 25px !important;
    }

    .content {
        margin: 5px 30px 0 30px;

        .title {
            font-family: $secondaryFont;
            text-transform: uppercase;
            font-size: 17px;
            letter-spacing: 1.8px;
            margin-bottom: 15px;
            font-weight: 600;
        }

        p {
            text-align: center;
            font-size: 13px;
            letter-spacing: 0.4px;
            line-height: 20px;
            font-family: $primaryFont;
        }

        p:nth-child(3) {
            margin-top: 20px;
        }

        .form_submit {
            position: relative;
            display: flex;
            justify-content: center;

            .button.dark {
                width: auto;
                color: $white;
                background-color: $black;
                padding: 0 16px;
                margin-top: 30px;
            }

            .loader {
                position: absolute;
                left: calc(50% - 245px/2);
                top: 28px;
                width: 36%;
                height: 100%;
                background-color: $white;
                background-size: 25% !important;
            }
        }
    }
}

body.en {
    #cartSizeInfoLightbox {
        height: 317px;
    }
}