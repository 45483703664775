@mixin flexbox($direction, $justify, $align) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

/***** Media query(ies) *****/

@mixin medium {
    @media screen and (max-width: 1480px) {
        @content;
    }
}

@mixin extraLargeScreen {
    @media screen and (min-width: 2500px) {
        @content;
    }
}

@mixin asterisk($size) {
    content: "*";
    position: absolute;
    top: 0;
    right: -$size;
    width: $size;
    height: $size;
    display: block;
}
  