.wrapper_description,
.wrapper_infos {
    width: 100%;
    /**@ IE10+  */
    display: -ms-grid;
    -ms-grid-columns: 70% 30%;
    -ms-grid-rows: auto auto auto;
    display: grid;
    grid-template-columns: auto 128px;
    grid-template-rows: auto auto auto;
    position: relative;
    z-index: -1;

    /* ECLAT */
    .eclat_rect {
        overflow: hidden;
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-row: 1;
        grid-row: 1;
        line-height: 1;
    }

    /* PRODUCT NAME(S) */
    p.item_name {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        grid-column: 1;
        grid-row: 1;
        @include wshopFont($primaryFont, .813rem, unset, unset, 500, $primaryColor);
        text-transform: uppercase;

        &.subtitle {
            margin-bottom: .2rem;
            text-transform: none;
        }

        .subtitle {
            text-transform: none;
        }

        .subtitle,
        .item_des {
            display: none;
        }
    }

    /* PRODUCT SHORT NAME */
    .item_subtitle {
        display: none;
    }

    /* PRODUCT PRICES */
    .wrap_prix_eclat,
    .wrapper_price,
    .item_price {
        -ms-grid-column: 1 / 3;
        -ms-grid-row: 2;
        display: -ms-grid;
        grid-column: 1 / 3;
        grid-row: 2;
        display: flex;

        .item_price {
            display: flex;
        }

        .pricetag {
            display: flex;
            align-items: center;
        }

        .pricetag:first-child {
            margin-right: .5rem;
        }

        .pricetag,
        .pricetag span {
            @include wshopFont($primaryFont, .75rem, 1.5, unset, 400, $primaryColor);
        }

        .pricetag:nth-child(2),
        .pricetag:nth-child(2) span {
            @include wshopFont($primaryFont, .7rem, 1.5, unset, 400, $tertiaryColor);

            &::after {
                margin-top: -1.5px;
                background-color: $tertiaryColor;
            }
        }
    }

    .wrap_prix_eclat .item_price {
        align-items: flex-end;
    }


    /* PRODCUCT COLORS */
    .item_color {
        grid-column: 1 / 3;
        grid-row: 3;
        margin: .5rem 0 0;
        @include flexbox(row, flex-start, unset);
        flex-wrap: wrap;

        .form_itm {
            position: relative;
            width: 10px;
            height: 10px;
            margin-bottom: .5rem;

            &:not(:last-child) {
                margin-right: 1rem;
            }

            .input_check {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: -1;
            }

            label {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 50%;
            }

            form {
                display: contents;
            }
        }
    }
}