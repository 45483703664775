// COOKIES
#cookies_block_footer {
    z-index: 20;
    position: fixed;
    bottom: 8%;
    right: 2%;
    max-width: 345px;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(white,.9);

    .cookies_txt {
        font-size: 11px;
        font-family: $primaryFont;
        font-weight: 500;
        text-align: center;
        line-height: 2;

    }

    .cookies_btn {
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 5%;
    }
}

.locatShop {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 60px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-family: $primaryFont;
    font-weight: 500;
    z-index: 20;

    .closebtn {
        cursor: pointer;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 117px;
        transform: translateY(-50%);
        color: transparent;

        &::before{
            position: absolute;
        }
    }

    .locaLink {
        padding-left: .2rem;
    }
}


.wrap_reassurance {
    background-color: #fff;
    width: 100%;

    .container_reassurance {
        width: 100%;
        max-width: 1620px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 160px;

        .reassurance {
            display: flex;
            flex-direction: column;
            width: 25%;
            align-items: center;
            font-family: $primaryFont;
            text-transform: uppercase;
            font-size: 13px;

            .title {
                font-weight: bold;
                margin-bottom: 10px;
                
            }
            .txt {
                font-weight: 400;
                
            }
        }
    }
}

#container_footer {
    background-color: #eeeeee;
    width: 100%;
    display: flex;
    align-items: center;
    font-family: $primaryFont;

    .wrap_links {
        display: flex;
        width: 100%;
        max-width: 1620px;
        margin: 0 auto;
        height: 100%;
        align-items: flex-start;
        justify-content: space-between;
        padding: 50px;

        .footer_logo {
            padding-top: 12px;
            
            .logo_footer {
                width: 125px;
            }
        }

        .footer_nav {
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            width: 52%;
            height: 100%;

            .col {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .col_title {
                    font-weight: bold;
                    font-size: 12px;
                    margin-bottom: 20px;
                    text-transform: uppercase;
                }

                .col_links {
                    text-decoration: none;
                    font-weight: 400;
                    font-size: 12px;

                    &+.col_links {
                        margin-top: 10px;
                    }
                }
            }
        }

        .footer_nl {
            width: 32%;
            display: flex;
            align-items: flex-start;
            height: 100%;

            .col {
                width: 100%;

                .news_container {
                    max-width: 400px;
                    .common_txt{
                        font-size: 20px;
                        text-transform: uppercase;
                        margin-bottom: 14px;
                        letter-spacing: .1rem;
                        font-weight: bold;
                        display: block;
                    }

                    .sub_title {
                        font-family: $secondaryFont;
                        font-size: 15px;
                        margin-bottom: 24px;
                        display: block;
                        line-height: 1.2;
                    }

                    .w-newsletter-form {
                        .w-form-line {
                            .w-input-note {
                                text-align: left;
                            }
                        }
                    }
                   
                }
            }

            .w-newsletter-form .w-input-element:not([type=radio]):not([type=checkbox]), .w-newsletter-form input[type=email].w-input-element,.w-newsletter-form .w-form-line.w-submit, .w-newsletter-form .w-form-line.w-submit .w-submit-button {
                height: 45px;
            }
        }
        
    }

}

.wrap_copy {
    background-color: #eeeeee;
    width: 100%;
    height: 50px;
    margin-top: .1rem;
    .copyr {
        padding: 0 2rem;
        display: flex;
        justify-content: space-between;
        max-width: 1620px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        align-items: center;
        font-family: $primaryFont;
        text-transform: uppercase;

        .switchLang {
            display: flex;
            font-weight: 500;
            font-size: 11px;
            div {
                margin: 0 .5rem;
            }
            .lang_itm  {
                text-decoration: none;
            }

            .active {
                font-weight: 700;
            }

        }

        .wshop {
            text-transform: uppercase;
            font-size: 10px;
            font-family: $primaryFont;
            font-weight: 500;
            display: flex;
            align-items: center;

            img {
                max-height: 24px;
                width: auto;
                margin-left: 1rem;
            }
        }

        .socialRow {
            display: flex;
            align-items: center;
            font-family: $primaryFont;
            color: $primaryColor;
            font-weight: 500;
            font-size: 10px;
            text-transform: uppercase;
            width: 60%;
            justify-content: center;
            a {
                text-decoration: none;
            }
    
            span {
                margin-left: .5rem;
            }
        }
    }
}


/*********************************************/
/******   RESPONSIVE   *********/
/*********************************************/

/* TABLETTES **********/
@media screen and (min-width: 750px) and (max-width: 1024px) {
    #container_footer .wrap_links {
        .footer_nav .col {
            padding-left: 1rem;

            &:first-child {
                padding-left: 2rem;
                padding-right: 2rem;
            }

            .col_links {
                font-size: 10px;
            }
        }

        .footer_logo {
            max-width: 15%;
            .col {
                max-width: 100%;
                a {
                    max-width: 100%;
                    display: block;
                    
                    img {
                        max-width: 100%;
                    }
                }
            }
            
        }

        .footer_nl {
            .col .news_container .sub_title {
                font-size: 13px;
            }
        }
    } 
}

#cookiesLightbox {
    font-family: $primaryFont;

    .cookiesLightbox_container {
        display: grid;
        max-width: 990px;
        margin: auto;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(4, auto);

        .cookiesLightbox_content {
            grid-column: 1 / 6;
            grid-row: 1;
            max-width: none;

            a {
                text-underline-position: unset;
            }
        }
        
        .cookiesLightbox_preferences {
            grid-column: 1 / 6;
            grid-row: 3;
            margin: 0;

            ul.cookiesInfos {
                #cookiePref02,
                #cookiePref03 {
                    .w-input-container {
                        .w-input {
                            .w-input-label {
                                .h4_style {
                                    .icon {
                                        display: inline-block;
                                        mask-image: url('../svg/spritesheet-conf.svg');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .cookiesLightbox_custom_btn {
            grid-column: 1;
            grid-row: 2;
            margin-left: 0;
            background-color: $white;
            color: $black;
            
            .w-submit-button {
                border: none;
                text-transform: none;
                font-weight: normal;
                font-size: 13px;
                margin-top: 0;
                background-color: $white;
                color: $black;

                &:hover {
                    background-color: transparent;
                    color: $black;
                }

                &::after {
                    display: none;
                }

                &.w-loader {
                    display: none;
                }
            }
        }

        .cookiesLightbox_refuse_btn {
            grid-column: 4;
            grid-row: 4;
            margin-left: 0;
        }

        .cookiesLightbox_accept_btn {
            grid-column: 5;
            grid-row: 4;
            margin-left: 1.25rem;
        }
    }

    #js_cookie_refuse {
        &+.w-loader {
            background-image: unset;
            background-color: #fff;

            span {
                display: inline-grid;
                font-size: 0;
                margin-top: 0.5rem;
                
                &:before {
                    content: "";
                    mask-image: url(../svg/three_dots.svg);
                    background-color: #000;
                    mask-repeat: no-repeat;
                    mask-size: 75%;
                    width: 3.5rem;
                    height: 1rem;
                    mask-position: center;
                }
            }
        }
    }

    #js_cookie_accept {
        &:not(:hover), +.w-loader {
            background-color: #000;
            color: #fff;
        }

        &:hover {
            background-color: #fff;
            color: #000;
        }
    }
}