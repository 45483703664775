.rayon_lookbook {

    .items_wrapper {
        width: 100%;
        @include flexbox(row, space-between, unset);
        flex-wrap: wrap;
    }

    .item.lookbook {
        width: 46.5vw;
        margin: 30px 0;

        &:nth-child(10n+4),
        &:nth-child(10n+10) {
            width: 51.5vw;
        }

        &:nth-child(10),
        &:nth-child(30) {
            width: 46.5vw;
        }

        &:nth-child(5n+2) {
            width: 5vw;
        }

        /***** STYLES *****/

        .imgWrapper {
            width: 100%;
            height: 100%;
        }

        /* IMAGES AROUND ECLAT TEXT */
        &:nth-child(5n+1) .imgWrapper,
        &:nth-child(5n+3) .imgWrapper {
            img {
                width: auto;
                max-width: 27.875rem;
                height: 100%;
                object-fit: contain;
            }
        }

        &:nth-child(5n+1) .imgWrapper {
            @include flexbox(row, flex-end, unset);
        }

        &:nth-child(5n+3) .imgWrapper {
            @include flexbox(row, flex-start, unset);
        }

        /* ECLAT TEXT */
        &:nth-child(5n+2) .imgWrapper {
            @include flexbox(row, center, unset);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        /* IMAGE PAYSAGE */
        &:nth-child(10n+4) .imgWrapper {
            @include flexbox(row, unset, center);

            img {
                width: 100%;
            }
        }

        &:nth-child(4) .imgWrapper {
            width: 46.5vw;
            @include flexbox(row, right, center);

            img {
                max-width: 654px;
                width: 100%;
                height: auto;
            }
        }

        &:nth-child(9) .imgWrapper {
            @include flexbox(row, right, center);
            justify-content: right!important;
        }

        &:nth-child(14) .imgWrapper {
            width: 46.5vw;
            @include flexbox(row, right, center);

            img {
                max-width: 654px;
                width: 100%;
                height: auto;
            }
        }

        &:nth-child(24) .imgWrapper {
            width: 46.5vw;
            @include flexbox(row, right, center);

            img {
                max-width: 654px;
                width: 100%;
                height: auto;
            }
        }

        /* IMAGE PORTRAIT BIG */
        &:nth-child(10n+10) .imgWrapper {
            @include flexbox(row, flex-start, unset);

            img {
                width: auto;
                height: 100%;
            }
        }

        &:nth-child(10) .imgWrapper {
            @include flexbox(row, left, unset);

            img {
                height: 970px;
                width: 654px;
                min-width: 39rem;
                object-fit: contain;

                @media only screen and (width: 1024px) {
                    height: 699px;
                    width: 471px;
                }
            }
        }

        &:nth-child(30) .imgWrapper {
            @include flexbox(row, flex-start, unset);

            img {
                height: 890px;
                width: auto;

                @media only screen and (width: 1024px) {
                    height: 714px;
                    width: auto;
                }
            }
        }

        /* IMAGE PORTRAIT SMALL */
        &:nth-child(10n+5),
        &:nth-child(10n+9) {
            .imgWrapper {
                @include flexbox(row, center, unset);

                img {
                    width: auto;
                    min-width: 39rem;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        &:nth-child(5) {
            .imgWrapper {
                @include flexbox(row, left, center);

                img {
                    max-width: 552px;
                    min-width: 0;
                    width: 100%;
                    height: auto;
                }
            }
        }

        &:nth-child(15) .imgWrapper {
            @include flexbox(row, left, unset);

            img {
                height: 970px;
                width: 654px;

                @media only screen and (width: 1024px) {
                    height: 699px;
                    width: 471px;
                }
            }
        }

        &:nth-child(19) .imgWrapper {
            @include flexbox(row, right, unset);
        }

        &:nth-child(20) {
            width: 46.5vw;
        
            .imgWrapper {
                @include flexbox(row, flex-start, unset);
                align-items: center;

                img {
                    max-width: 552px;
                    min-width: 0;
                    width: 100%;
                    height: auto;
                }
            }
        }

        &:nth-child(25) .imgWrapper {
            @include flexbox(row, left, unset);

            img {
                max-width: 552px;
                min-width: 0;
                width: 100%;
                height: auto;
            }
        }

        &:nth-child(29) .imgWrapper {
            @include flexbox(row, right, unset);

            img {
                max-width: 552px;
                min-width: 0;
                width: 100%;
                height: auto;
            }
        }
    }
}

.lightbox#lightbox_achat_express_v2 .page_look {

    h2:not(.item_name) {
        @include wshopFont($primaryFont, 1rem, unset, .05rem, 700, $primaryColor);

        &:before {
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    .product_right {
        width: 66%;
        margin: 0;
        @include flexbox(row, center, center);

        .lookbookSwiper {
            max-width: 100%;
            width: 100%;
            margin-top: 4rem;
            padding: 0 2rem 0 2.5rem;

            > div:first-of-type {
                display: flex;
                justify-content: center;

                > div {

                    width: 50%;

                    &:first-of-type {
                        padding-right: 1rem;
                    }
                    &:last-of-type {
                        padding-left: 1rem;
                    }
                }
            }

            .block_vid{
                background: #ffffff;
                width: 100%;
                height: 0;
            }
        }
    }
}

body.cms_page.template2,
body.cms_page_new.template2{
    .cms_category_1.cms_page{
        .cms_page_content .cms-page-module.text-image-5{
            .cms-page-column{
                span.cms-page-module-img{
                    margin-right: 0rem;
                    margin-left: 0rem;
                    transition: margin 0.25s ease-in-out;
                }
                img.cms-page-module-img{
                    margin-right: 3rem;
                    margin-left: 3rem;
                    transition: margin 0.25s ease-in-out;
                }
                .ill_img>img {
                    width: auto !important;
                }
            }
        }
    }
}

