.productLeftPart {
    height: 100%;
    width: 65%;
    max-width: 1340px;

    @include flexbox(row, flex-start, unset);

    @media screen and (max-width: 1450px){
        width: 70%;
    }

    // Contains center swiper + desc 
    .productLeftPart__swiperPics {
        height: 100%;
        width: 72%;

        .lightbox .close_pop.bgSprite {
            height: unset;

            &::before {
                position: absolute;
                top: -5px;
                right: -20px;
                width: 1rem;
                height: 1rem;
            }
        }
    }

    // Center swipers
    .swipersContainer {
        @include flexbox(row, flex-start, flex-start);
        height: 88vh;
        max-height: 802px;
    
        .prodSwiperBig {
            height: 100%;
            width: auto;
            max-width: 642px;
            margin: 0 0 0 5%;
    
        
            .swiper-wrapper {
                flex-direction: column !important;
                width: auto;
        
                .swiper-slide {
        
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        cursor: url(../svg/zoom_cursor.svg) 13 13,auto;
                        position: relative;
                        transition: all .5s ease-in-out;
    
                        &:hover {
                            transform: scale(2);
                            transition: transform 250ms ease 0s;
                        }
                    }
                }
            } 
            .block_vid{
                position: relative;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                height: 0;
                padding-bottom: 125%;
                position: relative;

                @media screen and (min-width: 1024px){
                    padding-bottom: 110%;
                }

                @media screen and (min-width: 1310px){
                    padding-bottom: 707px;
                }

                @media screen and (min-width: 1450px){
                    padding-bottom: 125%;
                }
                iframe {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    top:0;
                    border: 0;
                    z-index: -1;

                    @media screen and (max-width: 1410px){
                        width: 64vmin;
                    }

                    @media screen and (max-width: 1024px){
                        width: 557px;
                    }
                }
            } 
        }
        
        .thumbs_vis {
            
            height: 100%;
            @include flexbox(column, center, center);
            max-width: 80px;
            width: 100%;
            margin: 0 5%;

            @media all and (-ms-high-contrast:none){
                width: 80px;
                min-width: 80px;
            }
        
            .btnNext, 
            .btnPrev {
                background-image: unset;
                width: 20px;
                height: 20px;
                margin: 1rem 0;

                &:focus {
                    outline: none;
                }
        
                // When less than 5 pics, dont need arrows
                &.swiper-button-disabled {
                    display: none;
                }
            }

            .btnPrev::before {
                transform: rotate(180deg);
            }
        }
    }

    // Left swiper
    .productLeftPart__onePic {
        width: 30%;
        max-width: 333px;
        height: 100vh;

        @include flexbox(column, flex-end, flex-end); 
        
        .prodSwiperAside_title {
            padding-bottom: 1rem;
            text-align: right;
            @include wshopFont($primaryFont, 2.188rem, unset, unset, 500, $primaryColor);
            text-transform: uppercase;
        }

        .prodSwiperAside {
            width: 100%;
            height: 802px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            .block_vid{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 0;
                padding-bottom: 750px;
                iframe {
                    width: 64vmin;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top:0;
                    border: 0;
                    z-index: -1;

                    @media screen and (max-width: 1024px){
                        width: 592px;
                    }
                }
            }
        }
    }
}
