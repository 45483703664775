.pricetag {
    color: $primaryColor;
    position: relative;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 0.08rem;
    
    &:only-of-type {
        color: $primaryColor;
        font-size: 0.75rem;
    }

    .currency {
        margin-left: .1rem;
    }

    + .pricetag {
        color: $tertiaryColor;
        margin-right: 10px;
        font-size: 0.7rem;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $tertiaryColor;
            position: absolute;
            top: 50%;
            margin-top: -0.5px;
            left: 0;
        }
    }
}

.cents.no_cents {
    display: none!important;
}