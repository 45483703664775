.main_ariane {
    max-width: 1620px;
    margin: 1rem auto;

    nav.breadcrumbs {
        @include flexbox(row, flex-start, center);
    }

    .breadcrumb {

        &:not(:first-child) {
            @include flexbox(row, flex-start, center);

            &:before {
                content:  '/';
                display: block;
                margin: 0 .2rem;
            }
        }

        a,
        span,
        &::before {
            @include wshopFont($primaryFont, .563rem, unset, unset, 500, $tertiaryColor);
            text-decoration: none;
            text-transform: uppercase;
        }
    }

    @media screen and (max-width: 1680px) {
        .breadcrumbs {
            margin-left: 3rem;
        }
    }
}