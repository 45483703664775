.savoir_faire {

    .knowHow {
        max-width: 1500px;

        @media screen and (max-width:1024px) {
            img.first_img, img.last_img {
                width: 50%;
            }

            .savoir_tricotage_container {
                margin-bottom: 13rem !important;
            }
        }

        h2 {
            text-transform: uppercase;
            font-family: $primaryFont;
            color: $primaryColor;
            font-weight: 600;
            margin-bottom: 2rem; 
            font-size: .8rem;
            letter-spacing: .3px;
            position: relative;
            padding: 1.5rem 0;
        }
    
        .savoir_faire_intro {
            text-align: center;
        }

        .savoir_content_bloc {
            text-align: center;

            h2 {
                &:before {
                    content: "";
                    display: block;
                    font-size: 5rem;
                    font-weight: 300;
                    font-family: $secondaryFont;
                    position: absolute;
                    z-index: -1;
                    color: #e9e9e9;
                    top: calc(50% + .5rem);
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                $steps: "1","2","3","4","5","6","7","8","9","10";
                @each $step in $steps {
                    $i: index($steps, $step);
                    
                    &[data-index="#{$i}"]:before {
                        content: "#{$step}";
                    }
                }
            }

            p {
                margin-bottom: 2.5rem;
                text-align: justify;
            }
        }

        .savoir_faire_bloc {
            display: flex;
            justify-content: center;
            margin: 2rem 0;

            &.two_img {
                margin: 5rem 0 10rem;

                img {
                    width: 100%;
                }

                img:nth-of-type(2), .last_img {
                    width: 100%;
                    padding-top: 13rem;
                    padding-left: 2rem;
                }

                .lower_img {
                    padding-top: 18rem;
                    padding-left: 2rem;
                    width: 100%;
                }
            }

            .savoir_single_img {
                margin: auto 0;
            }

            .savoir_content_bloc {
                width: 30%;
                margin-top: auto;
                margin-bottom: auto;

                &.left {
                    padding-right: 4rem;

                }

                &.right {
                    padding-left: 4rem;
                }
            }
        }

        .savoir_tricotage_container {
            display: flex;
            justify-content: space-around;
            margin-bottom: 9rem;

            &:first-of-type {
                margin-top: 6rem;
            }
            
            img {
                width: 100%;
            }

            @media screen and (max-width: 1024px) {
                img {
                    height: 120%;
                }
            }

            &.three_rows {
                .savoir_content_bloc:nth-of-type(2) {
                    margin: 2rem 2rem 0;
                }
            }

            &:nth-of-type(2) {
                .savoir_content_bloc:first-of-type {
                    margin-top: 2rem;

                    @media screen and (max-width: 768px) {
                        margin-right: 3rem;
                    }
                }
            }

            .savoir_content_bloc {
                position: relative;
                width: 23.125rem;
                height: 27.188rem;
                padding: 3.5rem;
                @include flexbox(row, unset, center);

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                .savoir_content_inner {
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    @include flexbox(column, center, unset);
                    background-color: $quaternaryColor;
                    padding: 2rem;

                    h2 {
                        margin: unset;

                        /****@ IE10+ specific styles go here */  
                        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
                            margin-bottom: 0rem;
                            padding: 0.5rem 0;
                        }
                    }
                    p {
                        padding: 1rem 0 0;
                    }
                }
            }
        }

        #fils {
            p {
                margin-bottom: 2rem;
            }

            .savoir_content_bloc {
                text-align: left;
            }

            img {
                margin: auto 0;
            }
        }
    }
}