.contactForm {
    width: 980px;
    margin: 0 auto;
    padding: 2rem;

    .w-contact-form {
        padding-bottom: 1rem;

        .w-form-line {
            .w-input-container {
                .w-textarea {
                    .w-input-element {
                        overflow-y: auto;
                    }
                }
            }
        }

        .w-input.w-textarea .w-input-element {
            padding-top: 2rem !important;
        }
    
        .w-input-label {
            @include wshopFont($primaryFont, .875rem, unset, unset, 400, $tertiaryColor);

            span {
                position: relative;

                &::after {
                    @include asterisk(.875rem);
                }
            }
        }
    
        .w-dropdown {
            select {
                cursor: pointer;
            }

            &.w-focused {
                box-shadow: 0;
                border-color: $black;
            }
        
            &::after {
                content: "";
                width: 20px;
                height: 20px;
                border-top: none;
                border-left: none;
                border-right: none;
                display: block;
                width: 20px;
                height: 20px;
                background-image: url(../svg/spritesheet.svg);
                @include bgIconCoord(6, 5, 5);
                z-index: 1;
            }
        }
    
        .w-lines-group {
            margin: 0 auto;
            text-align: center;
    
            .w-fieldset-label {
                margin: auto;
                padding-bottom: 1rem;
                @include wshopFont($secondaryFont, 1rem, unset, unset, 500, $primaryColor);
            }
    
            .w-form-line {
                width: 25%;
                margin: 0 auto;
                @include flexbox(row, space-between, unset);
    
                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
    
                .w-line-label {
                    width: unset;
                    text-align: left;
                    @include wshopFont($secondaryFont, .875rem, unset, unset, 700, $primaryColor);
                }
    
                .w-radio-input {
                }
            }
        }
    
        .w-submit {
            margin: 2rem auto .5rem;
    
            .w-submit-button {
                background-color: $primaryColor;
                color: $white;
            }
        }
    }


    .requested_field {
        margin: auto 0;
        text-align: center;
        @include wshopFont($primaryFont, .813rem, unset, unset, 200, $tertiaryColor);
    }

    .contactForm_aside {
        width: 588px;
        height: 180px;
        margin: 4rem auto 0;
        padding: 2rem;
        @include flexbox(column, center, center); 
        background-color: $quaternaryColor;

        h2 {
            @include wshopFont($primaryFont, .875rem, unset, .1rem, 500, $primaryColor);
            text-transform: uppercase;
            margin-bottom: 1rem;
        }

        p {
            @include wshopFont($secondaryFont, 1rem, unset, unset, 400, $primaryColor);

            :not(:last-child) {
                margin-bottom: .5rem;
            }
        }
    }

    .wrapperErrors, 
    .mention_cgv,
    .block_img {
        display: none;
    }
}

