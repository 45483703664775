.pageGiftCard {
    display: grid;
    max-width: 1620px;
    min-width: 990px;
    margin: 1rem auto;
    width: 100%;
    font-family: $primaryFont;
    line-height: 1;
    position: relative;
    grid-template-columns: 42% 58%;
    grid-template-rows: 69px 190px 482px;

    &.acheter_cart_cadeau {
        grid-template-rows: 69px 190px 850px;

        @include queriesWshop(1045px, max) {
            grid-template-rows: 69px 190px 1000px;
        }
    }

    /* Page header content */
    .pageGiftCard__header {
        order: 1;
        width: 93%;

        #showAmount {
            display: none;
        }

        h3 {
            font-size: 0;
        }

        h2 {
            text-align: center;
            font-size: 2.1875rem;
            line-height: 2.688rem;
            letter-spacing: 0.05em;
            font-family: $primaryFont;
            text-transform: uppercase;
            font-weight: 700;

            @include queriesWshop(1550px, max) {
                font-size: 1.75rem;
            }
        }
    }

    .pageGiftCard__steps {
        display: block !important;
        margin-left: 3%;
        order: 2;

        @include queriesWshop(1080px, max) {
            margin: 0 0 0 15px;
        }

        .giftcard_steps {
            display: flex;
            column-gap: 22%;
            margin-left: 1.5%;

            .giftcard_step_visual {
                position: relative;

                .step_number {
                    font-family: $fourthFont;
                    font-size: 8.75rem;
                    font-weight: 700;
                    line-height: 168px;
                    letter-spacing: 0.15em;
                    text-align: center;
                    color: $fadedPrimary;
                }

                .step_letter {
                    font-family: $fourthFont;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19px;
                    letter-spacing: 0.15em;
                    text-align: center;
                    color: $primaryColor;
                    text-transform: uppercase;
                    position: absolute;
                    top: 6.46rem;
                    display: flex;
                    justify-content: center;
                    height: auto;
                    width: 8rem;

                    @media screen and (max-width: 1150px) {
                        top: 8.5rem;
                    }

                    @include queriesWshop(1600px, max) {
                        font-size: 0.75rem;
                        line-height: 15px;
                        letter-spacing: 0.1em;
                    }
                }

                .step_photo_1 {
                    background-image: url(../img/carte_cadeau/votre_visuel_1.png);
                    background-repeat: no-repeat;
                    width: 8rem;
                    height: 8rem;
                    position: absolute;
                    top: 1.9rem;
                    left: 2rem;
                }

                .step_photo_2 {
                    background-image: url(../img/carte_cadeau/le_montant_2.png);
                    background-repeat: no-repeat;
                    width: 8rem;
                    height: 8rem;
                    position: absolute;
                    top: 1.9rem;
                    left: 2rem;
                }

                .step_photo_3 {
                    background-image: url(../img/carte_cadeau/votre_message_3.png);
                    background-repeat: no-repeat;
                    width: 8rem;
                    height: 8rem;
                    position: absolute;
                    top: 1.9rem;
                    left: 2rem;
                }

                .step_photo_4 {
                    background-image: url(../img/carte_cadeau/envoyer_par_email_4.png);
                    background-repeat: no-repeat;
                    width: 8rem;
                    height: 8rem;
                    position: absolute;
                    top: 1.9rem;
                    left: 1rem;
                }
            }
        }
    }

    /* Page main content */
    .pageGiftCard__main {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 0 0 0 31px;
        justify-content: center;
        order: 3;
        height: 260px;

        @media screen and (max-width: 1550px) {
            margin-top: 40px;
        }

        @media screen and (max-width: 1225px) {
            margin-top: 20px;
        }

        &.pageGiftCard__main--infos h3 {
            margin-bottom: 2rem;
        }

        &.pageGiftCard__main--infos #textGiftCardForGBE {
            font-family: $fontGiftCard;
            font-style: italic;
        }

        &.pageGiftCard__main--amountinfos {
            width: 764px;
            height: 397px;

            @include queriesWshop(1400px, max) {
                width: 55%;
            }

            @include queriesWshop(1030px, max) {
                width: 45%;
            }

            .return {
                display: block !important;
                margin: 2.3% auto 7%;

                .showAmountBtn {
                    background: $primaryColor;
                    color: $white;
                    border: 1px solid $primaryColor;
                    width: 220px;
                    height: 49px;
                }
            }

            h3 {
                font-family: $primaryFont;
                font-size: 2.188rem;
                font-weight: 700;
                line-height: 43px;
                letter-spacing: 0.05em;
                text-align: center;
                text-transform: uppercase;
            }

            small {
                font-family: $fontGiftCard;
                font-size: 1rem;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: center;
                color: $balticSea;
            }

            &.logged {
                position: absolute;
                z-index: 100;
                left: 25%;
                top: 5%;
                background-color: $white;

                .return {
                    display: block !important;
                }

                h3 {
                    margin-top: 7%;
                    font-family: $primaryFont;
                    font-size: 2.188rem;
                    font-weight: 700;
                    line-height: 43px;
                    letter-spacing: 0.05em;
                    color: $balticSea;
                    text-align: center;
                    text-transform: uppercase;
                }

                small {
                    margin: 0.5rem 18% 0;
                }

                form {
                    margin: 19px auto 0;
                    width: 63%;

                    .w-input-error {
                        margin: 0.5rem 0;
                        color: $redlight;
                    }
                }
            }
        }

        .pageGiftCard__bloc {
            h1 {
                display: none;
            }

            h3 {
                display: none;
            }
        }

        h2 {
            display: none;
        }

        small {
            font-family: $fontGiftCard;
            font-size: 1rem;
            line-height: 19px;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0em;
            text-align: left;
            color: $primaryColor;
        }

        /** First main - Gift card infos details **/
        .infosList {
            margin: 1rem 0 0 0.5rem;
            list-style-type: none;

            li {
                display: block !important;

                &:not(:first-of-type) {
                    padding-left: 1rem;
                    position: relative;

                    &::before {
                        position: absolute;
                        top: 5.5px;
                        left: 0;
                        display: inline-block;
                        content: "";
                        width: 5px;
                        height: 5px;
                        background-color: $primaryColor;
                        border-radius: 50%;
                    }
                }

                h2 {
                    font-family: $fontGiftCard;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 19.2px;
                    color: $primaryColor;
                    display: block;
                }

                &:first-of-type h2::before {
                    display: none;
                }

            }
        }

        .cgvLink:first-of-type {
            font-size: 0.813rem;
            font-weight: 325;
            position: absolute;
            margin-top: 1rem;
            color: $primaryColor;
            display: block !important;
            font-family: $fourthFont;
            line-height: 1rem;
            letter-spacing: 0em;
            text-align: center;
            text-decoration: underline;
        }

        .cgvGift {
            display: none;
        }

        .btn_container {
            height: 49px;
            margin-right: 10px;
            width: fit-content;

            .button {
                padding: 0 2rem;
                background: blue;
                color: $primaryColor;

                &:before {
                    content: none;
                }

                span {
                    white-space: nowrap;
                }
            }

            &:not(.return) {
                .button:not(.offerCardBtn) {
                    width: 350px;
                    height: 49px;
                    padding: 0;
                    background: $black;
                    color: $white;
                    white-space: nowrap;
                }
            }

            .offerCardBtn {
                width: 350px;
                height: 49px;
                padding: 0;
                border: 1px solid $balticSea;
                background: $white;
            }
        }

        .giftCard_btn {
            display: flex;
            margin-top: 61px;
            width: fit-content;

            @media screen and (max-width: 1550px) {
                flex-wrap: wrap;
                gap: 1rem;
            }

            #showAmount {
                display: block !important;
            }
        }

        /** Second main - Gift card form - Amount details **/
        .w-giftcard-form {

            .giftcard_desc {
                display: none;
            }

            .giftcard_amount {
                font-family: $fourthFont;
                font-size: 0.813rem;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: 0.1em;
                text-align: center;
                color: $balticSea;
                padding: 5% 0 2%;
                margin: 3% 0 0;
                border: solid 1px $primaryColor;
                border-bottom: none;
                letter-spacing: 1.3px;
                text-transform: uppercase;

                strong {
                    font-weight: 700;
                }
            }

            // class that begins with "giftcard_validity_"
            [class^="giftcard_validity_"] {
                font-family: $fontGiftCard;
                font-size: 1rem;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: center;
                color: $balticSea;
                padding: 0 0 28px;
                border: solid 1px $primaryColor;
                border-top: none;
            }

            .w-form-line {
                position: relative;
                margin-bottom: 0;

                #cardCode {
                    border-radius: 0;
                    width: 100%;
                    box-sizing: border-box;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                .w-input {
                    &:focus {
                        outline: none;
                    }
                }

                .w-submit {
                    position: absolute;
                    width: 15%;
                    right: 0;
                    top: 0;

                    @media screen and (max-width: 1440px) {
                        // left: 59%;
                    }

                    @media screen and (max-width: 1280px) {
                        // left: 60%;
                    }

                    .w-submit-button {
                        width: 100%;
                        background: none;
                        color: $balticSea;
                        border: none;
                    }
                }
            }
        }

        .btn_container.discover-btn {
            display: none;
        }
    }

    /* Page aside decorating img */
    .pageGiftCard__asideImg {
        margin-top: 0;
        max-height: 741px;
        max-width: 532px;
        order: 0;
        grid-row: 1 / span 3;
        margin-left: 14%;

        img {
            width: 100%;
        }
    }

    /*** General btn style ***/
    .btn_container button {
        width: fit-content;
        padding: 0 2rem;
    }

    /*** Show/hide class ***/
    .is-hidden {
        display: none;
    }
}

// Giftcard Lightbox
#lightboxOrderGiftCard {
    position: unset;
    width: 100%;
    margin-left: 0.7rem;

    #orderGiftCardForm {
        div {
            #step1 {
                max-width: 735px;

                @include queriesWshop(1650px, max) {
                    width: 83.7%;
                }

                @include queriesWshop(1400px, max) {
                    width: 79.6%;
                }

                @include queriesWshop(1190px, max) {
                    width: 87.5%;
                }

                .step0_infos {
                    display: block !important;
                    margin: 11rem 0 1.9rem;
                    position: relative;

                    input[type="radio"] {
                        position: absolute;
                        opacity: 0;
                        pointer-events: none; // Disable interaction with the hidden radio buttons
                    }

                    label {
                        display: inline-block;
                        margin-right: 20px;
                        border: 2px solid transparent;
                        padding: 5px;

                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }

                    input[type="radio"]:checked+label {
                        border: 2px solid $primaryColor;
                        line-height: 0;
                        padding: 5px;
                        transition: border 0.5s ease;
                    }

                    h2 {
                        font-family: $fourthFont;
                        font-size: 1rem;
                        font-weight: 700;
                        line-height: 19px;
                        letter-spacing: 0.15em;
                        color: $primaryColor;
                        text-transform: uppercase;
                        margin-bottom: 20px;
                    }

                    img {
                        height: 180px;
                    }
                }

                .step1_infos {
                    position: relative;

                    #sendMethods {
                        display: none;
                    }

                    #chooseBalance {
                        margin-bottom: 1rem;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 0 15px;

                        @include queriesWshop(1650px, max) {
                            gap: 0;
                            justify-content: space-between;
                        }

                        @include queriesWshop(1400px, max) {
                            gap: 0;
                            justify-content: space-between;
                        }

                        @include queriesWshop(1190px, max) {
                            gap: 0;
                            justify-content: space-between;
                        }

                        h2 {
                            font-family: $fourthFont;
                            font-size: 1rem;
                            font-weight: 700;
                            line-height: 19px;
                            letter-spacing: 0.15em;
                            text-align: left;
                            color: $primaryColor;
                            text-transform: uppercase;
                            margin-bottom: 20px;
                            width: 100%;
                        }

                        input[id="selectBalance"] {
                            width: 100%;
                            height: 48px;
                            padding: 14px;
                            border: 1px solid $lightgrey;
                            margin-top: 15px;

                            &::placeholder {
                                font-family: $fontPlaceholder;
                                font-size: 0.875rem;
                                font-weight: 400;
                                line-height: 21px;
                                letter-spacing: 0em;
                                text-align: left;
                            }

                            &:focus-visible {
                                border: 2px solid $onFocus;
                            }
                        }

                        input[id="selectBalance"].w-input.inputErr {
                            border: 1px solid $redlight;
                        }

                        /* Chrome, Safari, Edge, Opera */
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        /* Firefox */
                        input[type=number] {
                            -moz-appearance: textfield;
                        }

                        &.giftcard_amount_hide {
                            display: none;
                        }
                    }

                    input[type="radio"] {
                        display: none;
                    }

                    label {
                        border: 1px solid $montantRadio;
                        background-color: $white;
                        color: $grey3;
                        height: 3.125rem;
                        position: relative;
                        display: inline-flex;
                        align-items: center;
                        padding: 0 2.14rem;
                        margin-bottom: 0.9rem;

                        @include queriesWshop(1650px, max) {
                            height: 2.563rem;
                            padding: 0 2rem;
                        }

                        @include queriesWshop(1400px, max) {
                            height: 2.373rem;
                            padding: 0 1.75rem;
                        }

                        @include queriesWshop(1190px, max) {
                            height: 2.632rem;
                            padding: 0 1.6rem;
                        }

                        span {
                            text-align: center;
                            font-size: 0.875rem;
                            font-weight: 500;

                            @include queriesWshop(1650px, max) {
                                top: 12px;
                            }

                            @include queriesWshop(1400px, max) {
                                top: 10px;
                                font-size: 0.8rem;
                            }

                            @include queriesWshop(1190px, max) {
                                top: 8px;
                                font-size: 0.75rem;
                                margin-right: 0.1rem;
                            }
                        }
                    }

                    label.inputErr {
                        border: 1px solid $redlight;
                    }

                    label[for='balance_other'] {
                        margin: 0 0rem 0.9rem 0;
                    }

                    input[type="radio"]:checked+label {
                        border: 2px solid $primaryColor;
                        background-color: $primaryColor;
                        color: $white;
                    }
                }

                .btn_container {
                    display: none;
                }
            }

            #step2 {
                max-width: 735px;
                width: 100%;
                height: auto;

                @include queriesWshop(1650px, max) {
                    width: 83.7%;
                }

                @include queriesWshop(1400px, max) {
                    width: 79.6%;
                }

                @include queriesWshop(1190px, max) {
                    width: 87.5%;
                }

                .step2_infos {
                    .js-textDeliveryMail {
                        display: none;
                    }

                    h3 {
                        font-family: $fourthFont;
                        font-size: 1rem;
                        font-weight: 700;
                        line-height: 19px;
                        letter-spacing: 0.15em;
                        color: $primaryColor;
                        text-transform: uppercase;
                        margin-bottom: 15px;
                    }

                    .infos_msg {
                        margin-bottom: 20px;

                        .formPerso {
                            input {
                                width: 100%;
                                height: 48px;
                                padding: 14px;
                                border: 1px solid $lightgrey;
                                margin-bottom: 15px;

                                &:focus-visible {
                                    border: 2px solid $onFocus;
                                    outline: 0;
                                }

                                &::placeholder {
                                    font-family: $fontPlaceholder;
                                    font-size: 0.875rem;
                                    font-weight: 400;
                                    line-height: 21px;
                                    letter-spacing: 0em;
                                    text-align: left;
                                }
                            }

                            input.w-input.inputErr {
                                border: 1px solid $redlight;
                            }
                        }


                        // Animation input

                        .formPerso,
                        .formText {

                            .input-group {
                                position: relative;
                                display: block;
                                margin-bottom: 1em;
                            }
                        }

                        .w-input {
                            width: 100%;
                            padding: 8px;
                            padding-top: 18px;
                            border: 1px solid #ccc;
                            border-radius: 4px;
                        }

                        .form-label {
                            position: absolute;
                            display: block !important;
                            top: 0;
                            left: 8px;
                            pointer-events: none;
                            transition: all 0.2s ease;
                            padding: 0 4px;
                            transform-origin: left top;
                            transform: translateY(24px);
                            font-size: 10px;
                            opacity: 0;
                        }

                        .w-input:not(:placeholder-shown)+.form-label,
                        .w-input.has-value+.form-label {
                            transform: translateY(6px) scale(0.8);
                            opacity: 1;
                        }

                        // end animation input

                        .formText {
                            textarea {
                                width: 100%;
                                height: 180px;
                                padding: 14px;
                                font-family: $fontPlaceholder;
                                font-size: 0.875rem;
                                font-weight: 400;
                                line-height: 21px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: $black;
                                border: 1px solid $lightgrey;
                                resize: none;
                                box-sizing: border-box;

                                &::placeholder {
                                    font-family: $fontPlaceholder;
                                    font-size: 0.875rem;
                                    font-weight: 400;
                                    line-height: 21px;
                                    letter-spacing: 0em;
                                    text-align: left;
                                }

                                &:focus-visible {
                                    border: 2px solid $onFocus;
                                    outline: 0;
                                }
                            }

                            textarea.w-input.inputErr {
                                border: 1px solid $redlight;
                            }
                        }
                    }
                }

                .btn_container {
                    .js-backStep {
                        display: none;
                    }

                    #js-submitStep2 {
                        font-family: $fourthFont;
                        font-size: 0.688rem;
                        font-weight: 400;
                        line-height: 13px;
                        letter-spacing: 0em;
                        text-align: center;
                        background: $primaryColor;
                        width: 230px;
                        padding: 0;

                        span {
                            color: $white;
                        }
                    }
                }
            }

            #step3 {
                max-width: 735px;
                display: none;

                .infos {
                    h2 {
                        display: none;
                    }

                    ul.stepsList {
                        display: none;
                    }

                    ul.resumeList {
                        small.js-backStep.modify {
                            display: none;
                        }

                        h3 {
                            display: none;
                        }

                        li:first-child h3,
                        li:nth-child(3) h3 {
                            display: block;
                            font-family: $fourthFont;
                            font-size: 1rem;
                            font-weight: 700;
                            line-height: 19px;
                            letter-spacing: 0.15em;
                            color: $primaryColor;
                            text-transform: uppercase;
                            margin-bottom: 20px;
                        }

                        p#js-balance {
                            width: 100%;
                            height: 48px;
                            padding: 14px;
                            border: 1px solid $lightgrey;
                            margin-top: 20px;
                            margin-bottom: 30px;

                            @include queriesWshop(1650px, max) {
                                width: 83.7%;
                            }

                            @include queriesWshop(1400px, max) {
                                width: 79.6%;
                            }

                            @include queriesWshop(1190px, max) {
                                width: 87.5%;
                            }
                        }

                        li#sendMethodByPost {
                            display: none;
                        }

                        p#js-name,
                        p#js-signature {
                            width: 100%;
                            height: 48px;
                            padding: 14px;
                            border: 1px solid $lightgrey;
                            margin-bottom: 15px;

                            @include queriesWshop(1650px, max) {
                                width: 83.7%;
                            }

                            @include queriesWshop(1400px, max) {
                                width: 79.6%;
                            }

                            @include queriesWshop(1190px, max) {
                                width: 87.5%;
                            }
                        }

                        p#js-message {
                            width: 100%;
                            height: 150px;
                            padding: 14px;
                            font-family: $primaryFont;
                            font-weight: 400;
                            line-height: 21px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: $black;
                            border: 1px solid $lightgrey;
                            margin-bottom: 20px;

                            @include queriesWshop(1650px, max) {
                                width: 83.7%;
                            }

                            @include queriesWshop(1400px, max) {
                                width: 79.6%;
                            }

                            @include queriesWshop(1190px, max) {
                                width: 87.5%;
                            }
                        }
                    }
                }

                .btn_container {
                    display: flex;
                    flex-direction: row;

                    button {
                        font-family: $fourthFont;
                        font-size: 0.688rem;
                        font-weight: 400;
                        line-height: 13px;
                        letter-spacing: 0em;
                        text-align: center;
                        background: $primaryColor;
                        width: 230px;
                        padding: 0;

                        @include queriesWshop(1190px, max) {
                            width: 200px;
                        }

                        span {
                            color: $white;
                        }
                    }

                    .js-backStep {
                        order: 1;
                    }

                    #js-submitStep3 {
                        order: 0;
                        margin-right: 10px;
                    }
                }
            }

            .cgv_giftcard {
                font-size: 0.813rem;
                font-weight: 325;
                position: absolute;
                margin-top: 15px;
                color: $tertiaryColor;
                display: block !important;
                font-family: $fourthFont;
                line-height: 1rem;
                letter-spacing: 0em;
                text-align: center;
                text-decoration: underline;
            }
        }
    }
}

body.fr {
    .pageGiftCard {
        .pageGiftCard__main {
            #formGiftCard {
                .giftcard_validity_fr {
                    display: block !important;
                }
            }
        }
    }
}

body.en {
    .pageGiftCard {
        .pageGiftCard__main {
            #formGiftCard {
                .giftcard_validity_en {
                    display: block !important;
                }
            }
        }
    }
}

.euro-symbol {
    position: absolute;
    right: 25px;
    top: 86%;
    transform: translateY(-50%);
    pointer-events: none;
    opacity: .5;

    &.show-symbol {
        // !important car style inline
        display: block !important;
    }
}

body {
    &.giftcard_index {
        .pageGiftCard {
            .pageGiftCard__main {
                li {
                    h2 {
                        font-weight: 400 !important;
                        margin-bottom: initial;
                        font-style: normal;
                        text-align: initial;
                        letter-spacing: initial;
                        text-transform: initial;
                    }
                }
            }

            .pageGiftCard__header {
                h2 {
                    font-weight: 700 !important;
                    font-size: 2.1875rem !important;
                }
            }
        }
    }
}