/**@ Page plan-du-site  **/
.footer_sitemap  {
    #appContainer {
        .page_wrapper {
            .main_wrapper {
                .sitemap {
                    width: 100%;
                    .satellite_content {
                        width: 88%;
                        margin: 0 auto;
                        display: flex;
                        flex-wrap: wrap;

                        .groupe{
                            margin-top:3rem;
                        }
                        
                        .sitemap_section {
                            display: flex;
							flex-direction: column;
                            width: 25%;
                            .lowercase_title {
                                margin-bottom: 1rem;
                            }
                            
                            .article {
                                .site_map_ul {
                                    li {
                                        a {
                                            text-decoration: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}