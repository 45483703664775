/******************************************************************************/
/* SITE COLORS */
/******************************************************************************/

$primaryColor:      #242424;
$secondaryColor:    #0A259A; 
$tertiaryColor:     #949494;
$quaternaryColor:   #f5f4f4;
$darkgrey:          #333332;
$darkgrey2:         #221E1F;
$red:               #ff0000;

$white:             #FFF; 
$black:             #000;
$grey:              #A6A5A5;
$btnColor:          $secondaryColor;
$btnHover:          $primaryColor;
$subMenu:           #EEEEEE;
$borderColor:       #EFEFEF;
$grey:              #8B8B8B;
$grey2:             #242424;
$grey3:             #919191;
$lightgrey:         #B1B1B1;
$lightgrey2:        #F7F7F7;
$lightgrey3:        #e4e0dd;
$red2:              #cc1212;
$balticSea:         #272525;
$iridium:           #3C3C3C;
$dark:              #0e0e0e;
$lightgreen:        #90ee90;
$grey4:             #6f7376;
$darkred:           #de58441a;
$purplegrey:        #929195;
$redlight:          #f43939;
$grey5:             #999999;
$border:            #ffffff00;
$green:             #1ca82c;
$grey6:             #d9d9d9;
$grey7:             #aaa;
$greyblue:          #9ca4ab;
$darkblue:          #22252b;
$aquaHaze:          #f3f3f3;
$fadedPrimary:      rgba(36, 36, 36, 0.1);
$onFocus:           #101010;
$montantRadio:      #CFCFCF;