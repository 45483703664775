#search_page .page_rayon .items_wrapper {

    .item {
        margin: 0 0 2%;
        width: calc(93.5% / 4);
        max-width: 377px;
        min-height: 474px;
        margin-right: 2%;

        @media only screen and (max-width: 1460px) {
            min-height: auto;
        }


    }

    &:not(.threeRows){
        .item {
            &:nth-of-type(4n) {
            margin-right: 0 !important;
            }
        }
    }

    &:not(.fourRows), &.threeRows {
        @include flexbox(row, center, flex-start);
        width: unset;
        margin: 2rem 0 0;
        flex-wrap: wrap;

        .item {
            width: calc(96.17% / 3);
            margin: 0 0 1.915%;
            max-width: 517px;
            min-height: 646px;
            margin-right: 1.915%;

            @media only screen and (max-width: 1460px) {
            min-height: auto;
            }

            &:nth-of-type(3n) {
                margin-right: 0;
            }
        }
    }

    .item .imgWrapper img {
        width: 100%;
        height: auto;
    }
}

#search_page .imgWrapper{
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    background: url('../svg/cloud.svg') center no-repeat;
    background-size: 15%;

    .swiper-slide-off{
        width: 100%;
    }

    .productSwiper,
    .productVisualMulti{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        min-height: 100%;
        overflow: initial;

        .swiper-slide:first-of-type .swiper-lazy-preloader {
            background-size: contain;
            opacity: 1 !important;

            &:after {
                background-size: contain;
                background-image: none !important;
            }
        }

        .swiper-slide:not(:first-of-type) .swiper-lazy-preloader{
            background-size: contain;
            background-image: none !important;
        }

        .swiper-slide:not(:first-of-type) .swiper-lazy,
        .swiper-slide:not(:first-of-type) .swiper-lazy-preloader{
            opacity: 0;
        }

        .swiper-slide:not(:first-of-type) .swiper-lazy-loaded{
            opacity: 1;
        }

        .swiper-slide-off{

            a{
                height: 100%;
                padding-bottom: 0;

                img{
                    position: initial;
                }
            }
        }
    }

    .ill_img:not(.loaded) {
        padding-top: calc(818 / 642 * 100%);
        background: unset;

        &:before {
            content: none;
        }
    }
}

// Fix image on color switch
#search_page #list_item .item .imgNav.imgWrapper {
    display: block;
    .block_lnk {
        > div {
            display: block;
        }
    }
}


// Handle eclats style on search products
#search_page {
    .item {
        .eclat_product {
            max-height: 18px;
        }
    }
}
