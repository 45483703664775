.category .page_wrapper .textSeo, .super_boutik .page_wrapper .textSeo {
    max-width: 1420px;
    margin: 6rem auto 0;
    padding: 4rem 8rem;
    background-color: $subMenu;
    @include flexbox(row, space-between, flex-start); 

    .textSeo_bloc {
        width: 45%;
    }

    .textSeo_bloc_title {
        @include wshopFont($primaryFont, .75rem, unset, unset, 700, $primaryColor);
        text-transform: uppercase;
        margin-bottom: 1rem;
    }

    .textSeo_bloc_content {
        @include wshopFont($secondaryFont, .875rem, unset, unset, 400, $primaryColor);
    }
}