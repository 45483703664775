.wrapper_retailers_map .liste_recherche_distributeur .liste_distributeur_wrapper .liste_distributeur .elem_liste_distributeur .horaire_today .s_opened,
.wrapper_retailers_map .liste_recherche_distributeur .liste_distributeur_wrapper .liste_distributeur .elem_liste_distributeur .horaire_today .s_closed {
	font-weight: bold;
	font-size: 13px;
	letter-spacing: .1rem;
	text-transform: uppercase;
	margin-bottom: .5rem;
}

.wrapper_retailers_map .liste_recherche_distributeur .liste_distributeur_wrapper .liste_distributeur .elem_liste_distributeur .horaire_today .s_opened {
	color: $green;
}

.wrapper_retailers_map .liste_recherche_distributeur .liste_distributeur_wrapper .liste_distributeur .elem_liste_distributeur .horaire_today .s_closed {
	color: $red;
}

.wrapper_retailers_map {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;

	.wrap_store {
		.storeloc_form {
			margin: 0 2rem;
		}

		.formWrapper {
			.w-input-container:first-child {
				width: 30%;
			}

			.w-input-container:nth-child(2) {
				width: 60%;
			}

			span.or {
				display: flex;
				margin: 0 0rem;
				padding-right: 2rem;
				text-transform: uppercase;
				font-family: $primaryFont;
				font-size: 14px;
				font-weight: 500;

				/***@ IE10+ specific styles go here */
				@media screen and (-ms-high-contrast: active),
				screen and (-ms-high-contrast: none) {
					padding-right: 2.5rem;
				}
			}

			.btn_container {
				button {
					@extend .button;
					width: 240px;
					height: 48px;
					text-transform: uppercase;
					font-size: 11px;
					letter-spacing: .15rem;
					font-weight: bold;
					background-color: $black;
					color: $white;
				}
			}
		}

		.loc_btn {
			position: relative;
			background-color: $white;
			width: 35px;
			height: 35px;
			left: -3%;

			span:before {
				position: absolute;
				top: 50%;
				right: 0;
				width: 35px;
				height: 35px;
				background-position: 50% 75%;
				transform: translateY(-50%);
			}
		}
	}

	#retailers_map {
		height: 495px;
		width: 100%;
		margin-top: 26px;

		.info_popup {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;
			padding: 38px;
			overflow: hidden;
			max-width: 318px;
			max-height: 181px;

			.linkDetails {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-top: 16px;

				a {
					display: flex;
					font-size: 12px;
					color: $grey;
					font-family: $primaryFont;

					& + a {
						margin-left: 1rem;
					}
				}
			}

			.name {
				font-family: $primaryFont;
				font-weight: bold;
				letter-spacing: .1rem;
				font-size: 13px;
			}

			.adresse {
				margin-top: .8rem;
				font-family: $secondaryFont;
				font-weight: 400;
				font-size: 16px;
				line-height: 1;
				white-space: nowrap;
			}

			.coord {
				margin-top: .4rem;
			}
		}
	}

	.liste_recherche_distributeur {
		.resultat_recherche {
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: $primaryFont;
			text-transform: uppercase;
			font-size: 15.2px;
			padding: 32px 0 34px;
			font-weight: bold;

			h3 {
				margin-right: .5rem;
			}

			span#nb_magasins {
				font-weight: normal;
				margin-left: .5rem;
				font-size: 13px;
			}
		}

		.liste_distributeur_wrapper {
			.liste_distributeur {
				display: flex;
				justify-content: space-around;
				align-items: flex-start;
				flex-wrap: wrap;
				max-width: 1040px;
				width: 100%;
				margin: 0 auto;
				padding: 0 2rem;

				.elem_liste_distributeur {
					border: 1px solid rgba($color: $black, $alpha: .1);
					display: flex;
					justify-content: center;
					align-items: flex-start;
					padding: 44px 20px;
					width: 31%;
					height: auto;
					text-align: center;
					margin-bottom: 28px;

					.nom_store {
						font-family: $primaryFont;
						font-weight: bold;
						text-transform: uppercase;
						font-size: 13px;
						letter-spacing: .1rem;
						margin-bottom: 18px;
					}

					.afficherHoraires {
						font-family: $primaryFont;
						font-weight: 500;
						font-size: 12px;
						color: $tertiaryColor;
					}

					.detail_store {
						line-height: 1.3;
					}

					.horaire_today {
						margin: 20px 0;
						display: flex;
						justify-content: center;
						align-items: stretch;
						flex-wrap: wrap;

						.txt_store_open {
							font-weight: bold;
						}

						.store_open {
							&::before {
								content: '';
								width: 10px;
								height: 10px;
								background-color: $green;
								display: block;
								position: absolute;
								left: -15px;
								top: 4px;
								border-radius: 50%;
							}
						}

						.store_close {
							font-family: $primaryFont;

							&::before {
								content: '';
								width: 10px;
								height: 10px;
								background-color: $red;
								display: block;
								position: absolute;
								left: -15px;
								top: 60%;
								transform: translateY(-50%);
								border-radius: 50%;
							}
						}

						span {
							text-transform: uppercase;
							font-family: $primaryFont;
							color: $green;
							font-size: 13px;
							font-weight: 400;
							position: relative;
							display: block;
							height: 13px;
						}

						strong {
							font-size: 15px;
							margin-left: .5rem;
							padding-top: .1rem;
						}
					}

					.filtres {
						text-transform: uppercase;
						font-family: $primaryFont;
						color: $secondaryColor;
						letter-spacing: .05rem;
						font-size: 10px;
						font-weight: 700;
						margin: 1rem 0;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						align-items: center;

						/* point de séparation remplacant le ; généré dans le back  */
						.separator_bullet {
							vertical-align: center;
							font-size: 1rem;
							padding: 0 0.4rem;
						}

						span {
							display: flex;
							justify-content: center;
							align-items: center;
							color: $secondaryColor;
							text-transform: uppercase;
							font-weight: 600;

							p:not(:last-child) {
								margin-right: 1rem;
								position: relative;

								&::after {
									content: '•';
									position: absolute;
									right: -10px;
									top: -1px;
									padding-left: 1%;
								}
							}
						}
					}
				}
			}
		}
	}
}

.store_details_wrapper {
	.store_open {
		display: flex;
		align-items: stretch;

		strong {
			margin-left: .5rem;
			display: block;
		}

		span:first-child {
			display: none !important;
		}
	}

	.filtres {
		font-weight: 700;

		span {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			align-items: baseline;
		}

		p {
			text-transform: uppercase;
			font-family: $primaryFont;
			color: $secondaryColor;
			letter-spacing: .05rem;
			font-size: 10px !important;
			margin: 1rem 0;

			& + p {
				margin-left: 1rem;
			}
		}
	}
}

.gm-style .gm-style-iw-c {
	border-radius: 0;
}

// SHOP DETAILS
#favorite_store_button {
	display: none;
}

#sectionDistribution {
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	margin-bottom: 60px;
	padding: 0 2rem;

	.infoShop {
		width: 30%;
		background-color: $quaternaryColor;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		text-align: center;
		padding: 8% 36px;

		.wrapper_maps_details {
			width: 100%;
			text-align: center;

			.distribute_info section:nth-of-type(2) {
				margin: 24px 0;
				letter-spacing: .1rem;
				text-transform: uppercase;
				font-family: montserrat, sans-serif;
				font-size: 13px;
				font-weight: 500;
			}
		}

		.distribute_adress {
			margin: .8rem 0 0;
		}

		.horaires {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			text-align: left;
			margin: 1.5rem auto 0;
			width: 72%;
			letter-spacing: 0;
			font-weight: normal;

			.horaire,
			.holiday {
				text-transform: uppercase;
				font-family: $primaryFont;
				font-size: 0.8rem;
			}

			.horaire {
				padding-bottom: 1rem;
			}
		}

		h2 {
			font-size: 16px;
			letter-spacing: .1rem;
			text-transform: uppercase;
			font-family: $primaryFont;
			font-weight: bold;

			&.hourstitle {
				font-weight: 500;
				font-size: 13px;
				margin: 24px 0;
			}
		}

		p {
			font-size: 16px;
			line-height: 1.4;
		}

		.horaire_today {
			text-align: center;
			margin: 2rem auto;
			text-transform: uppercase;
			font-family: $primaryFont;
			display: flex;
			justify-content: center;

			> p {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}

			.store_open {
				span {
					text-transform: uppercase;
					font-family: $primaryFont;
					color: $green;
					font-size: 13px;
					font-weight: 700;
					position: relative;
					display: block;

					&::before {
						content: '';
						width: 10px;
						height: 10px;
						background-color: $green;
						display: block;
						position: absolute;
						left: -15px;
						top: 50%;
						transform: translateY(-50%);
						border-radius: 50%;
					}
				}

				strong {
					padding-top: .1rem;
					text-transform: lowercase;
				}

				font-size: 12px;
				font-weight: 500;
			}

			.store_close {
				.s_closed {
					position: relative;
					font-size: 13px;
					font-weight: 700;
					letter-spacing: 0.1rem;
					margin-bottom: .5rem;
					padding-left: 0.5rem;
					color: $red;

					&::before {
						content: '';
						width: 10px;
						height: 10px;
						background-color: $red;
						display: block;
						position: absolute;
						left: -15px;
						top: 50%;
						transform: translateY(-50%);
						border-radius: 50%;
					}
				}
			}
		}

		.filtres {
			text-transform: uppercase;
			font-family: $primaryFont;
			color: $secondaryColor;
			letter-spacing: .1rem;
			font-size: 10px;

			> span > p:not(:last-of-type) {
				padding-right: 0.5rem;
				position: relative;

				&::after {
					// Hex code
					content: "\25CF";
					display: block;
					position: absolute;
					left: 100%;
					right: 15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}

	.map {
		width: 68%;
		height: 100%;
		min-height: 420px;

		a {
			text-decoration: none;
		}

		span.button {
			display: flex;
			justify-content: center;
			align-items: center;
			text-transform: uppercase;
			font-weight: bolder;
			font-family: $primaryFont;

			&.dark {
				background-color: $primaryColor;
				color: $white;
			}
		}

		.rowCta {
			margin: 28px 28px 0px;
			display: flex;
			justify-content: center;

			button {
				margin-left: 1rem;
			}

			span {
				font-size: 12px;
			}
		}

		#store_detail_map {
			height: 100%;
			min-height: 420px;
		}
	}
}

.satellite_store_details .wrapper_moncompte .accountTitleHolder {
	.lowercase_title {
		display: block;
		font-family: $primaryFont;
		letter-spacing: .1rem;
		margin-top: .5rem;
		font-weight: 700;
		font-size: 20px;
		text-align: center;
	}

	.uppercase_title {
		margin-top: -20px;
	}
}

#sectionInfoMag {
	max-width: 1035px;
	width: 100%;
	margin: 25px auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: 90px;

	.description {
		margin-bottom: 56px;
	}

	.images {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		img:first-child {
			margin-bottom: 40px;
		}

		img:nth-child(1){
			width: 100%;
		}
		img:nth-child(2){
			width: 48%;
		}
		img:nth-child(3){
			width: 48%;
		}
	}

	.btn_container {
		margin-top: 25px;

		span {
			font-size: 12px;
		}
	}

	a {
		text-decoration: none;
	}

	span.button {
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		font-weight: bolder;
		font-family: $primaryFont;

		&.dark {
			background-color: $primaryColor;
			color: $white;
		}
	}
}

#ligthbox_send_store_information {
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	padding: 50px;
	text-align: center;
	width: 450px;

	.cross {
		height: initial;
		position: absolute;
		top: 1rem;
		right: 1rem;
		cursor: pointer;
		height: auto;
	}

	.popup_title {
		text-transform: uppercase;
		font-weight: bolder;
		font-family: $primaryFont;
		font-size: 16px;
		letter-spacing: .1rem;
		margin-bottom: 50px;
	}

	.form_item {
		display: flex;
		flex-direction: column;
		margin: 1rem 0;

		label[for="client_mail"] {
			font-family: $primaryFont;
			text-align: left;
			text-transform: uppercase;
			font-size: .75rem;
			padding-bottom: .5rem;
		}

		#client_mail {
			height: 2rem;
			padding: 0 1.5rem;
			@extend .w-input-element;
		}
	}

	.button {
		margin-top: 1rem;
	}
}

#ligthbox_send_store_information .form_item #client_mail:not([type=radio]):not([type=checkbox]),
#ligthbox_send_store_information .form_item input#client_mail[type=text] {
	&:focus {
		border: 1px solid $black;
	}
}

body.en.store_locator {
	.wrapper_retailers_map .wrap_store .formWrapper span.or {
		margin-left: 0;
	}
}