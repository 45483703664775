// Override from app

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element, // TODO:
input[type=password].w-input-element, // Get rid
input[type=text].w-input-element, // of these
textarea.w-input-element {
    // override rules
    border-radius: 0;
    box-sizing: border-box;

    .w-input:not(.w-dropdown) & {

        &:focus {
            border-color: $primaryColor;
            box-shadow: none;
        }
    }

    .w-has-error:not(.w-dropdown) & {
        border-color: $redlight;
        box-shadow: 0 0 0 1px $redlight;
    }
}

.w-input-label {
    font-size: 13 / 16 * 1rem;
    color: $grey;

    .w-nonempty &,
    .w-date-input & {
        font-style: italic;
    }
}

.error_field_tunnel.wrapper_msg_bounce {
    color: $redlight;
}

.w-input-note {
    &.w-input-error {
        color: $redlight;
    }
}

.w-checkbox-input,
.w-radio-input {
    .w-input-element {

        +.w-input-label {
            &::before {
                width: 1rem;
                height: 1rem;
            }
        }

        &:checked+.w-input-label::before {
            background-color: $secondaryColor;
            box-shadow: inset 0 0 0 0.15em $white;
        }


    }
}

.w-dropdown {
    position: relative;
    height: 42 / 14 * 1em;
    border: 1px solid $borderColor;
    border-radius: 0;

    &.w-focused {
        border-color: $grey5;
        box-shadow: 0 0 0 1px $grey5;
    }

    &.w-has-error {
        border-color: $redlight;
        box-shadow: 0 0 0 1px $redlight;
    }
}

.form_submit#bloc_add_basket .loader {
    height: 2.8125rem;
}

button.custom_product {
    @media screen and(max-width: 1770px) {
        margin-top: 1rem;
    }
}

.modal-custom-container {
    top: 60px;
    width: 100%;
    height: calc(100vh - 60px);
    padding-top: 36px;
    background-color: $white;
    color: $primaryColor;
    box-sizing: border-box;
    z-index: 91;
    overflow-y: auto;

    .modal-personnalisation-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1620px;
        margin: 0 auto;
    }

    span.close-modal-custom {
        position: absolute;
        display: block;
        font-size: 0;
        width: 11px;
        height: 11px;
        right: 1rem;
        top: 1rem;
        cursor: pointer;

        &:before,
        &:after {
            content: ' ';
            position: absolute;
            left: 6px;
            width: 1px;
            height: 12px;
            background-color: $darkgrey;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    h3 {
        font-family: $primaryFont;
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: .5vw;
    }

    .img-container {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        width: 50%;

        img {
            width: 100%;
            max-width: 650px;
            height: auto;
        }

        .img-block {
            position: relative;
            height: 100%;
        }

        .display-text-custom {
            position: absolute;
            font-size: 1vw;
            display: flex;
            align-items: center;
            width: fit-content;
            height: 2.6rem;

            @media screen and(min-width: 1400px) {
                font-size: 1vw !important;
            }

            @media screen and(min-width: 1500px) {
                font-size: .9vw !important;
            }
        }
    }

    .block-content {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .product-info {
        max-width: 465px;

        h2 {
            font-family: $primaryFont;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: .05rem;
            margin-bottom: 0.8vw;
            margin-bottom: 1vw;
        }

        .description {
            font-size: 16px;
            line-height: 1.2;
        }

        .price {
            font-family: $primaryFont;
            font-weight: 500;
            font-size: 25px;
            margin: 1vw 0 1.5vw;
        }
    }

    #embroidery_custom {
        max-width: 465px;

        .text-input {
            padding-bottom: 20px;

            p {
                font-family: $primaryFont;
                font-size: 11px;
                font-weight: 100;
                margin-bottom: .3rem;

                &::first-letter {
                    text-transform: uppercase;
                }
            }

            input {
                border: 1px solid $borderColor;
                width: 100%;
                height: 48px;
                text-indent: 1rem;

                &::placeholder {
                    color: $grey3;
                }
            }
        }

        .block-text-location,
        .typo-input,
        .color-input {
            input {
                display: none;
            }

            input:checked+label span::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 3px;
                background-color: $secondaryColor;
                bottom: 0px;
                display: flex;
            }

            label {
                cursor: pointer;
            }
        }

        #button-overlay {
            &.loading {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 305px;
                    height: 45px;
                    background: $iridium url(../svg/three_dots.svg) center no-repeat;
                    background-size: auto 25%;
                    border: 1px solid $black;
                }
            }
        }
    }

    .block-text-location,
    .typo-input {
        h3 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
        }

        .list-location {
            display: flex;
            justify-content: flex-start;
            gap: 12%;
        }

        .list-typo {
            display: flex;
            gap: 12%;
            height: 32px;
            align-items: flex-end;
            margin-bottom: 2rem;
        }
    }

    .block-text-location {
        label {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            max-width: 18%;
            min-width: max-content;
            padding-bottom: 20px;

            img {
                display: block;
                max-height: 5vw;
            }

            span {
                padding-top: 10px;
                position: relative;
            }
        }
    }

    .typo-input,
    .color-input {
        input:checked+label span::after {
            height: 4px;
        }

        label {
            span {
                display: block;
                position: relative;
            }
        }

        .title-and-option {
            display: flex;

            .choice {
                font-family: $primaryFont;
                font-size: 11px;
                padding-left: 5px;
                text-transform: lowercase;

                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }

        input[type="radio"] {
            +label {
                line-height: 1;
            }

            &#Alamain+label {
                font-family: $primaryFontCustom;
                font-size: 1.1em;
            }

            &#Newclassique+label {
                font-family: $secondaryFontCustom;
                font-size: 1.2em;
            }

            &#Anglaise+label {
                font-family: $thirdFontCustom;
                font-size: 1.8em;

                span.typo {
                    line-height: .7;
                }
            }
        }
    }

    .list-colors {
        display: flex;
        gap: 10px;
        padding-bottom: 40px;

        label {
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px $border solid;
            border-radius: 50%;
            padding: 0%;

            div {
                width: 14px;
                height: 14px;
                border: 1px solid $grey;
                border-radius: 50%;
            }
        }

        input:checked+label {
            border: 1px $secondaryColor solid;
        }
    }

    button.button {
        width: 305px;
        height: 45px;
        font-size: 11px;
        font-weight: 900;
        margin-bottom: 15px;

        &#no-customization-button {
            margin-bottom: 0;
        }
    }
}

.modal-custom-container #embroidery_custom .typo-input input:checked+label span::after {
    bottom: -.5rem;
}

#site_head_wrap.modal-active {
    z-index: 92;

    .promo_bar,
    .headerTop {
        display: none;
    }

    .header_menu_top.center {
        box-shadow: 0 0 5px 0 $black;
    }

    .mainMenu .main_menu_itm>span {
        padding-bottom: 0;
    }
}

.w-submit-button,
button.w-submit-button,
.button,
.button2 {
    cursor: pointer;
    height: 45 / 16 * 1rem;
    background-color: $white;
    border: 1px solid $primaryColor;
    font-size: 0.688rem;
    font-family: $primaryFont;
    letter-spacing: .05rem;
    font-weight: 700;
    color: $primaryColor;
    width: 245px;
    text-transform: uppercase;

    &:not(.w-loader):hover,
    &:not(.w-loader):not(.see_more_product):focus {
        background-color: $black;
        color: $white;
    }

    &.w-loader {
        background: $black url(../svg/three_dots.svg) center no-repeat;
        background-size: auto 25%;
    }
}

.w-form-line {
    margin-bottom: 30 / 16 * 1rem;

    #sendFriendYourName {
        text-transform: uppercase;
    }

    &.w-submit {
        max-width: 246 / 16 * 1rem;
    }

}

.w-radio-group {

    &.w-has-error .w-input-element + .w-input-label {
        color: $redlight;

        &::before {
            border-color: $redlight;
            box-shadow: 0 0 0 1px $redlight;
        }
    }
}

.w-checkbox-input,
.w-radio-input {

    .w-input-element {

        +.w-input-label {
            font-size: 0.875rem;
            color: $primaryColor;
            font-style: normal;
        }
    }
}

.w-input.w-tel-input {
    img.img_error {
        position: absolute;
        right: 1%;
        top: 28%;
    }

    .selected-flag {
        height: 95%;
        left: 1px;
    }
}

img.valid_form {
    position: absolute;
    right: 5px;
    top: 30%;
}

//
//  Formulaire d'inscription à la newsletter
// *********************************************************

.w-newsletter-form {
    width: 100%;


    .w-input-label {
        left: 1.5rem;
    }

    .w-input-element:not([type=radio]):not([type=checkbox]),
    input[type=email].w-input-element {
        padding-right: 8rem;
        padding-left: 1.5rem;
        border-radius: 0;
        height: 70 / 14 * 1em;
        background: $white;
        border: none;
        color: $primaryColor;
    }

    .w-input-note.w-input-error {
        text-align: center;
    }

    .w-form-line.w-submit {

        &,
        .w-submit-button {
            border-radius: 0;
            width: auto;
            height: 70 / 16 * 1rem;
            background: $white;
            color: $primaryColor;
        }

        .w-submit-button {
            border: 1px solid $white;
            padding: 0 1.5rem;
            font-size: 13 / 16 * 1rem;
            font-weight: 600;

            &.loading {
                display: none;
            }
        }
    }
}

//
//  Formulaire de contact
// *********************************************************

.w-contact-form {
    padding-bottom: 1rem;
}

//
//  Formulaire d'inscription à la newsletter (lightbox)
// *********************************************************

.w-adwords-form,
.lightbox#popup_inscription_home #formu_inscription_news form.w-adwords-form,
.lightbox#exit_overlay_OVI #formu_inscription_news form.w-adwords-form {
    width: 100%;
    position: relative;

    .w-form-line {
        width: auto;
        margin-bottom: 1rem;
        background-color: $quaternaryColor;

        &.w-submit {
            z-index: 2;
            position: absolute;
            top: 0;
            right: 0;
            background-color: transparent;
            outline: none;

            .w-submit-button,
            button.w-submit-button {
                width: unset;
                padding: 0 2rem;
                @include wshopFont($primaryFont, .688rem, unset, unset, 700, $primaryColor);
                background-color: transparent;
                border: none;
                outline: none;
            }

            .w-submit-button.w-loader, button.w-submit-button.w-loader {
                background: $quaternaryColor url(../svg/three_dots_black.svg) center no-repeat;
                background-size: auto 25%;
            }
        }
    }

    .w-input-container {
        width: 100%;

        .w-input-element {
            text-align: left;
            border: 0;
        }

        .w-input-label {
            left: 0;
            right: 0;
            text-align: left;
            padding-left: 10px;
        }
    }

    input:-internal-autofill-selected {
        background-color: $quaternaryColor !important;
    }
}

//
//  Formulaire de code promo
// *********************************************************

.w-coupon-form {

    .w-form-line {

        .w-input-element:not([type=radio]):not([type=checkbox]),
        input[type=text].w-input-element {
            border-radius: 0;
        }

        &.w-submit {

            border-left: 1px solid $lightgrey3;

            &,
            .w-submit-button {
                border-radius: 0;
                background: transparent;
                color: inherit;
            }

            .w-submit-button {
                border: none;
            }
        }
    }
}

//
//  Formulaire d'alerte réassort
// ***
.w-alertestock-form {
    position: relative;
    width: 100%;
}

.w-lostpass-form .mdpSentEmail {
    text-align: center;
    margin-bottom: 2rem;
}

.w-reply-form {
    .ajout_pj_label {
        border: 1px solid $primaryColor;
        height: 2rem;
        min-width: 13rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 0.85rem;
        cursor: pointer;

        &:before {
            content: '+';
            font-size: 1.3rem;
            margin-right: 0.5rem;
        }
    }
}

.wrapper_tunnel {
    .title.my_account {
        display: none;
    }

    .optin_container {
        display: flex;
        flex-direction: column;
    }

    .w-newlogin-form .knowMoreOptin {
        margin-top: 1em;
        margin-left: 0;

        .info_site a {
            @include wshopFont($primaryFont, .813rem, unset, unset, 200, $primaryColor);
        }
    }

    .w-input-label:before {
        border: 1px solid $grey !important;
    }

    .newlogin_page .login_bloc .title:not(.my_account) .subtitle,
    .w-newlogin-form .w-fieldset-label {
        @include wshopFont($secondaryFont, 1rem, unset, unset, 200, $primaryColor);
    }

    .w-line-label {
        margin-top: .3rem;
        margin-right: 1rem;
        @include wshopFont($secondaryFont, 1rem, unset, unset, 500, $primaryColor);
        min-width: 110px !important;
    }

    .login_bloc {
        width: 43%;

        &:first-of-type {
            border-bottom: 1px solid $borderColor;
        }

        .login_form_wrapper {
            .w-client-form {
                .w-form-line {
                    &.w-submit {
                        max-width: 245px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1024px) and (orientation: portrait) {
        .login_bloc {
            width: 55%;
        }
    }

    .newlogin_page {
        .title {

            h3,
            h4 {
                @include wshopFont($primaryFont, 1rem, unset, .1rem, 700, $primaryColor);
            }

            h4 {
                font-size: .75rem;
            }
        }

        .login_bloc .w-lostpass-form .w-form-line.w-submit {
            max-width: inherit;
            justify-content: space-around;

            .w-btn-wrapper {
                width: auto;
            }
        }
    }

}

body.contact_faq,
body.footer_contact,
body.footer_contact_page {
    #recaptcha {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }
}

// On vise spécifiquement Alamano
#embroidery_custom>div.toggleOption.opened.typo-input>div>label:nth-child(2)>span.typo {
    font-family: $primaryFontCustom;
}