.wrapper_content_panier {
    width: 1620px;
    margin: 0 auto;
}

#main_cart_wrapper + .basket_gondole {
    opacity: 0;
}

#main_cart_wrapper {
    .go_back {
        display: flex;
        justify-content: flex-start;
        font-family: $primaryFont;
        text-transform: uppercase;
        font-size: 11px;
        cursor: pointer;

        &::before {
            transform: rotate(90deg);
        }
    }

    .tunnel_track_pusher {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba($primaryColor, $alpha: .3);

        .tunnel_track_wrapper {
            max-width: 60%;
            width: 100%;
            margin: 0 auto;

            .tunnel_track {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 65px;

                .tunnel_step  {
                    text-transform: uppercase;
                    font-family: $primaryFont;
                    color: $primaryColor;
                    font-weight: 500;
                    font-size: 13px;
                    cursor: pointer;

                    &.on {
                        span {
                            color: #0a259a;
                            font-weight: 700;
                            display: flex;
                            position: relative;
                            &:before {
                               
                                content: '';
                                width: 20px;
                                height: 20px;
                                background-image: url(../svg/spritesheet.svg);
                                position: absolute;
                                top: 100%;
                                left: 50%;
                                -webkit-transform: translateX(-50%);
                                transform: translateX(-50%);
                                background-size: 500% auto;
                                background-position: 0 75%;
                                display: block;

                            }
                        }
                        
                    }
                }
            }
        }
    }

    #right_col_sticky {
        #cart_total + div {
        width: 351px;
        text-align: center;
        margin: 1rem auto 1.2rem;
        font-family: $fourthFont;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 400;
        }
    }

    .basket_gondole {
        border-top :1px solid #EEEEEE;
        border-bottom: 1px solid #EEEEEE;
        padding: 2rem 0;
        margin-top: 2rem;

        .title_gondole {
            font-size: 16px;
            font-family: $fourthFont;
            text-transform: uppercase;
            color: $primaryColor;
            margin-bottom: 1rem;
            font-weight: 400;
        }

        .description_gondole {
            margin-bottom: 2rem;
        }

        .gondole_wrapper {
            position: relative;
            padding: 0 20px;

            .swiper-button-next,
            .swiper-button-prev {
                background-image: url(../svg/spritesheet.svg);
                @include bgIconCoord(6, 5, 5);
                transform: rotate(90deg);
                width: 18px;
                height: 18px;
            }

            .swiper-button-next {
                transform: rotate(-90deg);
                right: 0;
            }

            .swiper-button-prev {
                left: 0;
            }
        }

        div#basket_gondole_slider {
            max-width: 100%;
            margin-top: 2rem;

            .item {
                &:hover {
                    .wrap_rolloverproduit {
                        bottom: 0;
                    }
                }

                .wrap_rolloverproduit {
                    padding: 5px;
                    min-height: 90px;

                    .addToWishlistButton {
                        width: 25px;
                        height: 25px;
                        top: -8px;

                        &:before {
                            width: 23px;
                            height: 23px;
                        }
                    }

                    .choices_list {
                        top: 0px;
                        max-height: 50px;
                    }

                    .titre_choices_list {
                        font-family: $fourthFont;
                        font-size: 8px;
                        margin-bottom: 5px;
                        position: absolute;
                        top: -16px;
                        width: 125px;
                        left: 2px;
                        font-weight: 400;
                    }

                    .bloc_add_color {
                        min-width: auto;

                        span{
                            font-size: 8px;
                            font-family: $fourthFont;

                            &:after {
                                height: 13px;
                                top: 0;
                                position: relative;
                                background-size: 10px;
                            }
                        }

                        button {
                            width: 62px;
                            span {
                                align-items: center;
                                
                                &:after {
                                    margin-left: 0;
                                }
                            }
                        }
                    }

                    form {
                        .rollover_left {
                           width: 48%;
                           min-width: 48%; 

                           .simplebar-placeholder {
                                height: 43px !important;
                           }
                           
                           .simplebar-content {
                                margin-top: 0;
                                height: 41px;
                           }
                        }
                    }

                    .form_itm.color [type=radio]:not(:checked)+label, 
                    .form_itm.color [type=radio]:checked+label {
                        width: 10px;
                        height: 10px;
                        margin: 0 6px 0 0;
                    }

                    .form_itm {
                        align-items: center;
                        margin-bottom: 4px;

                        &.color [type=radio]:checked+label {
                            &:after{
                                width: 13px;
                                height: 13px;
                            }
                        }

                        &.check {
                            &.size {
                                width: 18px;
                            }
                        }
                    }
                }

                .wrapper_description {
                    grid-template-columns: auto auto;
                    
                    .eclat_rect {
                        display: none;
                    }
                    
                    .item_name {
                        font-size: 10px;
                        font-weight: 600;
                        font-family: $primaryFont;
                        color: $primaryColor;
                        text-transform: uppercase;

                        .subtitle,
                        &.subtitle {
                            font-weight: 500;
                            display: block;
                            text-transform: inherit;
                        }
                    }

                    .wrap_prix_eclat {
                        flex-wrap: wrap;

                        .pricetag {
                            font-weight: 400;

                            span {
                                font-weight: 400;
                                font-size: 12px;
                            }
                        }
                        .eclat_rond {
                            margin-top: -1px;
                        }
                    }
                }
            }
            
            .swiper-scrollbar {
                border-radius: 2px;
                height: 3px;
                width: 100%;
                bottom: 0;
                left: 0;
                opacity: .1;
                background: $subMenu;
                position: relative;
                margin-top: 2rem;

                .swiper-scrollbar-drag {
                    background: #8d8d8d;
                }
            }
        }
    }
}