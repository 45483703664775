.historyWrapper {
    margin: 0 auto;

    .history_block_container {
        margin: 0 auto 2rem;
        @include flexbox(row, center, center);

        &:not(:last-child) {
            margin: 0 0 2rem;
        }
    }

    .pic, 
    .history_brand_txt, 
    .history_content_container {
        width: 30vw;
        margin: 0 4rem;  
        
        @include medium {
            width: 35vw; 
        }
    }

    /* CONTAINERS (pic + text) */
    .history_content_container {
        position: relative;
        padding: 0 2rem;

        .history_hover_span {
            cursor: pointer;
            text-decoration: underline;
        }

        @media screen and (max-width: 1024px) {
            .img_hover {
                display: none;
            }   
        }

        .img_hover {
            position: absolute;
            opacity: 0;

            &.left_imgs {
                left: -35%;
            }

            &.second_img {
                bottom: 0;
            }

            &.third_img {
                top: 0;
                left: 104%;
            }

            &.fourth_img {
                right: -35%;
                top: 25%;
            }
            &.fifth_img {
                right: -35%;
                top: 75%;
            }
            &.sixth_img {
                right: 96%;
                top: 72%;
            }

            &.active {
                opacity: 1;
                transition: opacity 0.1s linear;

            }
        }
    }

    /* PICTURES */
    .pic img {
        width: 100%;
        height: auto;
    }

    .history_block_container:nth-child(2) .pic {
        margin: 0 0 8rem;
        @include flexbox(row, center, unset);

        img {
            width: 60%;
        }
    }

    .history_block_container:nth-child(3) .pic {
        @include flexbox(row, center, flex-end); 
        flex-wrap: wrap;

        img:nth-child(1) {
            width: 50%;
            margin: 0 1rem 4rem;
        }

        img:nth-child(2) {
            width: 35%;
            object-fit: contain;
            margin: 0 1rem;
        }

        img:nth-child(3) {
            width: 70%;
            margin: 2rem 0 0;
        }
    }

    .history_content_bloc {
        @include flexbox(row, space-between, unset); 

        .history_content_inner_bloc {
            width: 50%;
            padding: 0 1rem;

            p:not(:last-child) {
                margin: .5rem 0 1rem;
            }

            a {
                display: block;
            }
        }
    }

    .history_quote {
        margin-top: 2rem;
        font-family: $thirdFont;
        font-size: 1.8rem;
        color: $darkgrey;

        .bgSprite {
            justify-content: flex-start;
            
            &.bfr:before {
                width: 2.5rem;
                height: 2.5rem;
                position: absolute;
                z-index: -1;
                margin-left: -1%;
            }

            &.quoteRight {
                display: initial;
            }
        }
    }

    /* TITRES */
    h2 {
        margin: 0 0 1rem;
        @include wshopFont($primaryFont, 1.25rem, unset, .05rem, 700, $primaryColor);
        text-transform: uppercase;
    }
    
    /* SOUS-TITRES ARTICLES */
    h3 {
        margin: 0 0 1rem;
        @include wshopFont($primaryFont, 1.25rem, unset, .1rem, 700, $primaryColor);
        text-transform: uppercase;

        span {
            font-size: .875rem;
            font-weight: 500;
        }
    }

    /* SOUS-TITRES CORPS DE TEXTE */
    h5 {
        margin: 0 0 .5rem;
        @include wshopFont($primaryFont, .875rem, unset, .1rem, 700, $primaryColor);
        text-transform: uppercase;
    }

    /* CORPS DE TEXTE */
    .history_brand_txt p,
    .history_content_inner_bloc p,
    .history_hover_span {
        @include wshopFont($secondaryFont, 1rem, unset, 0, 400, $primaryColor);
        text-transform: initial;
        margin: .5rem 0 1rem;
        text-align: justify
    }

    .history_content_inner_bloc {
        @include wshopFont($primaryFont, .875rem, unset, .1rem, 700, $primaryColor);
        text-transform: uppercase;
        margin: 0 0 .5rem;

        a {
            display: block;
        }
    }
}